/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import styled from 'styled-components';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import ProtectedRoute from './ProtectedRoute';
import ContactUs from './pages/ContactForm';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import { ParentScreener, EndSurvey } from './pages/ParentScreener';
import KidScreener from './pages/KidScreener';
import MyAccount from './pages/MyAccount';
import useDevices from './utils/useDevices';
import Navbar from './components/Navbar';
import RecoveryPassword from './pages/RecoveryPassword';

const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  min-height: calc(100vh - 80px);

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    min-height: 100vh;
  }
`;

export default function App(): JSX.Element {
  useDevices();
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Route
          sensitive
          render={({ location }: RouteProps) =>
            ['/home', '/dashboard', '/myAccount'].includes(
              location?.pathname || ''
            ) ? (
              <Navbar />
            ) : location?.pathname === '/signUp' ? (
              <Navbar signUpPage />
            ) : null
          }
        />
        <Main>
          <Switch>
            <Route sensitive path="/signUp" component={ContactUs} />
            <Route path="/signIn" component={SignIn} />
            <Route path="/completeRegister" component={SignUp} />
            <Route path="/resetPassword" component={RecoveryPassword} />
            <Route path="/kidScreener" component={KidScreener} />
            <ProtectedRoute path="/myAccount" component={MyAccount} />
            <ProtectedRoute path="/home" component={Home} />
            <ProtectedRoute path="/parentScreener" component={ParentScreener} />
            <ProtectedRoute path="/endsurvey" component={EndSurvey} />
            <Redirect from="*" to="/signUp" />
          </Switch>
        </Main>
      </Router>
    </DndProvider>
  );
}
