import styled, { keyframes } from 'styled-components';

export { default as Background } from './Background';

export { UserIcon, ImageContent } from './UserIcon';

export const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Subtitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 23.87px;
`;

export const Strong = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;

export const Small = styled.p`
  font-size: 13px;
  line-height: 18px;
`;

export const CloseButton = styled.button`
  position: relative;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    margin-left: -1px;
    top: 0;
    height: 16px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.main.default};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const UserImg = styled.div<{ size?: number }>`
  height: ${({ size }) => (size ? `${size}px` : '48px')};
  width: ${({ size }) => (size ? `${size}px` : '48px')};
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  background-color: ${({ theme }) => theme.colors.quaternary.default};
  border-radius: 100%;
  margin: 0 8px;
`;
