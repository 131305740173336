/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ICheckboxStyle, PillStyle } from './Form.interfaces';

export const LabelStyle = styled.label<ICheckboxStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
  }

  i {
    display: inline-table;
    position: relative;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.main.default};
    transition: all 0.1s linear;
    margin-right: 12px;
    ${({ error, theme }) =>
      error && `border-color: ${theme.colors.alert.default}; `}
  }

  &:hover input ~ i {
    border: 1px solid ${({ theme }) => theme.colors.main.default};
    background-color: ${({ theme }) => theme.colors.main.default};
  }

  input:checked ~ i {
    background-color: ${({ theme }) => theme.colors.main.default};
  }

  i:after {
    content: '';
    position: absolute;
    opacity: 0;
    left: 8px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 1px;
    transition: all 0.15s linear;
  }

  span {
    width: 100%;
  }

  input:checked ~ i:after {
    opacity: 1;
  }

  a {
    margin-left: 3px;
  }
`;

export const PillsBox = styled.div`
  margin-bottom: 16px;
  > div {
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      padding-top: 0;
    }
  }
`;

export const Pill = styled.div<PillStyle>`
  border: 1px solid;
  border-radius: 100px;
  padding: 14px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.25s linear;

  ${({ selected, theme }) =>
    selected &&
    `border-color: ${theme.colors.main.default};
    background: ${theme.colors.main.default};
    color: ${theme.colors.white.default};`}

  ${({ error, theme }) =>
    error && `border-color: ${theme.colors.alert.default};`}
`;
