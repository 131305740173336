import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DropIn from 'braintree-web-drop-in-react';
import right from '../../../assets/icons/Right.svg';
import { Modal } from '../..';
import ResetPassword from '../../ResetPassword/ResetPassword';
import {
  Title,
  List,
  BackButton,
  PaymentTitle,
  ContainerButton,
  Wrapper,
} from './styles';
import left from '../../../assets/icons/Left.svg';
import Button from '../../Button';
import IState from '../../../reducers';
import {
  getBraintreeToken,
  resetBraintreeToken,
} from '../../../pages/Payment/payment.action';
import { ISettingsLinkProps } from './SettingsLink.interfaces';
import { getBrainTreeSettings } from '../../../utils';

const rightIcon = right;

export default function SettingsLink({ title, links }: ISettingsLinkProps) {
  const dispatch = useDispatch();
  const [instance, setInstance] = useState<{
    requestPaymentMethod: () => { nonce: string };
  } | null>(null);
  const { isMobile } = useSelector((state: IState) => state.devices);
  const { btToken } = useSelector((state: IState) => state.payment);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(
    false
  );
  const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>(
    false
  );

  const handleShowScreen = (screen: string) => {
    switch (screen) {
      case 'paymentMethod':
        setShowPaymentMethodModal(true);
        break;
      case 'resetPassword':
        setShowResetPasswordModal(true);
        break;
      default:
        break;
    }
  };

  const buy = () => {
    if (instance) {
      instance.requestPaymentMethod();
    }
  };

  useEffect(() => {
    dispatch(getBraintreeToken());
    return () => {
      dispatch(resetBraintreeToken());
    };
  }, [dispatch]);

  if (!btToken) {
    return <div> Loading...</div>;
  }

  const leftAction = () => (
    <BackButton>
      <div onClick={() => setShowResetPasswordModal(false)}>
        <img src={left} alt="Go back" />
        <span>My account</span>
      </div>
    </BackButton>
  );

  return (
    <div>
      <Title>{title}</Title>
      <List>
        {links.map((link) => (
          <li key={link.label}>
            <div onClick={() => handleShowScreen(link.screen)}>
              <span>{link.label}</span> <img src={rightIcon} alt="" />
            </div>
          </li>
        ))}
        {showPaymentMethodModal && (
          <Modal
            mw={675}
            isOpen
            full={isMobile}
            closeModal={() => {
              setShowPaymentMethodModal(false);
            }}
          >
            <PaymentTitle>Payment method</PaymentTitle>
            <Wrapper>
              {btToken ? (
                <DropIn
                  key={btToken}
                  options={{
                    authorization: btToken,
                    ...getBrainTreeSettings(),
                  }}
                  onInstance={(inst) => setInstance(inst)}
                />
              ) : (
                <div>Loading...</div>
              )}
            </Wrapper>
            {instance ? (
              <ContainerButton>
                <Button type="button" onClick={buy} width={139}>
                  Save
                </Button>
              </ContainerButton>
            ) : (
              <div>Loading...</div>
            )}
          </Modal>
        )}
        {showResetPasswordModal && (
          <Modal
            mw={549}
            isOpen
            full={isMobile}
            leftAction={leftAction()}
            closeModal={() => setShowResetPasswordModal(false)}
          >
            <ResetPassword
              title="Reset Password"
              closeModal={() => setShowResetPasswordModal(false)}
            />
          </Modal>
        )}
      </List>
    </div>
  );
}
