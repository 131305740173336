import 'normalize.css';
import styled, { createGlobalStyle } from 'styled-components';

/*
100 Thin (Hairline) -- Ultralight
200	Extra Light (Ultra Light) -- Thin
300	Light -- Light
400	Normal (Regular) -- Regular
500	Medium -- Medium
600	Semi Bold (Demi Bold) --Semibold
700	Bold -- Bold
800	Extra Bold (Ultra Bold) --Black
900	Black (Heavy) -- Heavy
*/

const fonts = styled.style`
  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Ultralight.otf);
    font-weight: 100;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Thin.otf);
    font-weight: 200;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Light.otf);
    font-weight: 300;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Regular.otf);
    font-weight: 400;
    font-weight: normal;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Medium.otf);
    font-weight: 500;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Semibold.otf);
    font-weight: 600;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Bold.otf);
    font-weight: 700;
    font-weight: bold;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Black.otf);
    font-weight: 800;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text'), url(./fonts/SF-Pro-Text-Heavy.otf);
    font-weight: 900;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${fonts}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    font-family: 'SF Pro Text', Arial, Helvetica;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.main.default};

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin: 0 auto;
    padding: 0;
    
    transition: all 0.25s linear;
  }

  h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
  }

  h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.main.default};

    &:hover {
      color: ${({ theme }) => theme.colors.main.dark};
    }
  }

  #root {
    width: 100%;
  }

  .muted-text {
    color: ${({ theme }) => theme.colors.quaternary.default};
  }

  .container-size {
    width: 100%;
    max-width: 720px;
    box-sizing: border-box;

    @media (max-width: 740px) {
      padding: 20px;
    }
  }

  .text-muted {
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.main.default};
  }

  .cursor-pointer {
    cursor: pointer;
  }
  input,
  button {
    outline: none;
  }

  .lock {
    overflow: hidden;
  }
`;
export default GlobalStyle;
