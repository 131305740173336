// TYPES
import axios from 'axios';
import { isLocalhost, isStaginghost, checkBool } from '../../utils';

export const INTERESTS_SERVICES = 'INTERESTS_SERVICES';
export const GRADES = 'GRADES';
export const LOCATIONS = 'LOCATIONS';
export const FORM_DATA_SEND = 'FORM_DATA_SEND';
export const FORM_DATA_FAILURE = 'FORM_DATA_FAILURE';
export const FORM_DATA_SUCCESS = 'FORM_DATA_SUCCESS';
export const CLOSE_THANK_YOU_MODAL = 'CLOSE_THANK_YOU_MODAL';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

const URL_HS = process.env.REACT_APP_URL_HS;
const PORTAL_ID = process.env.REACT_APP_PORTAL_ID;
const FORM_REGISTER_ID = process.env.REACT_APP_FORM_REGISTER_ID;
const TEST_HUBSPOT = process.env.REACT_APP_TEST_HUBSPOT;

const interestedSuccess = (interests) => {
  return {
    type: INTERESTS_SERVICES,
    payload: {
      interests,
    },
  };
};

const gradesSuccess = (grades) => {
  return {
    type: GRADES,
    payload: {
      grades,
    },
  };
};

const locationsSuccess = (locations) => {
  return {
    type: LOCATIONS,
    payload: {
      locations,
    },
  };
};

const formDataSend = () => ({
  type: FORM_DATA_SEND,
});

const formDataSuccess = () => ({
  type: FORM_DATA_SUCCESS,
});

export const formDataFailure = (error) => ({
  type: FORM_DATA_FAILURE,
  payload: {
    error,
  },
});

export const closeThankYouModal = () => ({
  type: CLOSE_THANK_YOU_MODAL,
});

export const getInterestedServices = () => {
  return (dispatch) => {
    axios.get(`${HOST_PROFILE}interest_service`).then((res) => {
      dispatch(interestedSuccess(res.data));
    });
  };
};
export const getGrades = () => {
  return (dispatch) => {
    axios.get(`${HOST_PROFILE}grade`).then((res) => {
      dispatch(gradesSuccess(res.data));
    });
  };
};
export const getLocations = () => {
  return (dispatch) => {
    axios.get(`${HOST_PROFILE}location`).then((res) => {
      dispatch(locationsSuccess(res.data));
    });
  };
};

const info = {
  context: {
    pageUri: 'https://app.learnfully.com/signUp',
    pageName: 'Register',
  },
  legalConsentOptions: {
    consent: {
      consentToProcess: true,
      text:
        'I agree to allow Learnfully Company to store and process my personal data.',
      communications: [
        {
          value: true,
          subscriptionTypeId: 999,
          text:
            'I agree to receive marketing communications from Learnfully Company.',
        },
      ],
    },
  },
};

const sendDataHubspot = (formDataHubspot) => {
  const dataHubspot = {
    ...info,
    fields: Object.keys(formDataHubspot).map((key) => ({
      name: key.toLowerCase(),
      value: formDataHubspot[key],
    })),
  };

  axios
    .post(`${URL_HS}/${PORTAL_ID}/${FORM_REGISTER_ID}`, dataHubspot)
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('Error');
    });
};

export const sendContactUsData = (contactUsData, formDataHubspot) => {
  return (dispatch) => {
    const prod = Boolean(!isLocalhost && !isStaginghost);
    const test = Boolean(
      (isLocalhost || isStaginghost) && checkBool(TEST_HUBSPOT)
    );
    dispatch(formDataSend());

    if (prod || test) {
      sendDataHubspot(formDataHubspot);
    }

    axios
      .post(`${HOST_PROFILE}user`, contactUsData)
      .then(() => {
        dispatch(formDataSuccess());
      })
      .catch((err) => {
        const message =
          err.response.data.erroCode === '409' ? 'alreadyExist' : err.message;
        dispatch(formDataFailure(message));
      });
  };
};
