import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendAccountCreateData } from './signUp.action';
import { IsExpired } from '../../helpers/timeHelper';
import { Field } from '../../components/Form';
import {
  Main,
  FormContainer,
  ContainerPassword,
  SubmitContainer,
  Background,
  Container,
} from '../SignIn/styles';
import { Expired, User, Link, SmallText } from './styles';
import Button from '../../components/Button';
import { validatePassword } from '../../helpers/validatePassword';
import IState from '../../reducers';
import { IUser, IData } from './SignUp.interfaces';
import LoadingComponent from '../../components/Loading';

function useQuery() {
  return new URLSearchParams(useLocation().search).get('token') || '';
}

export default function SignUp() {
  const token = useQuery();
  const history = useHistory();
  let user: IUser = { email: '', exp: 0, aud: '' };
  if (!token) {
    history.push('/home');
  } else {
    user = jwt_decode(token);
  }

  const dispatch = useDispatch();

  const { accountCreated, loading } = useSelector(
    (state: IState) => state.userCreated
  );

  if (accountCreated) {
    history.push('/home');
  }

  const [data, setData] = useState<IData>({
    password: '',
    confirmPassword: '',
  });

  const [inputType, setInputType] = useState('password');

  const [passErr, setPassErr] = useState(false);
  const [confirmPassErr, setConfirmPassErr] = useState(false);

  const handleValidatePassword = () => {
    if (!validatePassword(data.password)) {
      setPassErr(true);
    }
  };

  const handleValidateConfirmPassword = () => {
    setConfirmPassErr(data.password !== data.confirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleValidatePassword();
    handleValidateConfirmPassword();
    if (data.password && data.confirmPassword && !passErr && !confirmPassErr) {
      const accountRegistrationData = {
        email: user.email,
        password: data.password,
        token,
      };

      dispatch(sendAccountCreateData(accountRegistrationData));
    }
  };

  const handleChange = (event) => {
    if (validatePassword(event.target.value)) {
      setPassErr(false);
    }
    if (event.target.name === 'confirmPassword') {
      setConfirmPassErr(data.password !== event.target.value);
    }
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleVisible = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  if (IsExpired(user.exp)) {
    return (
      <Expired>
        <div>Token has expired</div>
      </Expired>
    );
  }

  return (
    <Main className="container-size" bottom>
      <Background />
      <Container>
        <h1>Create account</h1>
        <p>Welcome! Enter a password to create a Learnfully account.</p>
        <FormContainer>
          <User>
            <div>
              <b>User</b>
            </div>
            <div>{user.email}</div>
          </User>
          <form onSubmit={handleSubmit}>
            <ContainerPassword>
              <Field
                label="Password"
                name="password"
                type={inputType}
                autoComplete="off"
                handleChange={handleChange}
                onBlur={handleValidatePassword}
                value={data.password}
                error={
                  passErr &&
                  `Minimum eight (8) characters in length. Contain at least one (1)
                number, one (1) uppercase letter and special character (~\`! ...)`
                }
                full
              />

              <FontAwesomeIcon
                icon={inputType === 'text' ? faEye : faEyeSlash}
                onClick={handleVisible}
              />
            </ContainerPassword>

            <ContainerPassword>
              <Field
                label="Confirm Password"
                name="confirmPassword"
                type={inputType}
                autoComplete="off"
                handleChange={handleChange}
                onBlur={handleValidatePassword}
                value={data.confirmPassword}
                error={confirmPassErr && `Passwords do not match!`}
                full
              />

              <FontAwesomeIcon
                icon={inputType === 'text' ? faEye : faEyeSlash}
                onClick={handleVisible}
              />
            </ContainerPassword>

            <SubmitContainer>
              <Button type="submit">
                {loading ? <LoadingComponent /> : 'Sign up'}
              </Button>

              <SmallText>
                By signing in you accept our
                <Link
                  target="blank"
                  href="https://learnfully.com/terms-and-conditions/"
                >
                  <b>Terms of Services</b>
                </Link>
                and
                <Link
                  target="blank"
                  href="https://learnfully.com/privacy-policy/"
                >
                  <b>Privacy Policy</b>
                </Link>
                .
              </SmallText>
            </SubmitContainer>
          </form>
        </FormContainer>
      </Container>
    </Main>
  );
}
