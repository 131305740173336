import React from 'react';
import { ContentWrapper, StoryBox, ImageBox } from './styles';
import { IStoryComponentProps } from './StoryComponent.interfaces';

export default function StoryComponent({
  title,
  questionType,
  image,
  story,
}: IStoryComponentProps) {
  return (
    <ContentWrapper>
      <h3>{title}</h3>
      {questionType === 'text' ? (
        <StoryBox>{story}</StoryBox>
      ) : (
        <ImageBox>
          <img src={image} alt="" />
        </ImageBox>
      )}
    </ContentWrapper>
  );
}
