// eslint-disable-next-line import/prefer-default-export
export const packageIncluded = {
  'Skill Screener': [
    'Parent Screener.',
    'Two live online learner sessions with a Learnfully Education Specialist.',
    'Detailed evaluation of skills measured against common core baseline.',
    'Detailed report of learner strengths, challenges, and learning style.',
    '30-minute engagement with Education Specialist to discuss recommendations and remediation plan.',
  ],
  'Silver Package': [
    'Parent Screener.',
    'Learner Screener - Live session with Education Specialist.',
    'Detailed report on Learner’s scores (common core).',
    'Detailed view of Learner’s Profile, strengths & challenges.',
    'Recommendations and remediation plan.',
    'Live 45 min parent conversation with Education Specialist.',
  ],
  'Gold Package': [
    'Everything from the Silver Package.',
    'Four 1-hr sessions at $124.99 / hr.',
    'One custom matched Education specialist or Literacy or Math.',
    'Parent coaching (1 - 30 min session).',
  ],
  'Platinum Package': [
    'Everything from the Silver Package.',
    'Eight 1-hr sessions at $124.99 / hr.',
    '1-2 Custom matched Education specialist or Literacy and/or Math.',
    'Parent coaching (2 - 30 min sessions).',
  ],
};
