import Step1 from '../../assets/images/step1.png';
import Step2 from '../../assets/images/step2.png';
import Step3 from '../../assets/images/step3.png';

export default [
  {
    title: 'Learn & Listen',
    description:
      "Evaluate the learner's abilities, emotional and behavioral traits for strengths.",
    image: Step1,
  },
  {
    title: 'Suggest & Shape',
    description:
      'Create a content and services plan to meet the individual learner needs.',
    image: Step2,
  },
  {
    title: 'Share & Guide',
    description:
      'Progress is measured towards goals to ensure the optimal path and results.',
    image: Step3,
  },
];
