import styled from 'styled-components';

const SliderContainer = styled.div`
  display: flex;
  transition: all 250ms linear;
  min-height: 572px;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    min-height: 100%;
  }
`;

const Slide = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  transition: all 250ms linear;
  height: 100%;
  justify-content: space-around;

  ${({ active }) =>
    active &&
    `
    opacity: 1; 
    z-index: 1;`};
`;

const Dots = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    margin-top: 50px;
  }
`;

const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.quaternary.default};
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  ${({ active, theme }) =>
    active && `background-color: ${theme.colors.primary.default}`}
`;

const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.main.dark};

  p {
    text-align: center;
    margin-bottom: 20px;
    padding: 0px 30px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
      padding: 0;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 320px;
  height: 320px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
`;

export {
  SliderContainer,
  Slide,
  Dots,
  Dot,
  Explanation,
  ImageContainer,
  Image,
};
