import styled from 'styled-components';
import { commonWrapper } from '../../../pages/Payment/styles';

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.main.default};
  font-size: 20px;
  margin: 0 0 16px 0;
  font-weight: bold;
`;

export const List = styled.ul`
  border: 1px solid ${({ theme }) => theme.colors.quaternary.dark};
  border-radius: 8px;
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
  li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
    cursor: pointer;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px;
      span {
        color: ${({ theme }) => theme.colors.main.default};
        font-weight: 600;
      }
    }
    &:last-child {
      border: none;
    }
  }
`;

export const BackButton = styled.div`
  display: flex;
  width: 100%;

  > div {
    display: flex;
    cursor: pointer;
  }

  span {
    padding-left: 15px;
  }
`;

export const PaymentTitle = styled.h2`
  font-size: 28px;
  font-weight: 500;
`;

export const ContainerButton = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  ${commonWrapper}
`;
