import styled from 'styled-components';

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colors.secondary.medium}85`};
  z-index: 6;

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    padding: 0;
    align-items: flex-end;
  }
`;

interface IMain {
  full?: boolean;
  mw?: number;
  rounded?: boolean;
}

const Main = styled.div<IMain>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 680px;
  max-height: 90vh;
  background-color: white;
  overflow: auto;
  padding: 50px 0 25px 25px;
  border-radius: 16px;
  box-sizing: border-box;

  ${({ mw }) => mw && `max-width: ${mw}px;`}

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    justify-content: flex-start;
    max-height: 100vh;
    padding: 50px 0 20px 20px;
    border-radius: 16px 16px 0 0;
    ${({ full }) => full && `height: 100vh; border-radius: 0;`}
  }
`;

const Header = styled.div`
  position: absolute;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 18px 25px 0;
  box-sizing: border-box;
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 0;
  box-sizing: border-box;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    padding-right: 25px;
  }
`;

const CloseButton = styled.button`
  position: relative;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    margin-left: -1px;
    top: 0;
    height: 20px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.main.default};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export { ModalContainer, Main, Header, Body, CloseButton };
