import React from 'react';
import { FieldBox, Label, Input, Error } from './common';
import { IField } from './Form.interfaces';

export default function Field(props: IField) {
  const {
    label,
    type,
    name,
    placeholder,
    handleChange,
    onBlur,
    value,
    disabled,
    error,
    width,
    full,
  } = props;
  return (
    <FieldBox full={full}>
      <Label name={name} label={label} />
      <Input
        {...props}
        type={type}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        error={!!error}
        dirty={!!value}
        width={width}
      />
      {error && <Error>{error}</Error>}
    </FieldBox>
  );
}
