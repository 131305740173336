import {
  SEND_RECOVERY,
  SEND_RECOVERY_SUCCESS,
  SEND_RECOVERY_FAILURE,
  RESET_DATA,
} from '../pages/RecoveryPassword/recovery.action';

const initialState = {
  success: false,
  loading: false,
  failure: '',
};

export default function recoveryPassword(state = initialState, action) {
  switch (action.type) {
    case SEND_RECOVERY:
      return {
        ...state,
        loading: true,
        failure: '',
      };
    case SEND_RECOVERY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case SEND_RECOVERY_FAILURE:
      return {
        ...state,
        failure: action.err,
        loading: false,
      };
    case RESET_DATA:
      return {
        ...state,
        success: false,
        loading: false,
        failure: '',
      };
    default:
      return state;
  }
}
