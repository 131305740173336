import { combineReducers } from '@reduxjs/toolkit';
import contactUs from '../reducers/contactUsReducer';
import userCreated from '../reducers/signUpReducer';
import user from '../reducers/user';
import loginInfo from '../reducers/signInReducer';
import payment from '../reducers/paymentReducer';
import devices from '../reducers/devicesReducer';
import resetPassword from '../reducers/resetReducer';
import forgotPassword from '../reducers/forgotPassword';
import recoveryPassword from '../reducers/recoveryPassword';
import sessions from '../reducers/sessionsReducer';

const rootReducer = combineReducers({
  contactUs,
  userCreated,
  user,
  loginInfo,
  payment,
  devices,
  resetPassword,
  forgotPassword,
  recoveryPassword,
  sessions,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
