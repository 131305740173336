/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { useSelector, useDispatch } from 'react-redux';
import IState from '../../reducers';
import { saveScheduledEvent } from './kidScreenerSchedule.action';

import {
  CalendlyContainer,
  CalendlySideDescription,
  CalendlySideContainer,
  CalendlyDescriptionTitle,
  ContactUsMessage,
  ContactUsLink,
  UserIcon,
  LoadingContainer,
} from './styles';
import {
  ICalendlyPrefillProps,
  ICalendlyProps,
  IPrefillRef,
} from './kidScreener.interfaces';

const InlineWidgetMemo = React.memo((props: ICalendlyPrefillProps) => (
  <InlineWidget {...props} />
));
export default function CalendlyPage({
  sessionId,
  alias,
  sessionNumber,
  isReviewSession,
  sessionDetailId,
  isLastStep,
}: ICalendlyProps) {
  const contactUsRef = useRef<HTMLDivElement>(null);
  const calendlyURL = `${process.env.REACT_APP_CALENDLY_URL}`;
  let calendlyEvent = '';

  if (isReviewSession) {
    calendlyEvent = `${process.env.REACT_APP_CALENDLY_REVIEW}`;
  } else {
    calendlyEvent =
      sessionNumber === 1
        ? `${process.env.REACT_APP_CALENDLY_LITERACY}`
        : `${process.env.REACT_APP_CALENDLY_MATH}`;
  }
  const [loading, setLoading] = useState(true);
  const [showContactUs, setShowContactUs] = useState(true);
  const { info } = useSelector((state: IState) => state.user);

  const dispatch = useDispatch();

  const REVIEW = 'review';
  const SCREENER = 'screener';

  useEffect(() => {
    showContactUs &&
      contactUsRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showContactUs]);

  const sessionScheduled = (event) => {
    const eventArr = event.data.payload.event.uri.split('/');
    const inviteeArr = event.data.payload.invitee.uri.split('/');
    const calendlyObj = {
      eventId: eventArr[eventArr.length - 1],
      inviteeId: inviteeArr[inviteeArr.length - 1],
      sessionType: isReviewSession ? REVIEW : SCREENER,
      sessionDetailId,
    };
    dispatch(saveScheduledEvent(sessionId, calendlyObj));
    setShowContactUs(true);
    isLastStep(false);
  };

  const prefillRef = useRef<IPrefillRef>({
    name: alias,
    email: info.email,
  });
  const styleRef = useRef({
    height: '700px',
  });
  return (
    <>
      <CalendlyContainer isReviewSession={isReviewSession}>
        <CalendlySideDescription isReviewSession={isReviewSession}>
          {!isReviewSession ? (
            <>
              <UserIcon />
              <CalendlyDescriptionTitle>
                {isReviewSession ? null : (
                  <b>{`${alias}'s Session ${
                    sessionNumber === 1 ? '1' : '2'
                  }`}</b>
                )}
              </CalendlyDescriptionTitle>
            </>
          ) : (
            <>
              <UserIcon />
              <h3>{alias}’s Program review</h3>
            </>
          )}
        </CalendlySideDescription>
        <CalendlySideContainer isReviewSession={isReviewSession}>
          <CalendlyEventListener
            onEventTypeViewed={() => {
              setLoading(false);
              setShowContactUs(false);
              isLastStep(false);
            }}
            onDateAndTimeSelected={() => isLastStep(true)}
            onEventScheduled={sessionScheduled}
          />
          {loading && <LoadingContainer>Loading Calendar...</LoadingContainer>}
          <InlineWidgetMemo
            url={`${calendlyURL}${calendlyEvent}?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=DF6A26&`}
            prefill={prefillRef.current}
            styles={styleRef.current}
          />
        </CalendlySideContainer>
      </CalendlyContainer>
      {showContactUs && (
        <ContactUsMessage ref={contactUsRef}>
          Need to cancel or reschedule?
          <ContactUsLink>
            <a href="mailto:contact@learnfully.com">Contact us</a>
          </ContactUsLink>
        </ContactUsMessage>
      )}
    </>
  );
}
