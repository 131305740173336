import { SET_DEVICES } from '../commonActions';

const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

export default function devices(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICES:
      return action.devices;
    default:
      return state;
  }
}
