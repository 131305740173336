import React, { useState, useCallback } from 'react';

import DragableFigure from './DragableFigure';
import DropFigure from './DropFigure';
import {
  ContentWrapper,
  TopContent,
  BottomContent,
  StoryBox,
  ImageBox,
  BottomBox,
  ImageElement,
} from './styles';
import {
  IDragableImagesQuestionProps,
  IPosition,
  IImageList,
} from './DragableImagesQuestion.interfaces';

export default function DragableImagesQuestion({
  title,
  imageList = [],
  reorder = false,
}: IDragableImagesQuestionProps) {
  const defaultAnswers = imageList.map((image, index) => ({
    position: `${index + 1}`,
    name: image.name,
  }));
  const [answers, setAnswers] = useState<IPosition[]>([...defaultAnswers]);
  const isPositionFilled = useCallback(
    (localPosition): IImageList | null => {
      const [currentObject] = answers
        .filter(({ position }) => localPosition === position)
        .map(({ name }) => name);

      if (currentObject) {
        const [selectedImage] = imageList.filter(
          ({ name }) => name === currentObject
        );
        return selectedImage;
      }
      return null;
    },
    [imageList, answers]
  );

  const handleOnDrop = useCallback(
    (item, dropResult) => {
      const targetPosition = isPositionFilled(dropResult.position);
      if (!targetPosition) {
        setAnswers((current) => {
          const filtered = current.filter(
            (imageMoving) => imageMoving.name !== item.name
          );
          return [
            ...filtered,
            { position: dropResult.position, name: item.name },
          ];
        });
      }
    },
    [setAnswers, isPositionFilled]
  );

  return (
    <ContentWrapper reorder={reorder}>
      <h3>{title}</h3>
      <StoryBox>Story: Washing your hands</StoryBox>
      <TopContent reorder={reorder}>
        {imageList
          .map((item, index) => `${index + 1}`)
          .map((element) => (
            <ImageBox
              key={
                isPositionFilled(element) ? `${element}busy` : `${element}box`
              }
              reorder={reorder}
            >
              <DropFigure
                position={element}
                state={!!isPositionFilled(element)}
                type={isPositionFilled(element) ? 'busy' : 'box'}
              >
                {isPositionFilled(element) && (
                  <DragableFigure
                    name={isPositionFilled(element)?.name}
                    onDrop={handleOnDrop}
                  >
                    <ImageElement image={isPositionFilled(element)?.image} />
                  </DragableFigure>
                )}
              </DropFigure>
            </ImageBox>
          ))}
      </TopContent>
      <BottomContent reorder={reorder}>
        {imageList
          .map((item, index) => `${index + 1}A`)
          .map((element, index) => (
            <BottomBox
              key={
                isPositionFilled(element) ? `${element}busy` : `${element}box`
              }
              reorder={reorder}
            >
              <DropFigure
                position={element}
                state={!!isPositionFilled(element)}
                type={isPositionFilled(element) ? 'busy' : 'box'}
              >
                {isPositionFilled(element) && (
                  <DragableFigure
                    name={isPositionFilled(element)?.name}
                    onDrop={handleOnDrop}
                  >
                    <ImageElement image={isPositionFilled(element)?.image} />
                  </DragableFigure>
                )}
                {!isPositionFilled(element) && <span>{index + 1}</span>}
              </DropFigure>
            </BottomBox>
          ))}
      </BottomContent>
    </ContentWrapper>
  );
}
