import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Summary, PaymentMethod } from 'pages/Payment';
import IState from 'reducers';

import {
  getUser,
  getLearnerSessionsById,
  getRecommendedTutors,
} from 'commonActions';

import {
  resetPaymentFailure,
  resetPaymentOptions,
} from 'pages/Payment/payment.action';
import { IPackageItem } from 'pages/Payment/Payment.interfaces';

import Notification from './Notification';
import UpcomingSessions from './UpcomingSessions';
import Specialists from './Specialists';
import { Modal } from '..';
import { Background, BackGroup, ContactUsLink } from './styles';

import { IPaymentOptions } from './Dashboard.interfaces';
import TimeLeft from './TimeLeft';
import SessionDuration from './SessionDuration';
import SpecialistCalendly from './SpecialistCalendly';

export default function Dashboard() {
  const { purchaseFailure, bundlesPurchased } = useSelector(
    (state: IState) => state.payment
  );
  const { sessionsByLearner, retryLearnerSessions } = useSelector(
    (state: IState) => state.sessions
  );
  const [paymentStep, setPaymentStep] = useState<number>(0);
  const [pagePile, setPagePile] = useState<string[]>([]);
  const [paymentOptions, setPaymentOptions] = useState<IPaymentOptions>({
    code: '',
    quantity: 0,
    total: 0,
    totalDiscount: 0,
    enrollmentStatus: false,
    name: '',
  });
  const [packageBundle, setPackageBundle] = useState<IPackageItem>({
    description: '',
    name: '',
    productId: '',
    price: 0,
    sessionsAmount: 0,
  });
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [backText, setBackText] = useState('Back');
  const [specialistSelected, setSpecialistSelected] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [calendarUrl, setCalendarUrl] = useState('');
  const backButtonText = {
    specialistSelection: 'Schedule',
    sessionDuration: 'Ed. Specialist',
  };
  const [lastStep, setLastStep] = useState(false);
  const dispatch = useDispatch();

  const { isMobile } = useSelector(
    (state: { devices: { isMobile: boolean } }) => state.devices
  );

  const { actualLearner, recommendedTutors } = useSelector(
    (state: IState) => state.user
  );

  const learnerIdMemo = useMemo(() => {
    return actualLearner.learnerId;
  }, [actualLearner.learnerId]);

  useEffect(() => {
    dispatch(getLearnerSessionsById(learnerIdMemo, true));
  }, [dispatch, learnerIdMemo, bundlesPurchased]);

  useEffect(() => {
    dispatch(getUser(true));
    dispatch(getRecommendedTutors());
  }, [dispatch, actualLearner.learnerId]);

  useEffect(() => {
    let timer;
    if (retryLearnerSessions) {
      timer = setInterval(() => {
        dispatch(getLearnerSessionsById(learnerIdMemo, true));
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [dispatch, retryLearnerSessions, learnerIdMemo]);

  const handleSummaryNext = (options, bundle) => {
    setPagePile((prevState) => [...prevState, 'payment']);
    setPaymentOptions(options);
    setPackageBundle(bundle);
  };

  const handleScheduleNext = (goTo, calendar?) => {
    if (calendar) setCalendarUrl(calendar);
    setPagePile((prevState) => [...prevState, goTo]);
    setBackText(backButtonText[goTo]);
  };

  let learnerSessions = sessionsByLearner[learnerIdMemo];

  if (learnerSessions && learnerSessions.length) {
    learnerSessions = learnerSessions.filter((item) => {
      return item.sessionDetails[0].type === 'DYNAMIC';
    });
  }

  let sessions = [];
  if (learnerSessions?.sessionDetails) {
    learnerSessions.sessionDetails.forEach((session) => {
      sessions = sessions.concat(session.sessions);
    });
  }
  sessions = _.sortBy(sessions, [(o) => o.startDate]);

  const handleAddSessions = () => {
    setPagePile(() => ['addSession']);
    setShowPayment(true);
  };

  const handleScheduleSessions = (specialist) => {
    setSpecialistSelected(specialist);
    setPagePile(() => ['schedule']);
    setShowPayment(true);
  };

  const handleCloseModal = () => {
    if (paymentStep === 1) {
      if (purchaseFailure.status) {
        dispatch(resetPaymentFailure());
      }
      setPaymentStep(0);
    }
    dispatch(resetPaymentOptions());
    setShowPayment(false);
  };

  const paymentStepsToShow = () => {
    switch (pagePile[pagePile.length - 1]) {
      case 'summary':
        return (
          <Summary next={handleSummaryNext} actualLearner={actualLearner} />
        );
      case 'payment':
        return (
          <PaymentMethod
            closeModal={handleCloseModal}
            learnerId={actualLearner.learnerId}
            package={packageBundle}
            paymentOptions={paymentOptions}
          />
        );
      case 'addSession':
        return (
          <TimeLeft
            alias={actualLearner.alias}
            next={handleScheduleNext}
            timeLeft={
              learnerSessions ? learnerSessions.totalMinutesAvailable : 0
            }
          />
        );
      case 'schedule':
        return (
          <TimeLeft
            alias={actualLearner.alias}
            next={handleScheduleNext}
            timeLeft={
              learnerSessions ? learnerSessions.totalMinutesAvailable : 0
            }
            schedule
          />
        );
      case 'sessionDuration':
        return (
          <SessionDuration
            next={handleScheduleNext}
            specialistSelected={specialistSelected}
            timeLeft={
              learnerSessions ? learnerSessions.totalMinutesAvailable : 0
            }
            addSession={handleAddSessions}
          />
        );
      case 'calendar':
        return (
          <SpecialistCalendly
            alias={actualLearner.alias}
            specialistSelected={specialistSelected}
            calendarUrl={calendarUrl}
            isLastStep={setLastStep}
            learnerId={actualLearner.learnerId}
          />
        );
      default:
        return null;
    }
  };

  const BackButton = () => {
    const goBack = () => {
      if (pagePile.length === 1) {
        setPagePile([]);
        setShowPayment(false);
      } else {
        let newPagePile = [...pagePile];
        newPagePile = newPagePile.slice(0, -1);
        setPagePile(newPagePile);
        setBackText(backButtonText[newPagePile[pagePile.length - 2]]);
      }
    };

    return !bundlesPurchased ? (
      <BackGroup onClick={goBack} className="cursor-pointer">
        <FontAwesomeIcon icon={faAngleLeft} /> {backText}
      </BackGroup>
    ) : null;
  };

  return (
    <Background>
      <Modal
        full={isMobile}
        mw={
          pagePile.length > 1 && pagePile[pagePile.length - 1] === 'calendar'
            ? 1000
            : 550
        }
        isOpen={showPayment}
        closeModal={!lastStep ? handleCloseModal : undefined}
        leftAction={
          pagePile.length > 1 &&
          pagePile[pagePile.length - 1] !== 'calendar' &&
          BackButton()
        }
      >
        {paymentStepsToShow()}
      </Modal>
      <h2>Home</h2>
      {actualLearner.credit > 0 && (
        <Notification
          notificationText={`You have $${actualLearner.credit} credit to purchase sessions!`}
          handleClick={() => false}
        />
      )}
      <UpcomingSessions
        addSession={handleAddSessions}
        timeLeft={learnerSessions ? learnerSessions.totalMinutesAvailable : 0}
        sessions={sessions || []}
        alias={actualLearner.alias}
        retryLearnerSessions={retryLearnerSessions}
      />
      <Specialists
        scheduleSession={handleScheduleSessions}
        title="Educational Specialists"
        recommendedTutors={recommendedTutors}
      />
      {(!sessions.length || recommendedTutors.length < 1) && (
        <ContactUsLink>
          <p>To schedule your sessions contact us at:</p>
          <a href="mailto:contact@learnfully.com">contact@learnfully.com</a>
        </ContactUsLink>
      )}
    </Background>
  );
}
