import styled from 'styled-components';

export { UserIcon } from '../../components/Common';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ScreenerTitle = styled.h2`
  font-size: 28px;
  font-weight: 500;
  margin-top: 0;
`;

export const CalendarGroup = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    font-size: 13px;
  }
`;

export const CalendarItem = styled.div`
  display: flex;

  svg {
    opacity: 0.5;
  }
`;

export const CalendarStatus = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && `opacity:.5`};
  margin-bottom: 13px;
`;

export const CalendlyContainer = styled.div<{ isReviewSession?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    flex-direction: column;
  }
`;

export const CalendlyDescriptionTitle = styled.h1`
  font-size: 20px;
`;

export const CalendlySideDescription = styled.div<{
  isReviewSession?: boolean;
}>`
  padding-left: 20px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    width: 100%;
    border-right: none;
  }

  h3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
  }
`;

export const CalendlySideContainer = styled.div<{
  isReviewSession?: boolean;
}>`
  padding-left: ${({ isReviewSession }) => !isReviewSession && `0`};
  #page-region [data-container'booking-container'] {
    box-shadow: none;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const ContactUsMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ContactUsLink = styled.div`
  a {
    ${({ theme }) => `color: ${theme.colors.primary.default}`};
    text-decoration: none;
    margin-top: 8px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
`;

export const ScreenerUser = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;

export const TextLearner = styled.div`
  color: ${({ theme }) => theme.colors.main.dark};
`;

export const BottomText = styled.div`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.main.medium};
  margin: 32px 0;

  a {
    margin-left: 3px;
    color: ${({ theme }) => theme.colors.primary.default};
  }
`;

export const ViewDetailsTitle = styled.h3`
  font-size: 22px;
  font-weight: 400;
`;

export const ViewDetailsInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewDetailsInfoTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const ViewDetailsDateGroup = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const ViewDetailsDateItem = styled.div`
  width: 200px;
`;

export const ViewDetailsDisabledTitles = styled.div`
  color: ${({ theme }) => theme.colors.main.medium};
  margin-bottom: 8px;
`;

export const ViewDetailsInfoItem = styled.div`
  color: ${({ theme }) => theme.colors.secondary.default};
`;

export const ViewDetailsLinkGroup = styled.div`
  margin-bottom: 32px;
  div {
    margin-bottom: 8px;
  }
  a {
    color: ${({ theme }) => theme.colors.primary.default};
  }
`;

export const ViewDetailsSessionDetailsTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.medium};
  margin-bottom: 8px;
`;

export const ViewDetailsSessionDetails = styled.div`
  margin-bottom: 56px;
  color: ${({ theme }) => theme.colors.main.dark};
`;

export const ViewDetailsFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewDetailsContactUs = styled.div`
  margin-top: 8px;
  a {
    color: ${({ theme }) => theme.colors.primary.default};
    font-size: 17px;
    text-decoration: none;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
