import {
  CLEAN_USER,
  SET_USER,
  UPDATE_INFO,
  UPDATE_ACTUAL_LEARNER,
  UPDATE_LEARNERS,
  UPDATE_LEARNER,
  GET_RECOMMENDED_TUTORS_SUCCESS,
  GET_SESSION_TUTORS_SUCCESS,
  GET_TUTOR_SESSIONS_DURATION_FAILURE,
  SEND_TUTOR_SESSIONS_DURATION,
} from '../commonActions';

const initialState = {
  info: {
    userId: '',
    firstName: '',
    lastName: '',
    onboardingCompleted: false,
    email: '',
  },
  homeSteps: [],
  actualLearner: {
    learnerId: '',
    alias: '',
    profileCompleted: false,
    parentScreenerStatus: 'PENDING',
    learnerScreenerStatus: 'PENDING',
    screenerReviewStatus: 'PENDING',
    initialPlanPayed: false,
    defaultUser: false,
    nextStep: '',
    totalSessions: 0,
    sessionOcurred: 0,
    sessions: [
      {
        id: '',
        sessionName: '',
        sessionDescription: '',
        videoConferenceLink: '',
        eventType: '',
        learnerId: '',
        userId: '',
        inviteeId: '',
        eventStartDate: '',
        sessionStatus: 'NOT_SCHEDULED',
        tutorId: '',
        externalMeetingId: '',
        surveyResponses: [''],
      },
    ],
  },
  learners: [
    {
      alias: '',
      profileCompleted: false,
      parentScreenerStatus: 'PENDING',
      learnerScreenerStatus: 'PENDING',
      screenerReviewStatus: 'PENDING',
      initialPlanPayed: false,
      learnerId: '',
      credit: 0,
    },
  ],
  recommendedTutors: [
    {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      profileImageUrl: null,
      titleDegree: null,
      introVideoUrl: null,
      aboutMe: '',
      calendarUrl: null,
      specialist: false,
    },
  ],
  availableSessions: [],
  noEvents: false,
  loadingEvents: false,
};

export default function userSettings(state = initialState, action) {
  switch (action.type) {
    case CLEAN_USER:
      return initialState;
    case SET_USER:
      return {
        ...state,
        ...action.user,
        actualLearner: !state.actualLearner?.learnerId
          ? action.user.learners.filter((learner) => learner.defaultUser)[0]
          : action.user.learners.filter(
              (learner) => state.actualLearner.learnerId === learner.learnerId
            )[0],
      };
    case UPDATE_INFO:
      return {
        ...state,
        info: action.info,
      };
    case UPDATE_ACTUAL_LEARNER:
      return {
        ...state,
        actualLearner: action.actualLearner,
      };
    case UPDATE_LEARNERS:
      return {
        ...state,
        learners: action.learners,
        actualLearner: !state.actualLearner?.learnerId
          ? action.learners.filter((learner) => learner.defaultUser)[0]
          : action.learners.filter(
              (learner) => state.actualLearner.learnerId === learner.learnerId
            )[0],
      };
    case UPDATE_LEARNER:
      return {
        ...state,
        learners: [
          ...state.learners.map((learner) =>
            learner.learnerId === action.learner.learnerId
              ? action.learner
              : learner
          ),
        ],
        actualLearner:
          state.actualLearner.learnerId === action.learner.learnerId
            ? action.learner
            : state.actualLearner,
      };
    case GET_RECOMMENDED_TUTORS_SUCCESS:
      return {
        ...state,
        recommendedTutors: action.recommendedTutors,
      };
    case GET_SESSION_TUTORS_SUCCESS:
      return {
        ...state,
        availableSessions: action.availableSessions.events,
        noEvents: false,
        loadingEvents: false,
      };
    case SEND_TUTOR_SESSIONS_DURATION:
      return {
        ...state,
        loadingEvents: true,
      };
    case GET_TUTOR_SESSIONS_DURATION_FAILURE:
      return {
        ...state,
        loadingEvents: false,
        noEvents: true,
      };
    default:
      return state;
  }
}
