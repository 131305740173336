import React from 'react';
import { InfoBox } from './styles';

export default function MessageStatus({
  status,
  completed,
  pending,
}: {
  status: string | boolean;
  completed: boolean;
  pending: boolean;
}) {
  let text = '';
  if (completed) text = 'Done';
  if (pending) text = 'Pending';
  if (status === 'IN_PROGRESS') text = 'In progress';
  if (status === 'ACTION_REQUIRED') text = 'Action required';
  if (status === 'CANCELED') text = 'Canceled';
  if (status === 'SCHEDULED') text = 'Scheduled';

  return (
    <InfoBox
      done={completed}
      pending={pending}
      inProgress={status === 'IN_PROGRESS'}
      actionRequired={status === 'ACTION_REQUIRED'}
    >
      {text}
    </InfoBox>
  );
}
