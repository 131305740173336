import React from 'react';
import { IRadioButtonProps } from './RadioButton.interfaces';
import { Label } from './styles';

const RadioButton = ({ value, label }: IRadioButtonProps) => {
  return (
    <Label>
      <input type="radio" name="options" value={value} />
      <span className="radio" />
      <span className="value">{label}</span>
    </Label>
  );
};

export default RadioButton;
