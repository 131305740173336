import React from 'react';
import { useDrag } from 'react-dnd';
import { IDragableFigureProps } from './DragableImagesQuestion.interfaces';
import { DragableFigureBox } from './styles';

export default function DragableFigure({
  name,
  onDrop,
  children,
}: IDragableFigureProps) {
  const [, drag] = useDrag({
    item: { name, type: 'box' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item, dropResult);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return <DragableFigureBox ref={drag}>{children}</DragableFigureBox>;
}
