import React from 'react';
import { ITimeLeft } from './Dashboard.interfaces';
import { TimeLeftSubtitle, TimeLeftInfo, ButtonsGroup } from './styles';
import Button from '../Button';

export default function TimeLeft({
  alias,
  timeLeft,
  next,
  schedule,
}: ITimeLeft) {
  return (
    <div>
      <TimeLeftSubtitle>Sessions for {alias}</TimeLeftSubtitle>
      <TimeLeftInfo>
        You have {timeLeft} minutes of unscheduled sessions!
      </TimeLeftInfo>
      <ButtonsGroup schedule={schedule}>
        <Button
          type="button"
          onClick={() => next('summary')}
          kind="secondary"
          width={196}
        >
          Purchase
        </Button>
        {schedule && (
          <Button
            type="button"
            onClick={() => next('sessionDuration')}
            width={196}
          >
            Schedule
          </Button>
        )}
      </ButtonsGroup>
    </div>
  );
}
