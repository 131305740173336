import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import Button from '../../components/Button';
import { IKidScreenerProps } from './kidScreener.interfaces';

import {
  MainContainer,
  ScreenerTitle,
  CalendarGroup,
  CalendarItem,
  CalendarStatus,
  UserIcon,
  ScreenerUser,
  TextLearner,
  BottomText,
} from './styles';
import { CardContainer, CardTitle, CardHeader } from '../../components/Card';
import MessageStatus from '../../components/MessageStatus/MessageStatus';

const EmptySession = ({
  sessionNumber,
  next,
  disabled,
}: {
  sessionNumber?: number;
  next: (sessionNumber) => void;
  disabled?: boolean;
}) => {
  return (
    <CardContainer>
      <CardHeader>
        <CardTitle>{`Session ${sessionNumber}`}</CardTitle>
        <MessageStatus status="PENDING" completed={false} pending />
      </CardHeader>
      <CalendarGroup>
        <CalendarItem>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{ marginRight: 4, width: '1em' }}
          />
          <CalendarStatus disabled>Not scheduled</CalendarStatus>
        </CalendarItem>
        <CalendarItem>
          <FontAwesomeIcon
            icon={faClock}
            style={{ marginRight: 4, width: '1em' }}
          />
          <CalendarStatus disabled>Not scheduled</CalendarStatus>
        </CalendarItem>
      </CalendarGroup>
      <Button
        type="button"
        kind="small"
        color="primary"
        onClick={() => next(sessionNumber)}
        disabled={disabled}
      >
        SCHEDULE
      </Button>
    </CardContainer>
  );
};

export default function KidScreenerSchedule({
  learner,
  next,
  sessions,
}: IKidScreenerProps) {
  const sessionsToShow = () => {
    if (!sessions[0].sessions.length) {
      return [1, 2].map((item) => (
        <EmptySession
          key={item}
          sessionNumber={item}
          next={next}
          disabled={
            (sessions[0].sessions.length > 0 &&
              sessions[0].sessions[item - 1].startDate === null) ||
            item === 2
          }
        />
      ));
    }
    return sessions[0].sessions.map((session, i) => {
      return (
        <CardContainer key={session.id}>
          <CardHeader>
            <CardTitle> {session.sessionName} </CardTitle>
            <MessageStatus
              status={session.sessionStatus.name}
              completed={session.sessionStatus.name === 'COMPLETED'}
              pending={session.sessionStatus.name !== 'COMPLETED'}
            />
          </CardHeader>
          <CalendarGroup>
            <CalendarItem>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ marginRight: 4, width: '1em' }}
              />
              <CalendarStatus disabled={session.startDate === null}>
                {session.startDate === null
                  ? 'Confirmation Pending. No action needed'
                  : moment
                      .utc(session.startDate)
                      .local()
                      .format('dddd, MMM Do, YYYY')}
              </CalendarStatus>
            </CalendarItem>
            <CalendarItem>
              <FontAwesomeIcon
                icon={faClock}
                style={{ marginRight: 4, width: '1em' }}
              />
              <CalendarStatus disabled={session.startDate === null}>
                {session.startDate === null
                  ? 'Confirmation Pending. No action needed'
                  : `${moment.utc(session.startDate).local().format('LT')} -
                      ${moment.utc(session.endDate).local().format('LT')}
                    `}
              </CalendarStatus>
            </CalendarItem>
          </CalendarGroup>
          <Button
            type="button"
            onClick={() => next(i + 1, true)}
            kind="small"
            color="primary"
            disabled={session.startDate === null}
          >
            VIEW DETAILS
          </Button>
        </CardContainer>
      );
    });
  };

  return (
    <MainContainer>
      <ScreenerTitle>Screener sessions</ScreenerTitle>
      <ScreenerUser>
        <UserIcon />
        <TextLearner>
          For <b>{`${learner.alias}`}</b>
        </TextLearner>
      </ScreenerUser>
      {sessionsToShow()}
      {sessions[0].sessions.length === 1 && (
        <EmptySession sessionNumber={2} next={next} />
      )}
      <BottomText>
        Please email all images of the learner&apos;s work to{' '}
        <a href="mailto:screeners@learnfully.com">screeners@learnfully.com</a>
      </BottomText>
    </MainContainer>
  );
}
