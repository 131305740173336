import styled from 'styled-components';
import BackgroundImage from '../../assets/images/background-sign.jpg';
import BackgroundImageMobile from '../../assets/images/background-sign-mobile.jpg';

export const Main = styled.div<{ bottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    justify-content: ${({ bottom }) => (bottom ? 'flex-end' : 'center')};
  }

  h1 {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50vh;
  background: ${({ theme }) => theme.colors.quaternary.default};
  background: url(${BackgroundImage});
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    background: url(${BackgroundImageMobile});
    background-position-x: center;
    background-size: cover;
  }
`;

export const Container = styled.div`
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 100%;
  }

  @media (min-width: 426px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > p {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  border-radius: 8px;
  padding: 20px;
  margin-top: 40px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white.default};
  width: 100%;

  @media (min-width: 479px) {
    width: 479px;
  }
`;

export const ContainerPassword = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 46px;
    right: 28px;
    z-index: 4;
    cursor: pointer;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 27px;
`;

export const ForgotButton = styled.button`
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  font-weight: 600;
  background: ${({ theme }) => theme.colors.white.default};
  color: ${({ theme }) => theme.colors.secondary.default};
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: 50px;
`;

export const ContainerError = styled.div`
  margin-top: 20px;
`;
