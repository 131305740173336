import {
  ACCOUNT_REGISTRATION_SUCCESS,
  ACCOUNT_REGISTRATION_FAILURE,
  SEND_ACCOUNT_CREATE,
} from '../pages/SignUp/signUp.action';

const initialState = {
  loading: false,
  accountCreated: false,
  registrationFailed: '',
};

export default function contactUsData(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_REGISTRATION_SUCCESS:
      return {
        ...state,
        accountCreated: true,
        loading: false,
      };
    case SEND_ACCOUNT_CREATE:
      return {
        ...state,
        loading: true,
      };
    case ACCOUNT_REGISTRATION_FAILURE:
      return {
        ...state,
        registrationFailed: action.err,
        loading: false,
      };
    default:
      return state;
  }
}
