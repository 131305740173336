import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateLearnerProfile } from './parentScreener.action';
import styles from './parentScreener.module.css';

const PARENT_SCREENER_URL = `${process.env.REACT_APP_PARENT_SCREENER_URL}`;

export default function ParentScreener() {
  localStorage.setItem('endSurvey', 'false');
  const history = useHistory();
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const learnerId = query.get('learnerId');

  useEffect(() => {
    dispatch(
      updateLearnerProfile({ parentScreenerStatus: 'IN_PROGRESS' }, learnerId)
    );
    window.addEventListener('storage', () => {
      const fromEndSurvey = localStorage.getItem('endSurvey');
      if (fromEndSurvey === learnerId) {
        dispatch(
          updateLearnerProfile({ parentScreenerStatus: 'COMPLETED' }, learnerId)
        );
        history.push('/home');
      }
    });
  }, [history, learnerId, dispatch]);

  return (
    <div className="container-size">
      <div className={styles.main}>
        <h1>
          <b> Parent screener </b>
        </h1>
        <iframe
          title="SM"
          className={styles.iframe}
          src={`${PARENT_SCREENER_URL}${learnerId}`}
        />
      </div>
    </div>
  );
}
