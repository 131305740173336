// my-theme.ts
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  mediaQueries: {
    mobile: '425px',
  },
  colors: {
    main: {
      default: '#19324D',
      dark: '#526579',
      medium: '#8C98A6',
      light: '#C5CCD2',
    },
    primary: {
      default: '#DF6A26',
      dark: '#E78F5C',
      medium: '#EFB492',
      light: '#F7DAC9',
    },
    secondary: {
      default: '#11466B',
      dark: '#4C7490',
      medium: '#88A2B5',
      light: '#C3D1DA',
    },
    tertiary: {
      default: '#F4CDB8',
      dark: '#F7D9CA',
      medium: '#F9E6DB',
      light: '#FCF2ED',
    },
    quaternary: {
      default: '#DBDCDD',
      dark: '#E4E5E5',
      medium: '#EDEDEE',
      light: '#F6F6F6',
    },
    white: {
      default: '#FFFFFF',
      dark: '#FFFFFF',
      medium: '#FFFFFF',
      light: '#FFFFFF',
    },
    success: {
      default: '#194D41',
      dark: '#527970',
      medium: '#8CA6A0',
      light: '#C5D2CF',
    },
    alert: {
      default: '#DE413A',
      dark: '#E6706B',
      medium: '#EEA09C',
      light: '#F7CFCE',
    },
  },
};

export default theme;
