import React, { useEffect } from 'react';
import { ModalContainer, Main, Header, Body, CloseButton } from './styles';
import Transition from '../Common/Transition';

export default function ModalFull(props: {
  full?: boolean;
  mw?: number;
  isOpen?: boolean;
  children: React.ReactNode;
  closeModal?: () => void;
  leftAction?: React.ReactNode;
}) {
  const { mw, full, isOpen, leftAction, children, closeModal } = props;
  useEffect(() => {
    isOpen && window.document.body.classList.add('lock');
    return () => window.document.body.classList.remove('lock');
  }, [isOpen]);

  return (
    <Transition trigger={!!isOpen}>
      <ModalContainer>
        <Main full={full} mw={mw}>
          <Header>
            {leftAction}
            {closeModal && <CloseButton onClick={closeModal} />}
          </Header>
          <Body>
            <div>{children}</div>
          </Body>
        </Main>
      </ModalContainer>
    </Transition>
  );
}
