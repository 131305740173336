import React from 'react';
import SpecialistCard from './SpecialistCard';
import { ISpecialists } from './Dashboard.interfaces';

import { Container, ContainerSpecialists } from './styles';

const Specialists = ({
  title,
  scheduleSession,
  recommendedTutors,
}: ISpecialists) => {
  return (
    <Container>
      {recommendedTutors.length > 0 && (
        <>
          <h3>{title}</h3>
          <ContainerSpecialists>
            {recommendedTutors.map((specialist) => (
              <SpecialistCard
                scheduleSession={() => scheduleSession(specialist)}
                key={Math.random() * 100}
                specialist={specialist}
              />
            ))}
          </ContainerSpecialists>
        </>
      )}
    </Container>
  );
};

export default Specialists;
