import React, { useState } from 'react';
import book from '../../../assets/icons/Book.svg';
import { Modal } from '../..';
import StoryComponent from './StoryComponent';
import {
  ContentWrapperAnswer,
  StoryBoxAnswer,
  ImageAnswer,
  BottomContentAnswer,
} from './styles';
import { IStoryComponentAnswerProps } from './StoryComponent.interfaces';

const bookIcon = book;

export default function StoryComponentAnswer({
  title,
  story,
  image,
  label,
}: IStoryComponentAnswerProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <ContentWrapperAnswer>
      <h3>{title}</h3>
      <StoryBoxAnswer>
        {label && <p>{label}</p>}
        <input type="text" />
      </StoryBoxAnswer>
      <BottomContentAnswer onClick={() => setShowModal(true)}>
        <ImageAnswer src={bookIcon} alt="help" />
        <p>REVIEW STORY</p>
      </BottomContentAnswer>
      <Modal
        mw={762}
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
      >
        <StoryComponent
          title="Story Title"
          story={story}
          image={image}
          questionType={image ? 'image' : 'text'}
        />
      </Modal>
      )
    </ContentWrapperAnswer>
  );
}
