/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  SliderContainer,
  Slide,
  Dots,
  Dot,
  Explanation,
  ImageContainer,
  Image,
} from './styles';
import Button from '../Button';
import { ISliderProps } from './Slider.interfaces';

export default function Slider({ slides, callback }: ISliderProps) {
  const [curr, setCurr] = React.useState(0);
  const { length } = slides;

  const goToNext = () => {
    setCurr(curr + 1);
  };

  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }

  return (
    <SliderContainer>
      {slides.map((s, i) => (
        <Slide active={i === curr} key={s.title}>
          <ImageContainer>
            <Image src={s.image} alt="setp" loading="lazy" />
          </ImageContainer>
          <Explanation>
            <h2>{s.title}</h2>
            <p>{s.description}</p>
          </Explanation>
          {curr < length - 1 ? (
            <Button type="button" onClick={goToNext}>
              Next
            </Button>
          ) : (
            <Button type="button" onClick={callback}>
              Let&apos;s begin
            </Button>
          )}
          <Dots>
            {slides.map((e, idx) => (
              <Dot
                active={idx === curr}
                key={e.title}
                onClick={() => setCurr(idx)}
              />
            ))}
          </Dots>
        </Slide>
      ))}
    </SliderContainer>
  );
}
