import styled from 'styled-components';

interface Props {
  top?: boolean;
  bottom?: boolean;
}

export const TopController = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0;
`;

export const Title = styled.h1`
  @media only screen and (max-width: 600px) {
    font-size: 26px;
  }
`;

export const TopControllerButton = styled.button`
  color: ${({ theme }) => theme.colors.main.default};
  font-size: 17px;
  cursor: pointer;
  background-color: white;
  border: none;
  font-weight: 500;
`;

export const TopControllerTitle = styled.h2`
  font-size: 20px;
  color: #000;
  font-weight: bold;
`;

export const MainContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.main.default};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 620px;
  @media only screen and (max-width: 600px) {
    height: initial;
  }
`;

export const MainContentBorders = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  ${({ top, theme }) =>
    top && `border-top: 1px solid ${theme.colors.main.default}`};
  ${({ bottom, theme }) =>
    bottom && `border-bottom: 1px solid ${theme.colors.main.default}`};
  p {
    margin: 0;
  }
  .progress {
    width: 100%;
    max-width: 400px;
    padding-right: 20px;
  }
`;

export const ProgressBarContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary.default};
  height: 8px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin: 10px 0;
`;

export const ProgressBar = styled.div<{ percentage?: number }>`
  background-color: ${({ theme }) => theme.colors.primary.default};
  position: absolute;
  ${({ percentage }) => (percentage ? `width: ${percentage}%;` : null)}
  height: 100%;
  border-radius: 8px;
`;

export const HelpContent = styled.div`
  text-align: center;
  margin: 30px 0;
  cursor: pointer;
`;
