import React from 'react';
import moment from 'moment';
import {
  MainContainer,
  UserIcon,
  ScreenerUser,
  ViewDetailsTitle,
  ViewDetailsInfo,
  ViewDetailsInfoTitle,
  ViewDetailsDateGroup,
  ViewDetailsDateItem,
  ViewDetailsDisabledTitles,
  ViewDetailsInfoItem,
  ViewDetailsLinkGroup,
  ViewDetailsSessionDetails,
  ViewDetailsSessionDetailsTitle,
  ViewDetailsFooter,
  ViewDetailsContactUs,
} from './styles';

import { IViewSheduleProps } from './kidScreener.interfaces';

export default function ViewScheduleDetails({
  alias,
  session,
  isReviewSession,
  sessionNumber,
}: IViewSheduleProps) {
  const newSession = session.sessions[sessionNumber - 1];
  const details =
    'Meeting with Learnfully educational specialist to go over your learner’s profile and recomendations.';
  return (
    <MainContainer>
      <ScreenerUser>
        <UserIcon />
        <ViewDetailsTitle>
          {alias}
          {!isReviewSession
            ? `'s ${newSession.sessionName}`
            : `’s Program review`}
        </ViewDetailsTitle>
      </ScreenerUser>
      <ViewDetailsInfo>
        <ViewDetailsInfoTitle>
          <b>Session Info</b>
        </ViewDetailsInfoTitle>
        <ViewDetailsDateGroup>
          <ViewDetailsDateItem>
            <ViewDetailsDisabledTitles>Date</ViewDetailsDisabledTitles>
            <ViewDetailsInfoItem>
              {moment.utc(newSession.startDate).local().format('dddd, MMM Do')}
            </ViewDetailsInfoItem>
          </ViewDetailsDateItem>
          <ViewDetailsDateItem>
            <ViewDetailsDisabledTitles>Time</ViewDetailsDisabledTitles>
            {`${moment.utc(newSession.startDate).local().format('LT')} to
              ${moment.utc(newSession.endDate).local().format('LT')}
            `}
          </ViewDetailsDateItem>
        </ViewDetailsDateGroup>
        <ViewDetailsLinkGroup>
          <ViewDetailsDisabledTitles>Zoom</ViewDetailsDisabledTitles>
          <a href={newSession.videoConferenceLink} target="blank">
            {newSession.videoConferenceLink}
          </a>
        </ViewDetailsLinkGroup>
        <ViewDetailsSessionDetails>
          <ViewDetailsSessionDetailsTitle>
            <b>Session Details</b>
          </ViewDetailsSessionDetailsTitle>
          {!isReviewSession ? newSession.sessionDetails : details}
        </ViewDetailsSessionDetails>
        <ViewDetailsFooter>
          Need to cancel or reschedule?
          <ViewDetailsContactUs>
            <a href="mailto:contact@learnfully.com">Contact us</a>
          </ViewDetailsContactUs>
        </ViewDetailsFooter>
      </ViewDetailsInfo>
    </MainContainer>
  );
}
