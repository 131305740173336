/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface IInfoBox {
  done: boolean;
  pending: boolean;
  inProgress: boolean;
  actionRequired: boolean;
}

export const InfoBox = styled.div<IInfoBox>`
  position: absolute;
  right: 30px;
  border-radius: 6px;
  padding: 4px 6px;
  font-size: 13px;

  ${({ theme, done, pending, inProgress, actionRequired }) => {
    if (done)
      return `color: ${theme.colors.success.dark};
              background: ${theme.colors.success.light}; `;
    if (pending)
      return `color: ${theme.colors.main.medium};
              background: ${theme.colors.quaternary.medium}; `;
    if (inProgress)
      return `color: ${theme.colors.secondary.default};
              background: ${theme.colors.quaternary.medium}; `;
    if (actionRequired)
      return `color: ${theme.colors.alert.default};
              background: ${theme.colors.tertiary.light}; `;

    return '';
  }};
`;
