import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from '../../assets/icons/Menu.svg';
import Logo from '../../assets/icons/logo.png';
import Close from '../../assets/icons/Close.svg';
import NavbarData from './Data';
import SignOut from './SignOut';
import {
  MobileWrapper,
  OpenTrigger,
  MobileNavWrapper,
  NavMenu,
  CloseWrapper,
  LogoContainer,
  CloseContainer,
  Title,
  LinkWrapper,
  Wrapper,
  NavWrapper,
  LastLink,
  ContainerSignOut,
} from './styles';

const menuIcon = Menu;
const logoIcon = Logo;
const closeIcon = Close;

const Links = ({ showSidebar }: { showSidebar?: () => void }) => (
  <ul>
    {NavbarData.map((item) => {
      const Icon = item.icon;
      const propsClick = showSidebar
        ? {
            onClick: showSidebar,
          }
        : {};
      return (
        <li key={item.label}>
          <div {...propsClick}>
            <NavLink to={item.link}>
              <LinkWrapper>
                <Icon fill="#ffffff" />
                <span>{item.label}</span>
              </LinkWrapper>
            </NavLink>
          </div>
        </li>
      );
    })}
  </ul>
);

function Navbar({ signUpPage }: { signUpPage?: boolean }) {
  const [sidebar, setSidebar] = useState<boolean>(false);
  const showSidebar = () => setSidebar(!sidebar);
  const { isMobile } = useSelector(
    (state: { devices: { isMobile: boolean } }) => state.devices
  );
  return (
    <>
      {!signUpPage && isMobile ? (
        <MobileWrapper>
          <Link to="#">
            <OpenTrigger onClick={showSidebar} background={menuIcon} />
          </Link>
          <MobileNavWrapper active={sidebar}>
            <NavMenu active={sidebar}>
              <div>
                <CloseWrapper>
                  <LogoContainer>
                    <img src={logoIcon} alt="Logo" />
                  </LogoContainer>
                  <CloseContainer
                    background={closeIcon}
                    onClick={showSidebar}
                  />
                </CloseWrapper>
                <Title>Main Menu</Title>
                <Links showSidebar={showSidebar} />
              </div>
              <ContainerSignOut>
                <SignOut />
              </ContainerSignOut>
            </NavMenu>
          </MobileNavWrapper>
        </MobileWrapper>
      ) : (
        <Wrapper>
          <LogoContainer>
            <img src={logoIcon} alt="Logo" />
          </LogoContainer>
          {!signUpPage && (
            <NavWrapper>
              <Links />
            </NavWrapper>
          )}
          {!signUpPage ? (
            <LastLink>
              <SignOut />
            </LastLink>
          ) : (
            <Link to="/signIn">Sign in</Link>
          )}
        </Wrapper>
      )}
    </>
  );
}

export default Navbar;
