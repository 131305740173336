import styled, { css, keyframes } from 'styled-components';

export const spaceBetweenText = css`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const commonWrapper = css`
  display: flex;
  flex-direction: column;

  .braintree-methods--active:not(.braintree-methods--edit)
    .braintree-method--active,
  .braintree-method:focus
    .braintree-methods--active:not(.braintree-methods--edit)
    .braintree-method--active,
  .braintree-methods--active:not(.braintree-methods--edit)
    .braintree-method--active:focus {
    border-color: ${({ theme }) => theme.colors.primary.default};
  }
  .braintree-placeholder,
  .braintree-placeholder:hover,
  .braintree-heading,
  .braintree-heading:hover,
  .braintree-method .braintree-method__label,
  .braintree-method:hover .braintree-method__label,
  .braintree-large-button,
  .braintree-large-button:hover,
  .braintree-delete-confirmation,
  .braintree-delete-confirmation
    .braintree-delete-confirmation__button-container
    [data-braintree-id='delete-confirmation__no'] {
    font-family: 'SF Pro Text', Arial, Helvetica;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.main.default};
  }

  .braintree-large-button {
    background: ${({ theme }) => theme.colors.quaternary.light};
    font-weight: 400;

    span {
      border-color: ${({ theme }) => theme.colors.main.default};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.main.medium};
      font-weight: 400;

      span {
        border-color: ${({ theme }) => theme.colors.main.medium};
      }
    }
  }

  .braintree-method__label--small {
    color: ${({ theme }) => theme.colors.main.medium};
    font-family: 'SF Pro Text', Arial, Helvetica;
    font-size: 14px;
  }
  .braintree-method,
  .braintree-sheet {
    border-color: ${({ theme }) => theme.colors.quaternary.dark};
  }
  .braintree-delete-confirmation
    .braintree-delete-confirmation__button-container
    .braintree-delete-confirmation__button,
  .braintree-delete-confirmation
    .braintree-delete-confirmation__button-container
    [data-braintree-id='delete-confirmation__yes'] {
    border-top: 1px solid ${({ theme }) => theme.colors.quaternary.dark};
  }
  .braintree-methods--active:not(.braintree-methods--edit)
    .braintree-method--active
    .braintree-method__check {
    background-color: ${({ theme }) => theme.colors.success.dark};
  }
`;

export const commonFooter = css`
  display: flex;
  justify-content: center;
`;

export const PaymentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    height: 100%;
  }
`;

export const BoxesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const PaymentHeader = styled.h2`
  font-size: 28px;
  font-weight: 500;
`;

export const PaymentFooter = styled.div`
  ${commonFooter}
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  ${({ theme, color }) => `color:  ${color || theme.colors.main.dark}`};
`;

export const SummaryWrapper = styled.div`
  ${commonWrapper}
`;
export const PaymentMethodWrapper = styled.div`
  ${commonWrapper}
`;

export const SummaryGetDiscount = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary.light};
  color: ${({ theme }) => theme.colors.primary.default};
  padding: 16px 19px;
  border-radius: 8px;
  svg {
    margin-right: 7px;
  }
`;

export const SummaryFooter = styled.div`
  ${commonFooter}
`;

export const PaymentMethodFooter = styled.div`
  ${commonFooter}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

export const BundlePaymentFooter = styled.div`
  ${commonFooter}
  margin-bottom: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  border-top: 1px solid ${({ theme }) => theme.colors.quaternary.default};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OrderAmmount = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 20px;
  padding: 16px 0;
`;

export const OrderItems = styled.div<{ dark?: boolean; medium?: boolean }>`
  padding: 5px 0;
  ${({ dark, theme }) => dark && `color: ${theme.colors.main.dark}`}
  ${({ medium, theme }) => medium && `color: ${theme.colors.main.medium}`}
`;

export const TotalPrice = styled.div`
  ${spaceBetweenText}
  font-size: 20px;
  font-weight: 700;
  padding: 25px 0;
`;

export const DiscountSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 17px;
`;

export const DiscountTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.default};
  margin: 16px 0;
`;

export const DiscountActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    flex-direction: column;
    min-height: 120px;
  }
`;

export const DiscountActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  input {
    background-color: rgb(237, 237, 238);
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    flex-direction: column;
  }
`;

export const EndPayment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const EndPaymentTitle = styled.h2`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 28;
  font-weight: 500;
`;

export const EndPaymentSubtitle = styled.div`
  max-width: 456px;
  text-align: center;
  margin-bottom: 20px;
`;

export const EndPaymentSubtitleTitle = styled.div`
  width: 100%;
`;

export const EndPaymentContact = styled.div<{
  mt?: number;
}>`
  width: 100%;
  text-align: center;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '20px')};
  a {
    color: ${({ theme }) => theme.colors.primary.default};
  }
`;

export const ContactUsFooter = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  margin-top: 6px;
`;

export const CheckContainer = styled.div`
  display: flex;
`;

export const DisabledText = styled.div<{
  mw?: number;
  mt?: number;
  mb?: number;
}>`
  opacity: 0.5;
  text-align: center;
  max-width: ${({ mw }) => (mw ? `${mw}px` : '315px')};
  margin-top: ${({ mt }) => mt && `${mt}px`};
  margin-bottom: ${({ mb }) => mb && `${mb}px`};
`;

export const PaymentTitle = styled.h2`
  font-size: 28px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 8px;
`;

export const PaymentSubTitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const QuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  user-select: none;
`;

export const QuantityIcon = styled.div`
  cursor: pointer;
  padding: 0 10px;
  svg {
    color: ${({ theme }) => theme.colors.primary.default};
  }
`;

export const QuantityAmmount = styled.div`
  width: 22px;
  text-align: center;
  margin-top: 2px;
`;

export const PaymentUser = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
`;

export const ImageContent = styled.div`
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  overflow: hidden;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.quaternary.medium};
  margin: 0 10px;
`;

export const ScreenerDescription = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 14px;
`;

export const ScreenerLearner = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(255, 255, 255, 0.8);
`;

export const LoadingBlock = styled.div`
  position: relative;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
`;

const appear = keyframes`
  0%, 20% { opacity: 0 }
  100% { opacity: 1 }
`;

export const Appear = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  animation: ${appear} 2s forwards;
`;
