import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { getLearnerSessionsById } from 'commonActions';

import { IPackageItem } from 'pages/Payment/Payment.interfaces';
import {
  resetPaymentFailure,
  resetPaymentOptions,
} from 'pages/Payment/payment.action';
import { Summary, PaymentMethod } from 'pages/Payment';

import { IPaymentOptions } from 'components/Dashboard/Dashboard.interfaces';

import IState from 'reducers';

import warning from 'assets/icons/Warning.svg';

import { Modal } from '../..';

import { ILearnerProps } from './Learners.interfaces';

import {
  MainContent,
  UserIcon,
  TopContent,
  BottomContent,
  LearningSessions,
  LearningSessionsItem,
  BackGroup,
} from './styles';

export default function Learner({ learner }: ILearnerProps) {
  const dispatch = useDispatch();
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const { isMobile } = useSelector(
    (state: { devices: { isMobile: boolean } }) => state.devices
  );
  const { purchaseFailure, bundlesPurchased } = useSelector(
    (state: IState) => state.payment
  );

  const [paymentStep, setPaymentStep] = useState<number>(0);
  const { sessionsByLearner } = useSelector((state: IState) => state.sessions);

  const learnerSessions = sessionsByLearner[learner.learnerId];

  let done = 0;
  let upcoming = 0;

  learnerSessions &&
    learnerSessions.sessionDetails.forEach((e) =>
      e.sessions.forEach((e) => {
        if (e.sessionStatus.name === 'COMPLETED') {
          done += 1;
        } else {
          upcoming += 1;
        }
      })
    );

  useEffect(() => {
    dispatch(getLearnerSessionsById(learner.learnerId));
  }, [dispatch, learner]);

  useEffect(() => {
    dispatch(getLearnerSessionsById(learner.learnerId));
  }, [dispatch, learner.learnerId, bundlesPurchased]);

  const [pagePile, setPagePile] = useState<string[]>([]);

  const handleAddSessions = () => {
    setPagePile(() => ['summary']);
    setShowPayment(true);
  };

  const [paymentOptions, setPaymentOptions] = useState<IPaymentOptions>({
    code: '',
    quantity: 0,
    total: 0,
    totalDiscount: 0,
    enrollmentStatus: false,
    name: '',
  });

  const [packageBundle, setPackageBundle] = useState<IPackageItem>({
    description: '',
    name: '',
    productId: '',
    price: 0,
    sessionsAmount: 0,
  });

  const handleSummaryNext = (options, bundle) => {
    setPagePile((prevState) => [...prevState, 'payment']);
    setPaymentOptions(options);
    setPackageBundle(bundle);
  };

  const handleCloseModal = () => {
    if (paymentStep === 1) {
      if (purchaseFailure.status) {
        dispatch(resetPaymentFailure());
      }
      setPaymentStep(0);
    }
    dispatch(resetPaymentOptions());
    setShowPayment(false);
  };

  const paymentStepsToShow = () => {
    switch (pagePile[pagePile.length - 1]) {
      case 'summary':
        return <Summary next={handleSummaryNext} actualLearner={learner} />;
      case 'payment':
        return (
          <PaymentMethod
            closeModal={handleCloseModal}
            learnerId={learner.learnerId}
            package={packageBundle}
            paymentOptions={paymentOptions}
          />
        );
      default:
        return null;
    }
  };

  const BackButton = () => {
    const goBack = () => {
      if (pagePile.length === 1) {
        setPagePile([]);
        setShowPayment(false);
      } else {
        let newPagePile = [...pagePile];
        newPagePile = newPagePile.slice(0, -1);
        setPagePile(newPagePile);
      }
    };

    return !bundlesPurchased ? (
      <BackGroup onClick={goBack} className="cursor-pointer">
        <FontAwesomeIcon icon={faAngleLeft} />
      </BackGroup>
    ) : null;
  };

  return (
    <MainContent>
      <Modal
        full={isMobile}
        mw={550}
        isOpen={showPayment}
        closeModal={handleCloseModal}
        leftAction={pagePile.length > 1 && BackButton()}
      >
        {paymentStepsToShow()}
      </Modal>
      <TopContent>
        <div>
          <UserIcon letter={learner.alias.charAt(0)} />
          <span>{learner.alias}</span>
        </div>
        <p>{learner.grade}</p>
      </TopContent>
      {learner.profileCompleted && learnerSessions && (
        <div>
          <LearningSessions>
            <div>
              <b>Learning Sessions</b>
            </div>
            <LearningSessionsItem>
              <div>Done: {done}</div>
              <div>Upcoming: {upcoming}</div>
            </LearningSessionsItem>
          </LearningSessions>
          <BottomContent>
            <div>
              <img src={warning} alt="" />
              <span>
                {learnerSessions.totalMinutesAvailable} minutes left in your
                bundle!
              </span>
            </div>
            <button type="button" onClick={handleAddSessions}>
              ADD SESSIONS
            </button>
          </BottomContent>
        </div>
      )}
    </MainContent>
  );
}
