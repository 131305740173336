import styled from 'styled-components';

export const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.main.light};
  border-radius: 8px;
  padding: 20px 30px;
  position: relative;
  margin-bottom: 10px;
`;

export const CardTitle = styled.h2`
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  width: 50%;
  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    font-size: 16px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
