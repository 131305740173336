import {
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
  RESET_DATA,
} from '../components/ForgotPassword/forgot.action';

const initialState = {
  success: false,
  failure: '',
  loading: false,
};

export default function forgotPassword(state = initialState, action) {
  switch (action.type) {
    case SEND_EMAIL:
      return {
        ...state,
        loading: true,
        failure: '',
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        failure: action.err,
        loading: false,
      };
    case RESET_DATA:
      return {
        ...state,
        success: false,
        failure: '',
        loading: false,
      };
    default:
      return state;
  }
}
