import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { getUser, cleanUser } from '../../commonActions';

export const SEND_ACCOUNT_CREATE = 'SEND_ACCOUNT_CREATE';
export const ACCOUNT_REGISTRATION_SUCCESS = 'ACCOUNT_REGISTRATION_SUCCESS';
export const ACCOUNT_REGISTRATION_FAILURE = 'ACCOUNT_REGISTRATION_FAILURE';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

const accountFormDataSend = () => ({
  type: SEND_ACCOUNT_CREATE,
});

const accountRegistrationSuccess = () => ({
  type: ACCOUNT_REGISTRATION_SUCCESS,
});

const accountRegistrationFailure = (err) => ({
  type: ACCOUNT_REGISTRATION_FAILURE,
  err,
});

export const sendAccountCreateData = (data) => {
  return (dispatch) => {
    dispatch(accountFormDataSend());

    axios
      .post(`${HOST_PROFILE}user/register`, data)
      .then((res) => {
        const { accessToken, refreshToken } = res.data;
        const token: { 'custom:learnfully_user_id': string } = jwt_decode(
          accessToken
        );
        const id = token['custom:learnfully_user_id'];
        localStorage.setItem('token', accessToken);
        localStorage.setItem('userID', id);
        localStorage.setItem('refreshToken', refreshToken);
        dispatch(accountRegistrationSuccess());
        dispatch(cleanUser());
        dispatch(getUser());
      })
      .catch((err) => {
        dispatch(accountRegistrationFailure(err.message));
      });
  };
};
