import axios from 'axios';

export const SEND_RECOVERY = 'SEND_RECOVERY';
export const SEND_RECOVERY_SUCCESS = 'SEND_RESET_SUCCESS';
export const SEND_RECOVERY_FAILURE = 'SEND_RESET_FAILURE';
export const RESET_DATA = 'RESET_DATA';

const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/user/auth/recover`;

const sendRecovery = () => ({
  type: SEND_RECOVERY,
});

const sendRecoverySuccess = () => ({
  type: SEND_RECOVERY_SUCCESS,
});

const sendRecoveryFailure = (err) => ({
  type: SEND_RECOVERY_FAILURE,
  err,
});

export const clearData = () => ({
  type: RESET_DATA,
});

export const recoveryPassword = (payload) => {
  return (dispatch) => {
    dispatch(sendRecovery());
    const token = localStorage.getItem('token');
    const { email, code, newPassword } = payload;
    axios
      .put(
        `${HOST_PROFILE}`,
        {
          email,
          code,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        dispatch(sendRecoverySuccess());
      })
      .catch((err) => {
        dispatch(sendRecoveryFailure(err.errorMessage));
      });
  };
};
