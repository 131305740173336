/* eslint-disable react/jsx-no-bind */
import React, { useState, useRef } from 'react';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { useSelector, useDispatch } from 'react-redux';
import IState from '../../reducers';
import { saveScheduledEventDynamic } from '../../pages/KidScreenerSchedule/kidScreenerSchedule.action';

import {
  CalendlyContainer,
  CalendlySideDescription,
  CalendlySideContainer,
  CalendlyDescriptionTitle,
  ContactUsMessage,
  ContactUsLink,
  UserIcon,
  LoadingContainer,
  UserImg,
  UserInfo,
  Subject,
  UserContainer,
} from './styles';
import {
  ICalendlyPrefillProps,
  ICalendlySpecialistProps,
  IPrefillRef,
} from './Dashboard.interfaces';
import specialistLogo from '../../assets/images/LF-Avatar-Blue.png';

const InlineWidgetMemo = React.memo((props: ICalendlyPrefillProps) => (
  <InlineWidget {...props} />
));
export default function SpecialistCalendly({
  alias,
  isLastStep,
  specialistSelected,
  calendarUrl,
  learnerId,
}: ICalendlySpecialistProps) {
  const contactUsRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [showContactUs, setShowContactUs] = useState(true);
  const { info } = useSelector((state: IState) => state.user);

  const dispatch = useDispatch();

  const sessionScheduled = (event) => {
    const eventArr = event.data.payload.event.uri.split('/');
    const inviteeArr = event.data.payload.invitee.uri.split('/');
    const calendlyObj = {
      eventId: eventArr[eventArr.length - 1],
      inviteeId: inviteeArr[inviteeArr.length - 1],
      sessionType: 'TUTORING',
      email: specialistSelected.email,
      learnerId,
    };
    dispatch(saveScheduledEventDynamic(calendlyObj));
    setShowContactUs(true);
    isLastStep && isLastStep(false);
  };

  const prefillRef = useRef<IPrefillRef>({
    name: alias,
    email: info.email,
  });
  const styleRef = useRef({
    height: '700px',
  });
  return (
    <>
      <CalendlyContainer>
        <CalendlySideDescription>
          <UserContainer>
            <UserIcon />
            <CalendlyDescriptionTitle>
              <b>{`${alias}'s New Session`}</b>
            </CalendlyDescriptionTitle>
          </UserContainer>
          <UserInfo>
            <UserImg>
              <img src={specialistLogo} alt="specialist" />
            </UserImg>
            <UserInfo>
              <b>{`${specialistSelected.firstName} ${specialistSelected.lastName}`}</b>
              <Subject>{specialistSelected.titleDegree}</Subject>
            </UserInfo>
          </UserInfo>
        </CalendlySideDescription>
        <CalendlySideContainer>
          <CalendlyEventListener
            onEventTypeViewed={() => {
              setLoading(false);
              setShowContactUs(false);
              isLastStep && isLastStep(false);
            }}
            onDateAndTimeSelected={() => isLastStep && isLastStep(true)}
            onEventScheduled={sessionScheduled}
          />
          {loading && <LoadingContainer>Loading Calendar...</LoadingContainer>}
          <InlineWidgetMemo
            url={`${calendarUrl}?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=DF6A26&`}
            prefill={prefillRef.current}
            styles={styleRef.current}
          />
        </CalendlySideContainer>
      </CalendlyContainer>
      {showContactUs && (
        <ContactUsMessage ref={contactUsRef}>
          Need to cancel or reschedule?
          <ContactUsLink>
            <a href="mailto:contact@learnfully.com">Contact us</a>
          </ContactUsLink>
        </ContactUsMessage>
      )}
    </>
  );
}
