import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';
import { ContainerNotification } from './styles';
import { INotificationProps } from './Dashboard.interfaces';

const Notification = ({
  notificationText,
  buttonText,
  handleClick,
}: INotificationProps) => {
  return (
    <ContainerNotification>
      <span>
        <FontAwesomeIcon icon={faInfoCircle} />
        {notificationText}
      </span>
      {buttonText && (
        <Button type="button" kind="small" onClick={handleClick}>
          {buttonText}
        </Button>
      )}
    </ContainerNotification>
  );
};

export default Notification;
