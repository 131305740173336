import React from 'react';
import { useDrop } from 'react-dnd';
import { DropFigureBox } from './styles';
import { IDropFigureProps } from './DragableImagesQuestion.interfaces';

export default function DropFigure({
  position,
  state,
  type = 'box',
  children,
}: IDropFigureProps) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: type,
    drop: () => ({ position }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isAllowed = canDrop && isOver && !state;
  const opacity = isAllowed ? '0.3' : '1';
  return (
    <DropFigureBox ref={drop} style={{ opacity }}>
      {children}
    </DropFigureBox>
  );
}
