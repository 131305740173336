import styled from 'styled-components';

interface IPriceBoxComponent {
  premium: boolean | undefined;
}

export const PriceBoxComponent = styled.div<IPriceBoxComponent>`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  width: 100%;
  align-items: center;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  border-radius: 8px;

  ${({ premium, theme }) =>
    premium && `background-color: ${theme.colors.quaternary.default}`};

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    flex-direction: column;
    padding: 16px;
  }
`;

export const PriceBoxTitle = styled.div<{
  size?: number;
  dark?: boolean;
  medium?: boolean;
}>`
  ${({ size }) => `font-size: ${size || 16}px`};
  font-weight: 400;
  text-align: right;
  ${({ theme, dark }) => dark && `color: ${theme.colors.main.dark}`};
  ${({ theme, medium }) => medium && `color: ${theme.colors.main.medium}`};
`;

export const Price = styled.h2`
  font-weight: 500;
  font-size: 28px;
  margin: 0;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.primary.default};
`;

export const PriceSubtitle = styled.div`
  font-size: 0.8em;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.main.default};
`;

export const PriceDescription = styled.div<{ align?: string }>`
  font-size: 0.8em;
  ${({ align }) => `text-align:${align || 'left'}`};
  color: ${({ theme }) => theme.colors.secondary.default};
  margin-bottom: 0.8em;
`;

export const BundleSection = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1.5fr 92px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 20px;
`;

export const BundleSectionTitles = styled.h3<{
  first?: boolean;
  center?: boolean;
  last?: boolean;
}>`
  font-size: 22px;
  text-align: ${({ center }) => center && 'center'};
  text-align: ${({ last }) => last && 'right'};
  font-weight: 400;
  white-space: nowrap;
`;

export const BundleSectionContent = styled.div<{
  center?: boolean;
}>`
  text-align: ${({ center }) => center && 'center'};
`;

export const PackageIncludeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.8em;
  align-self: flex-start;
`;

export const PackageList = styled.div<{ spaceBetween?: boolean }>`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding: 10px 0;
  font-weight: 600;
  margin-bottom: 0.8em;
  display: flex;
  align-self: flex-start;
`;

export const IncludeItem = styled.div<{ color?: string }>`
  display: flex;
  margin-bottom: 0.7em;
  align-items: center;
  font-size: 16px;
  ${({ theme, color }) => `color:  ${color || theme.colors.secondary.default}`};
  svg {
    color: ${({ theme }) => theme.colors.main.dark};
    margin-right: 11px;
  }
`;

export const PackageFooter = styled.div`
  ${({ theme, color }) => `color:  ${color || theme.colors.main.dark}`};
  ${({ theme, color }) =>
    `background-color:  ${color || theme.colors.quaternary.light}`};
  font-size: 16px;
  margin-top: 26px;
  padding: 8px;
  border-radius: 8px;
`;

export const BundleDescription = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  font-size: 13px;
  font-style: italic;
`;

export const PaymentAccordion = styled.div<{ spaceBetween?: boolean }>`
  width: 100%;
  padding: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.default};
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`;

export const AccordionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
`;
