import React from 'react';
import { useLocation } from 'react-router-dom';

export default function EndSurvey() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  localStorage.setItem('endSurvey', `${query.get('user')}`);

  return <div>End survey</div>;
}
