/* eslint-disable class-methods-use-this */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from './helpers/useAuthentication';

const ProtectedRoute = ({
  component: Component,
  path,
  ...rest
}: {
  component: React.ComponentType;
  path: string;
}) => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route
      {...rest}
      render={() => {
        return isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/signIn',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
