import {
  GET_LEARNER_SESSIONS_SUCCESS,
  CLEAN_SESSIONS,
  UPDATE_LEARNER_BY_ID,
  CALENDLY_RETRY_LEARNERS,
} from '../commonActions';

const initialState = {
  isScreener: false,
  learnerId: '',
  totalSessions: 0,
  retryLearnerSessions: false,
  sessionDetails: [
    {
      minutesPerSession: 0,
      packageDetailId: '',
      packageId: '',
      scheduledSessions: 0,
      sessions: [],
      totalSessions: 0,
      type: '',
    },
  ],
  sessionsByLearner: {},
};

export default function LearnerSessions(state = initialState, action) {
  switch (action.type) {
    case CLEAN_SESSIONS:
      return initialState;
    case GET_LEARNER_SESSIONS_SUCCESS:
      return {
        ...state,
        ...action.sessions,
        retryLearnerSessions: false,
      };
    case CALENDLY_RETRY_LEARNERS:
      return {
        ...state,
        retryLearnerSessions: true,
      };
    case UPDATE_LEARNER_BY_ID:
      return {
        ...state,
        sessionsByLearner: {
          ...state.sessionsByLearner,
          [action.learnerId]: action.learnerData,
        },
        retryLearnerSessions: false,
      };
    default:
      return state;
  }
}
