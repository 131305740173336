import styled from 'styled-components';

export const ContentWrapper = styled.div`
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  h3 {
    text-align: center;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.main.default};
    font-weight: 500;
    padding-top: 20px;
  }
`;

export const MainContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-auto-rows: 1fr;
  margin: 30px 0;
  div {
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
    margin: 10px;
    border-radius: 8px;
    padding: 20px;
    font-size: 22px;
    font-weight: normal;
    input {
      margin: 0 10px;
    }
  }
`;
