/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import {
  useDrag,
  useDrop,
  DropTargetMonitor,
  DragSourceMonitor,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Card } from './styles';
import { IDragableCardProps, DragItem } from './SortableQuestion.interfaces';

export const ItemTypes = {
  CARD: 'card',
};

const style = {
  padding: '0.5rem 1rem',
  cursor: 'move',
  height: '40px',
};

export default function DragableCard({
  id,
  text,
  index,
  moveCard,
}: IDragableCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  const backgroundColor = isDragging ? 'grey' : 'white';
  const color = isDragging ? 'grey' : 'black';
  drag(drop(ref));
  return (
    <Card ref={ref} style={{ ...style, opacity, backgroundColor, color }}>
      {text}
    </Card>
  );
}
