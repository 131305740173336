/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;

  p {
    text-align: center;
    font-size: 17px;
    padding: 20px 0;
  }

  input {
    margin: 0 auto;
    width: 330px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
      width: 100%;
    }
  }
`;

export const WrapperSuccess = styled.div`
  text-align: center;

  h2 {
    margin: 40px 0 0;
  }

  p {
    text-align: center;
    padding: 20px 0;
    color: ${({ theme }) => theme.colors.main.dark};
  }
`;

export const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
`;
