import styled from 'styled-components';
import theme from '../../../config/theme';

export { UserIcon } from '../../Common';

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.main.default};
  font-size: 20px;
  margin: 0 0 16px 0;
  font-weight: bold;
`;

export const Wrapper = styled.div`
  margin: 20px 0;
`;

export const MainContent = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.quaternary.dark};
  border-radius: 8px;
  margin-bottom: 15px;
`;

export const TopContent = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.dark};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  div {
    display: flex;
    align-items: center;
    span {
      color: ${({ theme }) => theme.colors.main.default};
      font-size: 20px;
      font-weight: bold;
    }
  }
  p {
    color: ${({ theme }) => theme.colors.main.medium};
    font-weight: normal;
  }
`;

export const BottomContent = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.quaternary.medium};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  div {
    display: flex;
    align-items: center;
    img {
      width: 14px;
      height: 14px;
    }
    span {
      padding: 0 7px;
      color: ${({ theme }) => theme.colors.main.default};
      font-size: 13px;
    }
  }
  button {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.white.default};
    color: ${({ theme }) => theme.colors.primary.default};
    font-size: 13px;
    border: none;
    cursor: pointer;
    padding: 6px 10px;
    font-weight: 600;
  }
`;

export const LearningSessions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const LearningSessionsItem = styled.div`
  color: ${theme.colors.main.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.dark};
  padding: 10px 0;
  display: flex;
  div {
    width: 50%;
  }
`;

export const BackGroup = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary.default};
  svg {
    margin-right: 14px;
  }
`;
