import React, { useCallback, useState } from 'react';
import update from 'immutability-helper';
import DragableCard from './DragableCard';
import { ContentWrapper, OptionsContainer, CardId, StoryBox } from './styles';
import { ISortableQuestionProps } from './SortableQuestion.interfaces';

export default function SortableQuestion({ title }: ISortableQuestionProps) {
  const [cards, setCards] = useState([
    {
      id: 1,
      text: 'Kyle traded his gold coins for a horse.',
    },
    {
      id: 2,
      text: 'Kyle traded his horse for a cow, and his cow for a goose.',
    },
    {
      id: 3,
      text: 'Kyle took his bag of gold coins and set out to visit his mother.',
    },
    {
      id: 4,
      text: 'Kyle traded his goose for an ordinary stone.',
    },
  ]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  const renderCard = (card: { id: number; text: string }, index: number) => {
    return (
      <DragableCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
      />
    );
  };

  return (
    <ContentWrapper>
      <h3>{title}</h3>
      <StoryBox>Story: Kyle goes to the market.</StoryBox>
      <OptionsContainer>
        <div>
          {cards.map((card, index) => (
            <CardId key={card.id}>{index + 1}</CardId>
          ))}
        </div>
        <div>{cards.map((card, index) => renderCard(card, index))}</div>
      </OptionsContainer>
    </ContentWrapper>
  );
}
