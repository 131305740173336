import React from 'react';
import { useSelector } from 'react-redux';
import IState from '../../reducers';

import Steps from '../../components/Steps';
import Dashboard from '../../components/Dashboard';

export default function Home() {
  const {
    actualLearner: { profileCompleted },
  } = useSelector((state: IState) => state.user);

  return profileCompleted ? <Dashboard /> : <Steps />;
}
