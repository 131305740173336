import { HomeIcon, ProfileIcon } from './icons';

const NavbarData = [
  {
    label: 'Home',
    link: '/home',
    icon: HomeIcon,
  },
  {
    label: 'My account',
    link: '/myAccount',
    icon: ProfileIcon,
  },
];

export default NavbarData;
