/* eslint-disable import/prefer-default-export */
import { useDispatch } from 'react-redux';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { IsExpired } from './timeHelper';
import { logout } from '../commonActions';

const useIsAuthenticated = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  if (!token) dispatch(logout);
  if (token) {
    const { exp } = jwt_decode<JwtPayload>(token || '');
    if (IsExpired(exp)) dispatch(logout);
    return !IsExpired(exp);
  }
  return false;
};

export { useIsAuthenticated };
