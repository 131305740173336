import axios from 'axios';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';
export const RESET_DATA = 'RESET_DATA';

const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/user/auth/recover`;

const sendEmail = () => ({
  type: SEND_EMAIL,
});

const sendEmailSuccess = () => ({
  type: SEND_EMAIL_SUCCESS,
});

const sendEmailFailure = (err) => ({
  type: SEND_EMAIL_FAILURE,
  err,
});

export const clearData = () => ({
  type: RESET_DATA,
});

export const forgotPasswordSendEmail = (payload) => {
  return (dispatch) => {
    dispatch(sendEmail());
    const token = localStorage.getItem('token');
    axios
      .post(
        `${HOST_PROFILE}`,
        {
          email: payload.email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        dispatch(sendEmailSuccess());
      })
      .catch((err) => {
        dispatch(sendEmailFailure(err));
      });
  };
};
