import axios from 'axios';
import { IsExpired } from '../../helpers/timeHelper';
import {
  getLearnerSessions,
  logout,
  CALENDLY_RETRY_LEARNERS,
} from '../../commonActions';

const SAVE_CALENDLY_SESSION_SUCCESS = 'SAVE_CALENDLY_SESSION_SUCCESS';
const SAVE_CALENDLY_SESSION_FAILURE = 'SAVE_CALENDLY_SESSION_FAILURE';
const HOST_TUTORING = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/tutoring/api/v1/`;

export const saveScheduledEventSuccess = (packageId) => ({
  type: SAVE_CALENDLY_SESSION_SUCCESS,
  packageId,
});
export const saveScheduledEventFailure = () => ({
  type: SAVE_CALENDLY_SESSION_FAILURE,
});

export const retryGetLearners = () => ({
  type: CALENDLY_RETRY_LEARNERS,
});

export const saveScheduledEvent = (packageId, calendlyObj) => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (IsExpired(token)) {
      dispatch(logout);
      localStorage.removeItem('token');
      localStorage.clear();
    } else {
      axios
        .post(`${HOST_TUTORING}package/${packageId}/session`, calendlyObj, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(saveScheduledEventSuccess(res.data.id));

          axios
            .get(
              `${HOST_TUTORING}package/${packageId}/session/${res.data.id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            )
            .then(() => dispatch(getLearnerSessions()))
            .catch(() => {
              dispatch(retryGetLearners());
            });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          dispatch(saveScheduledEventFailure());
        });
    }
  };
};

export const saveScheduledEventDynamic = (calendlyObj) => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (IsExpired(token)) {
      dispatch(logout);
      localStorage.removeItem('token');
      localStorage.clear();
    } else {
      axios
        .post(`${HOST_TUTORING}package/session`, calendlyObj, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          dispatch(saveScheduledEventSuccess(res.data.id));

          axios
            .get(`${HOST_TUTORING}package/session/${res.data.id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            })
            .then(() => dispatch(getLearnerSessions()))
            .catch(() => {
              dispatch(retryGetLearners());
            });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          dispatch(saveScheduledEventFailure());
        });
    }
  };
};
