import styled, { css } from 'styled-components';
import { StyleProps, IButton } from './Button.interfaces';

const Primary = ({ theme, disabled }) => css<StyleProps>`
  min-width: 117px;
  min-height: 55px;
  background: ${theme.colors.primary.default};
  color: ${theme.colors.white.default};
  border-radius: 8px;
  border: 1px solid ${theme.colors.primary.default};

  &:hover {
    background: ${!disabled && theme.colors.tertiary.default};
    border-color: ${!disabled && theme.colors.tertiary.default};
    color: ${!disabled && theme.colors.primary.default};

    &:focus,
    &:active {
      background: ${!disabled && theme.colors.primary.default};
      color: ${!disabled && theme.colors.white.default};
      border: 1px solid ${theme.colors.main.default};
      box-shadow: 0px 0px 3px ${theme.colors.main.default};
    }
  }

  ${disabled && ` opacity: 0.5; cursor: default;`}
`;

const Secondary = ({ theme, disabled }) => css<StyleProps>`
  background: ${theme.colors.white.default};
  min-width: 117px;
  min-height: 55px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.primary.default};
  color: ${theme.colors.primary.default};

  &:hover {
    border-color: ${!disabled && theme.colors.tertiary.default};

    &:focus,
    &:active {
      border-color: ${!disabled && theme.colors.primary.default};
    }
  }

  &:focus,
  &:active {
    border: 2px solid ${theme.colors.primary.default};
    box-shadow: 0px 0px 3px ${theme.colors.main.default};
  }

  ${disabled && ` opacity: 0.5; cursor: default;`}
`;

const Quaternary = ({ theme, disabled, color }) => css<StyleProps>`
  background: ${theme.colors.white.default};
  border: 1px solid ${theme.colors.quaternary.medium};
  border-radius: 100px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;

  ${color &&
  ` color: ${theme.colors.primary.default};
    background: ${theme.colors[color].medium};
    border-color: ${theme.colors[color].medium}; `}

  &:hover {
    border-color: ${!disabled && theme.colors.main.medium};
    box-shadow: 0px 0px 2px ${theme.colors.main.medium};

    ${color &&
    ` 
    border-color: ${theme.colors[color].medium}; 
    box-shadow: 0px 0px 3px ${theme.colors[color].medium};
     `}

    &:focus,
    &:active {
      border: 3px solid ${theme.colors.main.medium};
      box-shadow: 0px 0px 3px ${theme.colors.main.medium};
      ${color &&
      ` 
      border-color: ${theme.colors[color].medium}; 
      box-shadow: 0px 0px 3px ${theme.colors[color].medium};
     `}
    }
    ${disabled && ` opacity: 0.5; cursor: default;`}
  }
`;

const TextButton = ({ theme, disabled }) => css<StyleProps>`
  color: ${theme.colors.main.default};
  background: transparent;
  border: 0;

  &:hover {
    ${!disabled &&
    ` font-weight: bold;
      color: ${theme.colors.secondary.default};`}

    &:focus,
    &:active {
      color: ${!disabled && theme.colors.main.default};
    }
  }

  &:focus,
  &:active {
    font-weight: bold;
  }

  ${disabled && `opacity: 0.5; `}
`;

export const Small = ({ theme, disabled, color }) => css<StyleProps>`
  background: ${theme.colors.white.default};
  border: 1px solid ${theme.colors.main.default};
  border-radius: 100px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;

  ${color &&
  ` color: ${theme.colors.white.default};
    background: ${theme.colors[color].default};
    border-color: ${theme.colors[color].default}; `}

  &:hover {
    border-color: ${!disabled && theme.colors.main.default};
    box-shadow: 0px 0px 2px ${theme.colors.main.default};

    ${color &&
    ` 
    border-color: ${theme.colors[color].default}; 
    box-shadow: 0px 0px 3px ${theme.colors[color].default};
     `}

    &:focus,
    &:active {
      border: 3px solid ${theme.colors.main.default};
      box-shadow: 0px 0px 3px ${theme.colors.main.default};
      ${color &&
      ` 
      border-color: ${theme.colors[color].default}; 
      box-shadow: 0px 0px 3px ${theme.colors[color].default};
     `}
    }
    ${disabled && ` opacity: 0.5; cursor: default;`}
  }

  &:focus,
  &:active {
    border-color: ${theme.colors.main.default};
    box-shadow: 0px 0px 3px ${theme.colors.main.default};

    ${color &&
    ` 
      border-color: ${theme.colors[color].default}; 
      box-shadow: 0px 0px 3px ${theme.colors[color].default};
     `}
  }

  ${disabled && `opacity: 0.5; `}
`;

const Button = styled.button<IButton>`
  font-weight: 600;
  cursor: pointer;
  outline: none;
  transition: all 0.25s linear;
  padding: 6px 12px 4.5px 12px;
  color: ${({ theme }) => theme.colors.main.default};
  letter-spacing: 0.5px;
  ${({ full }) => full && `width: 100%`};
  ${({ width, full }) => width && !full && `width: ${width}px`};

  ${({ theme, kind, disabled, color }) => {
    switch (kind) {
      case 'primary':
      default:
        return Primary({ theme, disabled });
      case 'secondary':
        return Secondary({ theme, disabled });
      case 'quaternary':
        return Quaternary({ theme, disabled, color });
      case 'text':
        return TextButton({ theme, disabled });
      case 'small':
        return Small({ theme, disabled, color });
    }
  }}
  ${({ bg, theme }) => bg && `background-color:  ${theme.colors[bg].default}`};
`;

export default Button;
