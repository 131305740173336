/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import Button from '../Button';
import help from '../../assets/icons/Help.svg';
import {
  TopController,
  Title,
  TopControllerButton,
  TopControllerTitle,
  MainContent,
  MainContentBorders,
  ProgressBarContainer,
  ProgressBar,
  HelpContent,
} from './styles';
import { IQuestionWrapperProps } from './QuestionWrapper.interfaces';

const helpIcon = help;

export default function QuestionWrapper(props: IQuestionWrapperProps) {
  const {
    title,
    currentIndex = 0,
    totalItems = 0,
    onClickNext = () => {},
    onClickBack = () => {},
    children,
  } = props;
  return (
    <div className="container-size">
      <Title>
        <b>{title}</b>
      </Title>
      <TopController>
        <TopControllerButton type="button" onClick={onClickBack}>
          Back
        </TopControllerButton>
        <TopControllerTitle>Pick the right answer!</TopControllerTitle>
        <TopControllerButton type="button" onClick={onClickNext}>
          Skip
        </TopControllerButton>
      </TopController>
      <MainContent>
        <MainContentBorders bottom>
          <p>
            Pick the right answer and select <strong>Done</strong> after you’re
            finished.
          </p>
        </MainContentBorders>
        {children}
        <MainContentBorders top>
          <div className="progress">
            <p>
              Question {currentIndex + 1}/{totalItems}
            </p>
            <ProgressBarContainer>
              <ProgressBar
                percentage={((currentIndex + 1) * 100) / totalItems}
              />
            </ProgressBarContainer>
          </div>
          <Button type="button" onClick={onClickNext}>
            Done/Next
          </Button>
        </MainContentBorders>
      </MainContent>
      <HelpContent>
        <img src={helpIcon} />
      </HelpContent>
    </div>
  );
}
