import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  input[type='radio'] {
    display: none;
  }

  .radio {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
    background-color: white;
  }

  .value {
    margin-left: 10px;
    font-size: 22px;
    font-weight: normal;
  }

  input[type='radio']:checked {
    & ~ .radio {
      background-color: #000;
    }
  }
`;
