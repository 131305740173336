import styled from 'styled-components';

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 14px;
  padding-top: 0;

  svg {
    width: 70px;
    height: 70px;
  }

  p {
    text-align: center;
    line-height: 23.8px;
    padding: 0 10px;

    span {
      width: 100%;
      display: block;
      margin-top: 20px;
    }

    > b {
      font-style: italic;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.primary.default};
    text-decoration: none;
  }

  h2 {
    font-weight: 500;
  }
`;

export default ThankYouContainer;
