import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  faCheckCircle,
  faExclamationCircle,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';

import {
  PriceBoxTitle,
  PaymentAccordion,
  PackageIncludeWrapper,
  IncludeItem,
  AccordionWrapper,
} from 'components/Prices/styles';
import {
  Input,
  Error,
  FieldsFluid,
  IconAbsolute,
} from 'components/Form/common';
import { Checkbox } from 'components/Form';
import Button from 'components/Button';
import { Spinner } from 'components';
import IState from 'reducers';

import BundleSelection from './BundleSelection';

import { getDiscount, resetDiscount } from './payment.action';
import {
  SummaryWrapper,
  PaymentHeader,
  SummaryFooter,
  DiscountSection,
  DiscountTitle,
  DiscountActions,
  DiscountActionsContainer,
  OrderAmmount,
  OrderItems,
  TotalPrice,
  ScreenerDescription,
  ScreenerLearner,
  Loading,
  Appear,
} from './styles';

import { ISummaryProps } from './Payment.interfaces';

export default function Summary({
  actualLearner,
  next,
  initialPlan,
}: ISummaryProps) {
  const { discount, discountFailure } = useSelector(
    (state: IState) => state.payment
  );

  const packageIncluded = [
    'Parent Screener.',
    'Two live online learner sessions with a Learnfully Education Specialist.',
    'Detailed evaluation of skills measured against common core baseline.',
    'Detailed report of learner strengths, challenges, and learning style',
    '30-minute engagement with Education Specialist to discuss recommendations and remediation plan.',
  ];

  interface BundlePurchase {
    unitPrice: number;
    quantity: number;
    totalCost: number;
    discountAmount: number;
    productId: string;
    name: string;
  }

  const [discountCode, setDiscountCode] = useState(discount.code);
  const { screener } = useSelector((state: IState) => state.payment);
  const { info } = useSelector((state: IState) => state.user);
  const [error, setError] = useState({ discountError: false });
  const [bundlePurchase, setBundlePurchase] = useState<BundlePurchase>({
    unitPrice: 0,
    quantity: 0,
    totalCost: 0,
    discountAmount: 0,
    productId: '',
    name: '',
  });

  const [enrollmentStatus, setEnrollmentStatus] = useState<boolean>(
    info.enrollmentAgreementAccepted
  );
  const [showIncludedOptions, setIncludedOptions] = useState(false);
  const [showEnrollmentError, setShowEnrollmentError] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();

  const handleDiscountChange = (event) => {
    const { value } = event.target;
    if (!value) {
      dispatch(resetDiscount());
    }
    setError({ discountError: false });
    setDiscountCode(value.toUpperCase());
  };

  const submitCode = (e) => {
    e.preventDefault();
    setError({ discountError: false });
    dispatch(getDiscount(discountCode));
  };

  useEffect(() => {
    if (discountFailure) {
      setError({ discountError: true });
    }
  }, [discountFailure]);

  const orderAmount = initialPlan
    ? screener[0].price
    : bundlePurchase.totalCost;

  const orderDiscount = bundlePurchase.discountAmount;

  const couponDiscount =
    discount.type === 'Percentage'
      ? ((orderAmount - orderDiscount - (actualLearner.credit || 0)) *
          discount.discount) /
        100
      : discount.discount;

  const totalDiscount =
    orderDiscount + (actualLearner.credit || 0) + couponDiscount;

  const totalPrice = orderAmount - totalDiscount;

  const handleMakePayment = () => {
    if (!enrollmentStatus && !initialPlan) {
      setShowEnrollmentError(true);
    } else {
      next(
        {
          code: discount.code,
          quantity: bundlePurchase.quantity,
          total: orderAmount,
          name: bundlePurchase.name,
          totalDiscount,
          enrollmentStatus,
        },
        {
          price: bundlePurchase.unitPrice,
          productId: bundlePurchase.productId,
        }
      );
    }
  };

  return (
    <SummaryWrapper>
      {!bundlePurchase.productId && true && (
        <Loading>
          <Appear>
            <Spinner />
          </Appear>
        </Loading>
      )}
      <PaymentHeader>{initialPlan ? 'Payment' : 'Summary'}</PaymentHeader>
      {!initialPlan ? (
        <BundleSelection setBundlePurchase={setBundlePurchase} />
      ) : (
        <AccordionWrapper>
          <ScreenerDescription>
            <ScreenerLearner>
              Screener for {actualLearner.alias}
            </ScreenerLearner>
            <PriceBoxTitle size={22}>{`$${orderAmount}`}</PriceBoxTitle>
          </ScreenerDescription>
          <PaymentAccordion
            onClick={() => setIncludedOptions(!showIncludedOptions)}
          >
            Review Details
            <FontAwesomeIcon
              icon={showIncludedOptions ? faAngleUp : faAngleDown}
              className="cursor-pointer"
              style={{ fontSize: '1.5em' }}
            />
          </PaymentAccordion>
          {showIncludedOptions && (
            <PackageIncludeWrapper>
              {packageIncluded.map((item) => (
                <IncludeItem key={item} color="main">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  {item}
                </IncludeItem>
              ))}
            </PackageIncludeWrapper>
          )}
        </AccordionWrapper>
      )}

      <DiscountSection>
        <DiscountTitle>
          <b>Apply Promo Code</b>
        </DiscountTitle>
        <form onSubmit={submitCode}>
          <DiscountActionsContainer>
            <DiscountActions>
              <FieldsFluid relative>
                <Input
                  value={discountCode}
                  onChange={handleDiscountChange}
                  error={error.discountError}
                  errColor={error.discountError}
                />
                {error.discountError && (
                  <IconAbsolute errColor>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </IconAbsolute>
                )}
                {discount.discount > 0 && (
                  <IconAbsolute successColor>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </IconAbsolute>
                )}
              </FieldsFluid>
              {error.discountError && <Error>invalid code!</Error>}
            </DiscountActions>
            <div>
              <Button
                full
                bg="white"
                kind="secondary"
                type="submit"
                disabled={discountCode.length === 0}
              >
                APPLY
              </Button>
            </div>
          </DiscountActionsContainer>
        </form>
      </DiscountSection>
      <OrderAmmount>
        <OrderItems>Subtotal</OrderItems>
        <PriceBoxTitle>{`$${orderAmount.toFixed(2)}`}</PriceBoxTitle>

        {!initialPlan && (
          <>
            <OrderItems dark>Discount</OrderItems>
            <PriceBoxTitle dark>{`-$${orderDiscount.toFixed(
              2
            )}`}</PriceBoxTitle>
          </>
        )}
        {actualLearner.credit > 0 && (
          <>
            <OrderItems dark>Credit - Screener Purchase</OrderItems>
            <PriceBoxTitle dark>{`- $${actualLearner.credit}`}</PriceBoxTitle>
          </>
        )}
        {discount.discount > 0 && (
          <>
            <OrderItems medium>Discount Code</OrderItems>
            <PriceBoxTitle medium>
              {`-$${couponDiscount.toFixed(2)}`}
            </PriceBoxTitle>
          </>
        )}
      </OrderAmmount>

      <TotalPrice>
        <div>TOTAL</div>
        <PriceBoxTitle size={28}>${totalPrice.toFixed(2)}</PriceBoxTitle>
      </TotalPrice>
      {!initialPlan && !info.enrollmentAgreementAccepted && (
        <Checkbox
          type="checkbox"
          name="terms"
          checked={enrollmentStatus}
          handleChange={() => {
            setEnrollmentStatus(!enrollmentStatus);
            setShowEnrollmentError(false);
          }}
          label="I agree to the following <a href='#'><b>enrollment agreement</b></a>"
          error={
            showEnrollmentError && 'You have to agree the enrollment agreement'
          }
        />
      )}
      <SummaryFooter>
        <Button kind="primary" type="button" onClick={handleMakePayment}>
          {initialPlan ? 'Proceed' : 'Checkout'}
        </Button>
      </SummaryFooter>
    </SummaryWrapper>
  );
}
