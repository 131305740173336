import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Small } from '../Button/Button';

const Container = styled.div<{
  actual?: boolean;
  completed?: boolean;
  isDisabled?: boolean;
}>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.main.default};
  border-radius: 8px;
  padding: 20px 30px;
  margin-bottom: 10px;
  cursor: default;

  ${({ isDisabled }) => isDisabled && `opacity: 0.3;`}

  @media only screen and (max-width: ${({ theme }) =>
    theme.mediaQueries.mobile}) {
    margin-left: 15px;
  }

  ${({ completed, theme }) =>
    completed &&
    ` border-color: ${theme.colors.success.default};
      background: ${theme.colors.quaternary.light};`}

  ${({ actual, theme }) =>
    actual &&
    ` filter: drop-shadow(
        0px 0px 3px ${theme.colors.secondary.default});
      background: ${theme.colors.white.default}; `}

  ${({ actual, theme }) =>
    !actual && `background: ${theme.colors.quaternary.light}`}
`;

const CalendarsContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const StatusLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 6px 12px 4.5px 12px;
  letter-spacing: 0.5px;
  transition: all 0.25s linear;

  ${({ theme }) => Small({ theme, disabled: false, color: 'primary' })}

  &:hover {
    color: ${({ theme }) => theme.colors.white.default};
  }

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    display: block;
  }
`;

const Step = styled.div<{ completed?: boolean }>`
  position: absolute;
  left: -15px;
  top: 16%;
  height: 26px;
  width: 26px;
  border: 1px solid ${({ theme }) => theme.colors.main.default};
  border-radius: 100%;
  text-align: center;
  background-color: white;
  line-height: 28px;

  svg {
    position: relative;
    transform: translateY(16%);
    height: 20px;
    width: 20px;

    path {
      ${({ completed, theme }) =>
        completed && ` fill: ${theme.colors.success.default};`}
    }
  }
`;

const Subtitle = styled.div`
  margin: 20px 0;
  line-height: 22.4px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    font-size: 13px;
    line-height: 18.2px;
  }
`;

const CalendarGroup = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;

  svg {
    margin-top: -2px;
    path {
      ${({ disabled, theme }) =>
        disabled && `fill: ${theme.colors.main.medium}`}
    }
  }
`;

const CalendarInput = styled.div<{ disabled?: boolean }>`
  padding: 7px;
  width: 100%;
  font-size: 13px;
  ${({ disabled, theme }) => disabled && `color: ${theme.colors.main.medium}`}
`;

const BoxDisabled = styled.div`
  opacity: 0.6;
  position: absolute;
  left: -16px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  background-color: white;
  z-index: 3;
`;

export {
  StatusLink,
  Container,
  Step,
  Subtitle,
  CalendarsContainer,
  CalendarGroup,
  CalendarInput,
  BoxDisabled,
};
