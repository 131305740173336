import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../Button';
import Session from './Session';
import {
  Container,
  ContainerSessions,
  NoUpcomingSessions,
  AddSessionsContainer,
  LoadingSessions,
} from './styles';
import { ISession } from '../../commonActions';

interface IUpcomingSessions {
  addSession: () => void;
  timeLeft: number;
  sessions: ISession[];
  alias: string;
  retryLearnerSessions: boolean;
}

const UpcomingSessions = ({
  addSession,
  timeLeft,
  sessions,
  alias,
  retryLearnerSessions,
}: IUpcomingSessions) => {
  const { isMobile } = useSelector(
    (state: { devices: { isMobile: boolean } }) => state.devices
  );

  if (!sessions.length)
    return (
      <Container>
        <h3>Upcoming Sessions</h3>
        <NoUpcomingSessions>
          {`${alias} has ${timeLeft} ${timeLeft === 1 ? 'minute' : 'minutes'}`}
          <Button
            full={isMobile}
            type="button"
            kind="small"
            color="primary"
            onClick={addSession}
          >
            ADD SESSIONS
          </Button>
        </NoUpcomingSessions>
      </Container>
    );
  return (
    <Container>
      <h3>Upcoming Sessions</h3>
      <ContainerSessions>
        {retryLearnerSessions && (
          <LoadingSessions>
            <div> Loading Sessions... </div>
          </LoadingSessions>
        )}
        {sessions.map((session) => {
          return <Session key={Math.random() * 100} session={session} />;
        })}
      </ContainerSessions>
      <AddSessionsContainer>
        <Button
          full={isMobile}
          type="button"
          kind="quaternary"
          color="quaternary"
          onClick={addSession}
        >
          ADD SESSIONS
        </Button>
      </AddSessionsContainer>
    </Container>
  );
};

export default UpcomingSessions;
