import styled from 'styled-components';

export { Background, UserIcon } from '../Common';

export const Container = styled.div`
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ContainerSessions = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: auto;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    flex-wrap: nowrap;
  }
`;

export const LoadingSessions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    z-index: 1;
    color: white;
    font-size: 20px;
  }
`;

export const NoUpcomingSessions = styled.div`
  ${({ theme }) => `border: 1px ${theme.colors.quaternary.default} dashed`};
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 25px;
  }
`;

export const BackGroup = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary.default};
  svg {
    margin-right: 14px;
  }
`;

export const ContainerSchedule = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerUser = styled.div<{
  border?: boolean;
  error?: boolean;
  pointer?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  justify-content: space-between;
  ${({ pointer }) => pointer && `cursor: pointer`};
  ${({ border, theme, error }) =>
    border &&
    `border: 1px solid ${
      error ? theme.colors.alert.default : theme.colors.quaternary.default
    };
     border-radius: 10px;
     padding: 16px 20px;
    `}
  ${({ theme, error }) => error && `color: ${theme.colors.alert.default}`}
`;

export const UserImg = styled.div`
  height: 48px;
  width: 48px;
  margin-right: 8px;
  img {
    width: 100%;
    border-radius: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 40px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  b {
    width: 100%;
  }
`;

export const Subject = styled.div`
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.main.medium};
  opacity: 0.5;
`;

export const Recomendations = styled.div`
  margin-bottom: 16px;

  ul {
    padding-left: 20px;
    margin: 10px 0 0 0;
  }
  li {
    margin: 5px 0 0 0;
  }
`;

export const ButtonCalendar = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.main.default};
  background: ${({ theme }) => theme.colors.white.default};
  width: fit-content;
  border-radius: 100%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;

  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.2s linear;

  &:hover {
    background: ${({ theme }) => theme.colors.tertiary.default};
  }

  svg {
    font-size: 1em;
    transform: translate(-0.5px, -0.5px);
  }
`;

export const ContactUsLink = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  a {
    ${({ theme }) => `color: ${theme.colors.primary.default}`};
    text-decoration: none;
    margin-top: 8px;
  }
`;

export const ContainerNotification = styled.div`
  line-height: 22.4px;
  background: ${({ theme }) => theme.colors.quaternary.default};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin-bottom: 30px;

  svg {
    margin-right: 8px;
  }
`;

export const AddSessionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  button {
    width: 145px;
    margin-top: 6px;
  }
`;

export const ContainerSpecialists = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: auto;
`;

export const TimeLeftSubtitle = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

export const TimeLeftInfo = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 44px;
`;

export const TimeLeftInfoWarn = styled.div`
  margin-bottom: 44px;
`;

export const ButtonsGroup = styled.div<{ schedule?: boolean }>`
  display: flex;
  justify-content: ${({ schedule }) => (schedule ? 'space-between' : 'center')};
`;

export const CalendlyContainer = styled.div<{ isReviewSession?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    flex-direction: column;
  }
`;

export const CalendlySideDescription = styled.div<{
  isReviewSession?: boolean;
}>`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    width: 100%;
    border-right: none;
  }

  h3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
  }
`;

export const CalendlySideContainer = styled.div<{
  isReviewSession?: boolean;
}>`
  padding-left: ${({ isReviewSession }) => !isReviewSession && `0`};
  #page-region [data-container'booking-container'] {
    box-shadow: none;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.mediaQueries.mobile}) {
    width: 100%;
    padding-left: 20px;
  }
`;

export const CalendlyDescriptionTitle = styled.h1`
  font-size: 20px;
`;

export const ContactUsMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-left: -10px;
`;

export const AddMoreMinutes = styled.span`
  color: ${({ theme }) => theme.colors.primary.default};
  margin: 10px 0;
  margin-bottom: 30px;
  cursor: pointer;
`;
