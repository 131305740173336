import styled from 'styled-components';

const Expired = styled.div`
  display: flex;
  padding: 30px 10px;
  width: 100%;
`;

const User = styled.div`
  margin-bottom: 28px;

  div:first-child {
    margin-bottom: 10px;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 27px;
`;

const SmallText = styled.p`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.main.default};
  text-decoration: none;
  margin: 0 3px;
`;

export { Expired, User, SubmitContainer, Link, SmallText };
