import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { ISessionDuration } from './Dashboard.interfaces';
import { getTutorSessionsDuration } from '../../commonActions';
import IState from '../../reducers';

import {
  TimeLeftSubtitle,
  TimeLeftInfoWarn,
  ContainerSpecialists,
  ContainerUser,
  AddMoreMinutes,
} from './styles';

export default function SessionDuration({
  specialistSelected,
  next,
  addSession,
  timeLeft,
}: ISessionDuration) {
  const dispatch = useDispatch();

  const { availableSessions, noEvents, loadingEvents } = useSelector(
    (state: IState) => state.user
  );

  const [notEnoughMinutes, setNotEnoughMinutes] = useState(false);

  useEffect(() => {
    dispatch(getTutorSessionsDuration(specialistSelected.email));
  }, [dispatch, specialistSelected.email]);

  if (loadingEvents) return <div>Loading events...</div>;
  if (noEvents)
    return (
      <div>
        <b>
          {specialistSelected.firstName} {specialistSelected.lastName}
        </b>{' '}
        doesn&apos;t have available events
      </div>
    );

  const handleTimeSelection = (event) => {
    if (event.duration <= timeLeft) {
      next('calendar', event.calendarUrl);
    } else {
      setNotEnoughMinutes(true);
    }
  };

  return (
    <div>
      <TimeLeftSubtitle>Session Duration</TimeLeftSubtitle>
      <TimeLeftInfoWarn>
        <p>Select the duration of the sessions you need to schedule with</p>
        <b>
          {specialistSelected.firstName} {specialistSelected.lastName}
        </b>
      </TimeLeftInfoWarn>
      <ContainerSpecialists>
        {_.sortBy(availableSessions, [(o) => o.duration]).map(
          (event: { duration: number; calendarUrl: string }) => (
            <ContainerUser
              pointer
              key={event.calendarUrl}
              border
              error={notEnoughMinutes && event.duration > timeLeft}
              onClick={() => handleTimeSelection(event)}
            >
              {`${event.duration} minutes`}
            </ContainerUser>
          )
        )}
        {notEnoughMinutes && (
          <div>
            <p>
              Insufficient minutes left. Click{' '}
              <AddMoreMinutes onClick={addSession}>here</AddMoreMinutes> to add
              more sessions!
            </p>
          </div>
        )}
      </ContainerSpecialists>
    </div>
  );
}
