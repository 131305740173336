import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  h3 {
    text-align: center;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.main.default};
    font-weight: 500;
    margin: 30px 0;
  }
`;

export const OptionsContainer = styled.div`
  margin: 20px 30px 30px 10px;
  display: grid;
  grid-template-columns: 45px 1fr;
  & > div {
    display: grid;
    grid-auto-rows: 65px;
    grid-gap: 4px;
    justify-content: start;
    align-items: center;
  }
`;

export const CardId = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primary.default};
  color: ${({ theme }) => theme.colors.primary.default};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const StoryBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
`;

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary.default};
  border-radius: 8px;
  padding: 10px;
  width: 90%;
`;
