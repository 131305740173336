import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import SwitchUser from '../SwitchUser';

import IState from '../../reducers';
import { BackgroundStyle, Container, Main } from './styles';

export default function Background({
  children,
  signUpPage,
}: {
  children: React.ReactNode;
  signUpPage?: boolean;
}) {
  const location = useLocation();
  const { isMobile } = useSelector((state: IState) => state.devices);
  const homePath = location?.pathname !== '/myAccount';
  return (
    <BackgroundStyle isMobile={isMobile}>
      <Main signUpPage={signUpPage}>
        {!signUpPage && homePath && <SwitchUser />}
        <Container>{children}</Container>
      </Main>
    </BackgroundStyle>
  );
}
