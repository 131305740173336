import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ThankYou from '../Thankyou';
import {
  getInterestedServices,
  getGrades,
  getLocations,
  sendContactUsData,
  closeThankYouModal,
  formDataFailure,
} from './contactForm.action';
import { Modal } from '../../components';
import Button from '../../components/Button';
import {
  FieldsBox,
  FieldsFluid,
  Field,
  Select,
  Checkbox,
  Pills,
  Error,
} from '../../components/Form';
import { CloseButton, Background } from '../../components/Common';
import {
  Title,
  Subtitle,
  AdditionalLearner,
  LearnersSection,
  LearnerBox,
  LearnerHeader,
  Text,
  SubmitSection,
  SubmitButtons,
  ContainerCheckboxs,
  BoxNumber,
} from './styles';
import {
  IFormData,
  IFormDataHubspot,
  IdbItem,
  IFormValues,
  initialState,
} from './ContactForm.interfaces';
import IState from '../../reducers';
import LoadingComponent from '../../components/Loading';

export default function ContactUs() {
  const dispatch = useDispatch();

  const {
    locations,
    grades,
    interests,
    showThankYouModal,
    formResponse,
    loading,
  } = useSelector((state: IState) => state.contactUs);
  const { isMobile } = useSelector(
    (state: { devices: { isMobile: boolean } }) => state.devices
  );
  const [lastLearnerCreated, setLastLearnerCreated] = useState<number>(
    initialState.lastLearnerCreated
  );
  const [errors, setErrors] = useState<Array<string>>(initialState.errors);
  const [showError, setShowError] = useState<boolean>(initialState.showError);
  const [formValues, setFormValues] = useState<IFormValues>(
    initialState.formValues
  );
  const myRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const handleShowError = () => {
      if (formResponse.error) {
        if (formResponse.error === 'alreadyExist') {
          setErrors((prevState) => [...prevState, 'alreadyExist']);
        } else {
          setShowError(true);
          setTimeout(() => setShowError(false), 3000);
        }
        setTimeout(() => dispatch(formDataFailure('')), 3000);
        myRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };
    dispatch(getLocations());
    dispatch(getGrades());
    dispatch(getInterestedServices());
    handleShowError();
  }, [dispatch, formResponse]);

  const addAdditionalLearner = () => {
    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      learnersGroup: {
        ...prevState.learnersGroup,
        [lastLearnerCreated + 1]: {
          firstName: '',
          grade: { value: 0, label: '' },
          interested: [],
          learnerIndex: lastLearnerCreated + 1,
          other: [],
        },
      },
    }));
    setLastLearnerCreated((prevState) => prevState + 1);
  };

  const closeModal = () => {
    dispatch(closeThankYouModal());
    setErrors(initialState.errors);
    setShowError(initialState.showError);
    setFormValues(initialState.formValues);
  };

  const handleChange = (event) => {
    setErrors((prevState) => prevState.filter((e) => e !== event.target.name));

    if (event.target.name === 'email') {
      if (errors.includes('invalidEmail')) {
        setErrors((prevState) => prevState.filter((e) => e !== 'invalidEmail'));
      }
      if (errors.includes('alreadyExist')) {
        setErrors((prevState) => prevState.filter((e) => e !== 'alreadyExist'));
      }
    }

    if (event.target.name === 'phone') {
      if (errors.includes('invalidPhone')) {
        setErrors((prevState) => prevState.filter((e) => e !== 'invalidPhone'));
      }
      setFormValues((prevState) => ({
        ...prevState,
        phone: event.target.value.replace(/\D/g, ''),
      }));
    } else if (event.target.name.includes('learnersGroup')) {
      const learnerData = event.target.name.split('-');
      setFormValues((prevState: IFormValues) => ({
        ...prevState,
        learnersGroup: {
          ...prevState.learnersGroup,
          [learnerData[1]]: {
            ...prevState.learnersGroup[learnerData[1]],
            [learnerData[2]]: event.target.value,
          },
        },
      }));
    } else {
      setFormValues((prevState: IFormValues) => {
        return {
          ...prevState,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        };
      });
    }
  };

  const handleChangeStateOfResidence = (event) => {
    setErrors((prevState) => prevState.filter((e) => e !== 'stateOfResidence'));
    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      stateOfResidence: event,
    }));
  };

  const handleChangeGrade = (idx, event) => {
    setErrors((prevState) =>
      prevState.filter((e) => e !== `learnersGroup-${idx}-grade`)
    );
    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      learnersGroup: {
        ...prevState.learnersGroup,
        [idx]: {
          ...prevState.learnersGroup[idx],
          grade: event,
        },
      },
    }));
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const validatePhone = (phone) => {
    const re = /[0-9]{10,16}$/g;
    return re.test(`${phone}`);
  };

  const preSubmit = async () => {
    let count = 0;
    const learnersGroupArray = _.map(
      formValues.learnersGroup,
      (learner) => learner
    );

    if (!formValues.firstName && !errors.includes('firstName')) {
      setErrors((prevState) => [...prevState, 'firstName']);
      count += 1;
    }
    if (!formValues.lastName && !errors.includes('lastName')) {
      setErrors((prevState) => [...prevState, 'lastName']);
      count += 1;
    }
    if (
      !formValues.stateOfResidence.label &&
      !errors.includes('stateOfResidence')
    ) {
      setErrors((prevState) => [...prevState, 'stateOfResidence']);
      count += 1;
    }
    if (!formValues.email && !errors.includes('email')) {
      setErrors((prevState) => [...prevState, 'email']);
      count += 1;
    }
    if (formValues.email && !validateEmail(formValues.email)) {
      setErrors((prevState) => [...prevState, 'invalidEmail']);
      count += 1;
    }
    if (formValues.phone && !validatePhone(formValues.phone)) {
      setErrors((prevState) => [...prevState, 'invalidPhone']);
      count += 1;
    }
    if (!formValues.terms && !errors.includes('terms')) {
      setErrors((prevState) => [...prevState, 'terms']);
      count += 1;
    }
    learnersGroupArray.forEach((e) => {
      if (
        !formValues.learnersGroup[e.learnerIndex].firstName &&
        !errors.includes(`learnersGroup-${e.learnerIndex}-firstName`)
      ) {
        setErrors((prevState) => [
          ...prevState,
          `learnersGroup-${e.learnerIndex}-firstName`,
        ]);
        count += 1;
      }
      if (
        !formValues.learnersGroup[e.learnerIndex].grade.label &&
        !errors.includes(`learnersGroup-${e.learnerIndex}-grade`)
      ) {
        setErrors((prevState) => [
          ...prevState,
          `learnersGroup-${e.learnerIndex}-grade`,
        ]);
        count += 1;
      }
      if (
        !formValues.learnersGroup[e.learnerIndex].interested.length &&
        !formValues.learnersGroup[e.learnerIndex].other.length &&
        !errors.includes(`learnersGroup-${e.learnerIndex}-interested`)
      ) {
        setErrors((prevState) => [
          ...prevState,
          `learnersGroup-${e.learnerIndex}-interested`,
        ]);
        count += 1;
      }
    });
    return !!errors.length || !!count;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      stateOfResidence,
      phone,
      learnersGroup,
      accessToMandatoryTech,
      terms,
    } = formValues;

    const anyError = await preSubmit();

    if (!anyError) {
      const formData: IFormData = {
        firstName,
        lastName,
        email,
        state: stateOfResidence.value,
        learners: _.map(learnersGroup, (learner) => ({
          alias: learner.firstName,
          grade: learner.grade.value,
          interestsTags: learner.interested,
          otherInterests: learner.other,
        })),
        accessToMandatoryTech,
        termsAndConditions: terms,
      };

      const formDataHubspot: IFormDataHubspot = {
        firstName,
        lastName,
        email,
        state: stateOfResidence.label,
        learners: _.map(learnersGroup, (learner) => {
          let interestsL = learner.interested
            .map((int) => interests.filter((inte) => inte.id === int)[0].name)
            .join(', ');
          interestsL = interestsL.length ? ` - ${interestsL}` : '';
          let interestsOther = learner.other.join(', ');
          interestsOther = interestsOther.length ? ` - ${interestsOther}` : '';
          return `${learner.firstName} - ${learner.grade.label}${interestsL}${interestsOther}`;
        }).join(', '),
        accessToMandatoryTech,
        termsAndConditions: terms,
      };

      if (phone) {
        formData.phoneNumber = `+1${phone}`;
        formDataHubspot.phone = `+1${phone}`;
      }

      dispatch(sendContactUsData(formData, formDataHubspot));
    }
  };

  const interestsOptions: Array<IdbItem> = interests
    .map((e) => ({
      value: e.id,
      label: e.name,
    }))
    .sort((a, b) => a.value - b.value);

  const gradesOptions: Array<IdbItem> = grades
    .map((e) => ({
      value: e.id,
      label: e.name,
    }))
    .sort((a, b) => a.value - b.value);

  const locationsOptions: Array<IdbItem> = locations
    .map((e) => ({
      value: e.id,
      label: e.name,
    }))
    .sort((a, b) => a.value - b.value);

  const removeAdditionalLearner = (learner) => {
    setFormValues((prevState: IFormValues) => ({
      ...prevState,
      learnersGroup: _.omit(prevState.learnersGroup, [learner.learnerIndex]),
    }));
  };

  const pickServicesPill = (id, val) => {
    setErrors((prevState) =>
      prevState.filter((e) => e !== `learnersGroup-${id}-interested`)
    );
    setFormValues((prevState: IFormValues) => {
      return {
        ...prevState,
        learnersGroup: {
          ...prevState.learnersGroup,
          [id]: {
            ...prevState.learnersGroup[id],
            interested: prevState.learnersGroup[id].interested.includes(val)
              ? prevState.learnersGroup[id].interested.filter((e) => e !== val)
              : [...prevState.learnersGroup[id].interested, val],
          },
        },
      };
    });
  };

  const handleOther = (e, id) => {
    setErrors((prevState) =>
      prevState.filter((e) => e !== `learnersGroup-${id}-interested`)
    );
    setFormValues((prevState: IFormValues) => {
      const { value } = e.target;
      return {
        ...prevState,
        learnersGroup: {
          ...prevState.learnersGroup,
          [id]: {
            ...prevState.learnersGroup[id],
            other: value !== '' ? [value] : [],
          },
        },
      };
    });
  };

  const emailValidation = (errors: Array<string>) => {
    if (errors.includes('email')) {
      return 'Email is Required';
    }

    if (errors.includes('invalidEmail')) {
      return 'Invalid email';
    }

    if (errors.includes('alreadyExist')) {
      return 'Email in use! Login to your account';
    }

    return false;
  };

  const optionSelectValidate = (value, options) => {
    return options.some((v) => _.isEqual(value, v)) ? value : false;
  };

  const learnersSection = () => {
    const learnersGroupArray = _.map(
      formValues.learnersGroup,
      (learner) => learner
    );

    return learnersGroupArray.map((learner) => {
      function handleClickPill(value) {
        pickServicesPill(learner.learnerIndex, value);
      }
      return (
        <LearnersSection key={learner.learnerIndex}>
          <LearnerBox>
            <LearnerHeader>
              {Object.keys(formValues.learnersGroup).length > 1 && (
                <CloseButton onClick={() => removeAdditionalLearner(learner)} />
              )}
            </LearnerHeader>
            <FieldsBox>
              <Field
                label="Name/Nickname*"
                name={`learnersGroup-${learner.learnerIndex}-firstName`}
                type="text"
                handleChange={handleChange}
                value={formValues.learnersGroup[learner.learnerIndex].firstName}
                error={
                  errors.includes(
                    `learnersGroup-${learner.learnerIndex}-firstName`
                  ) && 'First Name is Required'
                }
              />
              <Select
                label="Current Grade*"
                options={gradesOptions}
                name="gradesOptions"
                value={optionSelectValidate(
                  formValues.learnersGroup[learner.learnerIndex].grade,
                  gradesOptions
                )}
                placeholder="Select"
                handleChange={(e) => handleChangeGrade(learner.learnerIndex, e)}
                error={
                  errors.includes(
                    `learnersGroup-${learner.learnerIndex}-grade`
                  ) && 'Please select a grade'
                }
              />
            </FieldsBox>
            <FieldsFluid>
              <Text>
                <b>I’m interested in the following services:</b>
              </Text>
              {!!interestsOptions.length && (
                <>
                  <Pills
                    options={interestsOptions}
                    selections={learner?.interested}
                    handleClick={handleClickPill}
                    error={
                      errors.includes(
                        `learnersGroup-${learner.learnerIndex}-interested`
                      ) && `Please select o write a service`
                    }
                  />
                </>
              )}
            </FieldsFluid>
            <Field
              label="Other"
              name={`learnersGroup-${learner.learnerIndex}-other`}
              type="text"
              handleChange={(e) => {
                handleOther(e, learner.learnerIndex);
              }}
              value={
                formValues.learnersGroup[learner.learnerIndex].other[0] || ''
              }
              error={
                errors.includes(
                  `learnersGroup-${learner.learnerIndex}-interested`
                ) && 'Please select o write a service'
              }
            />
          </LearnerBox>
        </LearnersSection>
      );
    });
  };

  return (
    <Background signUpPage>
      <Title>Get Started</Title>
      <Subtitle ref={myRef}>Tell us about yourself:</Subtitle>
      <form onSubmit={handleSubmit}>
        {showError && (
          <Error center>
            There is an error in one of the fields, please check it and try
            again.
          </Error>
        )}
        <FieldsBox>
          <Field
            label="First Name*"
            name="firstName"
            type="text"
            handleChange={handleChange}
            value={formValues.firstName}
            error={errors.includes('firstName') && 'First Name is Required'}
          />
          <Field
            label="Last Name*"
            name="lastName"
            type="text"
            handleChange={handleChange}
            value={formValues.lastName}
            error={errors.includes('lastName') && 'Last Name is Required'}
          />
        </FieldsBox>
        <FieldsBox>
          <Field
            label="Email Address*"
            name="email"
            type="text"
            handleChange={handleChange}
            value={formValues.email}
            error={emailValidation(errors)}
          />
          <BoxNumber>
            <Field
              label={`Mobile Number <span class="text-muted">(Optional)</span>`}
              name="phone"
              type="text"
              handleChange={handleChange}
              value={formValues.phone}
              error={errors.includes('invalidPhone') && 'Invalid phone number'}
            />
          </BoxNumber>
        </FieldsBox>
        <FieldsBox>
          <Select
            label="State of residence*"
            options={locationsOptions}
            name="stateOfResidence"
            value={optionSelectValidate(
              formValues.stateOfResidence,
              locationsOptions
            )}
            placeholder="Select"
            handleChange={handleChangeStateOfResidence}
            error={
              errors.includes('stateOfResidence') && 'Please select a state'
            }
            required
          />
        </FieldsBox>
        <Subtitle>Tell us about your child:</Subtitle>

        {learnersSection()}
        {Object.keys(formValues.learnersGroup).length < 4 && (
          <AdditionalLearner onClick={addAdditionalLearner}>
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            <span>
              <b>Add additional learner</b>
            </span>
          </AdditionalLearner>
        )}
        <ContainerCheckboxs>
          <Checkbox
            type="checkbox"
            name="accessToMandatoryTech"
            checked={formValues.accessToMandatoryTech}
            handleChange={handleChange}
            label="My family has easy access to Laptop/Computer, a smartphone (Android
            or iOS), and a reliable home internet connection."
          />
          <Checkbox
            type="checkbox"
            name="terms"
            checked={formValues.terms}
            handleChange={handleChange}
            label="I agree to the following <a href='https://learnfully.com/terms-and-conditions/' target='blank'><b>terms of use</b></a>"
            error={errors.includes('terms') && 'You have to agree terms of use'}
          />
        </ContainerCheckboxs>
        <SubmitSection>
          <SubmitButtons>
            <Button type="submit">
              {loading ? <LoadingComponent /> : 'Submit'}
            </Button>
          </SubmitButtons>
        </SubmitSection>
      </form>

      <Modal
        mw={600}
        isOpen={showThankYouModal}
        full={isMobile}
        closeModal={() => {
          closeModal();
        }}
      >
        <ThankYou />
      </Modal>
    </Background>
  );
}
