import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { ISession } from '../../commonActions';

const ContainerSchedule = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
  width: 142px;
  min-width: 142px;
  margin-right: 10px;
  margin-bottom: 10px;

  p {
    margin: 0 0 20px 0px;
    font-weight: bold;
  }
`;

const ContainerUser = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const UserInfo = styled.div`
  color: ${({ theme }) => theme.colors.secondary.default};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const SessionDate = styled.div`
  font-size: 13px;
  margin-bottom: 5px;

  svg {
    margin: 0 3px;
  }
`;

const TutorInfo = styled.div`
  color: ${({ theme }) => theme.colors.main.medium};
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
`;

const Sessions = ({
  session: { sessionName, startDate, endDate, tutor },
}: {
  session: ISession;
}) => (
  <ContainerSchedule>
    <ContainerUser>
      <UserInfo>{sessionName}</UserInfo>
      <TutorInfo>
        {tutor
          ? `with ${tutor.firstName} ${tutor.lastName}`
          : 'with Learnfully'}
      </TutorInfo>
    </ContainerUser>
    <SessionDate>
      <FontAwesomeIcon icon={faCalendarAlt} />
      {moment(startDate).format('ddd, MMM DD')}
    </SessionDate>
    <SessionDate>
      <FontAwesomeIcon icon={faClock} />
      {moment.utc(startDate).local().format('hh:mmA')} -{' '}
      {moment.utc(endDate).local().format('hh:mmA')}
    </SessionDate>
  </ContainerSchedule>
);

export default Sessions;
