import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import { makeObjectUser } from '../../utils';
import { cleanUser, setUser } from '../../commonActions';

export const SEND_LOGIN = 'SEND_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SET_SELECTED_LEARNER_INDEX = 'SET_SELECTED_LEARNER_INDEX';

export const ONBOARDING_DONE = 'ONBOARDING_DONE';
export const GET_USER_INFO = 'GET_USER_INFO';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

const FormLoginSend = () => ({
  type: SEND_LOGIN,
});

const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = (err) => ({
  type: LOGIN_FAILURE,
  err,
});

const onboardingDoneSuccess = () => ({
  type: ONBOARDING_DONE,
});

const getUserInfo = () => ({
  type: GET_USER_INFO,
});

export const getUserSettings = () => {
  return (dispatch) => {
    dispatch(getUserInfo());
    const userID = localStorage.getItem('userID');
    const token = localStorage.getItem('token');
    axios
      .get(`${HOST_PROFILE}user/${userID}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(cleanUser());
        dispatch(setUser(makeObjectUser(res.data)));
        dispatch(loginSuccess());
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('getUser Error', err);
        dispatch(loginFailure('Something went wrong'));
      });
  };
};

export const onboardingDone = () => {
  return (dispatch) => {
    const user = { onboardingDone: true };
    localStorage.setItem('currentUser', JSON.stringify(user));
    dispatch(onboardingDoneSuccess());
  };
};

export const sendLoginData = (data) => {
  return (dispatch) => {
    dispatch(FormLoginSend());

    const authenticationData = {
      Username: data.user,
      Password: data.password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const poolData = {
      UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
      ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: data.user,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        const idToken = result.getIdToken();
        const refreshToken = result.getRefreshToken();
        const id = idToken.payload['custom:learnfully_user_id'];
        localStorage.removeItem('token');
        localStorage.setItem('token', idToken.getJwtToken());
        localStorage.setItem('userID', id);
        localStorage.setItem('refreshToken', refreshToken.getToken());
        dispatch(getUserSettings());
      },

      onFailure(err) {
        dispatch(loginFailure(err.message));
      },
    });
  };
};
