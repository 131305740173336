import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Learners from '../Learners';

import familyValuesChild from '../../../assets/icons/FamilyValuesChild.svg';
import SettingsLink from '../SettingsLink/SettingsLink';
import { Title, TopContent, BottomContent } from './styles';
import { clearData } from '../../ResetPassword/resetPassword.actions';
import IState from '../../../reducers';
import { IMyAccountWrapperProps } from './MyAccountWrapper.interfaces';

const familyValuesChildIcon = familyValuesChild;

const links = [
  { screen: 'paymentMethod', label: 'Payment method' },
  { screen: 'resetPassword', label: 'Reset password' },
];

export default function MyAccountWrapper({ title }: IMyAccountWrapperProps) {
  const dispatch = useDispatch();

  const accountInfo = useSelector((state: IState) => state.user);
  const name = `${accountInfo?.info?.firstName} ${accountInfo?.info?.lastName}`;
  const email = accountInfo?.info?.email;
  const learners = [...accountInfo?.learners].sort((a, b) =>
    a.alias.localeCompare(b.alias)
  );

  useEffect(() => {
    dispatch(clearData());
  }, [dispatch]);

  return (
    <>
      <Title>
        <b>{title}</b>
      </Title>
      <TopContent>
        <p>{name}</p>
        <span>{email}</span>
      </TopContent>
      <Learners
        title="Learners"
        image={familyValuesChildIcon}
        learners={learners}
      />
      <SettingsLink title="Account settings" links={links} />
      <BottomContent>
        <p>For questions please reach us at</p>
        <span>contact@learnfully.com</span>
      </BottomContent>
    </>
  );
}
