import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';
import { Field } from '../Form';
import Button from '../Button';
import {
  Title,
  SubmitContainer,
  FormContainer,
  ContainerPassword,
  SuccessContainer,
  Error,
} from './styles';
import { validatePassword } from '../../helpers/validatePassword';
import { sendPasswordData, clearData } from './resetPassword.actions';
import { IResetPasswordProps } from './ResetPassword.interfaces';
import IState from '../../reducers';

export default function ResetPassword({
  title,
  closeModal,
}: IResetPasswordProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearData());
  }, [dispatch]);

  const [data, setData] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const resetPasswordState = useSelector(
    (state: IState) => state.resetPassword
  );

  const [passErr, setPassErr] = useState<boolean>(false);
  const [newPassRegExpErr, setNewPassRegExpErr] = useState<boolean>(false);
  const [confirmPassErr, setConfirmPassErr] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>('password');

  const handleValidatePassword = () => {
    if (!validatePassword(data.password)) {
      setPassErr(true);
    }
  };

  const handleValidateConfirmPasswordRegExp = () => {
    if (!validatePassword(data.newPassword)) {
      setNewPassRegExpErr(true);
    }
  };

  const handleValidateConfirmPassword = () => {
    setConfirmPassErr(data.newPassword !== data.confirmNewPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleValidatePassword();
    handleValidateConfirmPasswordRegExp();
    handleValidateConfirmPassword();
    const { password, confirmNewPassword } = data;
    if (
      password &&
      confirmNewPassword &&
      !passErr &&
      !confirmPassErr &&
      !newPassRegExpErr
    ) {
      const resetData = {
        password,
        newPassword: confirmNewPassword,
      };
      dispatch(sendPasswordData(resetData));
    }
  };

  const handleChange = (event) => {
    if (validatePassword(event.target.value)) {
      setPassErr(false);
    }
    if (validatePassword(event.target.value)) {
      setNewPassRegExpErr(false);
    }
    if (event.target.name === 'confirmNewPassword') {
      setConfirmPassErr(data.newPassword !== event.target.value);
    }
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleVisible = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };
  return (
    <div>
      {resetPasswordState.success ? (
        <SuccessContainer>
          <Check />
          <Title>Password Updated</Title>
          <div>You have successfully updated your password.</div>
          <Button type="button" kind="primary" onClick={closeModal}>
            Back to My account
          </Button>
        </SuccessContainer>
      ) : (
        <>
          <Title>{title}</Title>
          <FormContainer>
            <form onSubmit={handleSubmit}>
              <ContainerPassword>
                <Field
                  label="Current Password"
                  name="password"
                  type={inputType}
                  autoComplete="off"
                  handleChange={handleChange}
                  onBlur={handleValidatePassword}
                  value={data.password}
                  error={
                    passErr &&
                    `Minimum eight (8) characters in length. Contain at least one (1)
              number, one (1) uppercase letter and special character (~\`! ...)`
                  }
                  full
                />
                <FontAwesomeIcon
                  icon={inputType === 'text' ? faEye : faEyeSlash}
                  onClick={handleVisible}
                />
              </ContainerPassword>

              <ContainerPassword>
                <Field
                  label="New Password"
                  name="newPassword"
                  type={inputType}
                  autoComplete="off"
                  handleChange={handleChange}
                  onBlur={handleValidateConfirmPasswordRegExp}
                  value={data.newPassword}
                  error={
                    newPassRegExpErr &&
                    `Minimum eight (8) characters in length. Contain at least one (1)
              number, one (1) uppercase letter and special character (~\`! ...)`
                  }
                  full
                />
                <FontAwesomeIcon
                  icon={inputType === 'text' ? faEye : faEyeSlash}
                  onClick={handleVisible}
                />
              </ContainerPassword>

              <ContainerPassword>
                <Field
                  label="Confirm New Password"
                  name="confirmNewPassword"
                  type={inputType}
                  autoComplete="off"
                  handleChange={handleChange}
                  value={data.confirmNewPassword}
                  error={confirmPassErr && `Passwords do not match!`}
                  full
                />
                <FontAwesomeIcon
                  icon={inputType === 'text' ? faEye : faEyeSlash}
                  onClick={handleVisible}
                />
              </ContainerPassword>
              <SubmitContainer>
                <Button type="submit">
                  {resetPasswordState.loading
                    ? 'Loading...'
                    : 'Update Password'}
                </Button>
                {resetPasswordState.resetFailure && (
                  <Error>{resetPasswordState.resetFailure}</Error>
                )}
              </SubmitContainer>
            </form>
          </FormContainer>
        </>
      )}
    </div>
  );
}
