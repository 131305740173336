import styled from 'styled-components';
import LoadingGif from '../../assets/icons/loading.gif';
// import { StyleProps, IButton } from './Button.interfaces';

export const LoadingComponent = styled.div`
  background: url(${LoadingGif});
  height: 40px;
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
`;

export default LoadingComponent;
