import React from 'react';
import { ContentWrapper, MainContent, InputWrapper, StoryBox } from './styles';
import { ISimpleQuestionProps } from './SimpleQuestion.interfaces';

export default function SimpleQuestion(props: ISimpleQuestionProps) {
  const { title, question } = props;
  return (
    <ContentWrapper>
      <h3>{title}</h3>
      <StoryBox>{question}</StoryBox>
      <MainContent>
        <InputWrapper>
          <input type="text" />
        </InputWrapper>
      </MainContent>
    </ContentWrapper>
  );
}
