import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Error } from '../../components/Form';
import {
  Main,
  FormContainer,
  ContainerPassword,
  SubmitContainer,
  Background,
  Container,
  ContainerSuccess,
  TextSuccess,
} from './styles';
import Button from '../../components/Button';
import IState from '../../reducers';
import { recoveryPassword } from './recovery.action';
import { validatePassword } from '../../helpers/validatePassword';

export default function RecoveryPassword() {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const emailParam = urlParams.get('email')?.replace(/\s/g, '+');
  const email = emailParam?.endsWith('/')
    ? emailParam.slice(0, -1)
    : urlParams.get('email')?.replace(/\s/g, '+');
  const dispatch = useDispatch();
  const resetInfo = useSelector((state: IState) => state.recoveryPassword);
  const [data, setData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [inputType, setInputType] = useState<string>('password');
  const [passErr, setPassErr] = useState<boolean>(false);
  const [confirmPassErr, setConfirmPassErr] = useState<boolean>(false);

  const handleValidatePassword = () => {
    if (!validatePassword(data.password)) {
      setPassErr(true);
    }
  };

  const handleValidateConfirmPassword = () => {
    setConfirmPassErr(data.password !== data.confirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleValidatePassword();
    handleValidateConfirmPassword();
    const { password, confirmPassword } = data;
    if (
      password &&
      confirmPassword &&
      !passErr &&
      !confirmPassErr &&
      code &&
      email
    ) {
      const recoveryData = {
        email,
        code,
        newPassword: confirmPassword,
      };
      dispatch(recoveryPassword(recoveryData));
    }
  };

  const handleChange = (event) => {
    if (validatePassword(event.target.value)) {
      setPassErr(false);
    }
    if (event.target.name === 'confirmPassword') {
      setConfirmPassErr(data.password !== event.target.value);
    }
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleVisible = () =>
    setInputType(inputType === 'password' ? 'text' : 'password');

  return (
    <Main className="container-size">
      <Background />
      {resetInfo.success ? (
        <ContainerSuccess>
          <h1>Reset password</h1>
          <p>Successfully updated</p>
          <FormContainer>
            <TextSuccess>
              Your password has been successfully updated, you can now use it to
              login.
            </TextSuccess>
            <SubmitContainer>
              <Link to="signIn">
                <Button type="button">Login</Button>
              </Link>
            </SubmitContainer>
          </FormContainer>
        </ContainerSuccess>
      ) : (
        <Container>
          <h1>Reset password</h1>
          <p>Please enter a new password</p>
          <FormContainer>
            <form onSubmit={handleSubmit}>
              <ContainerPassword>
                <Field
                  label="New password"
                  name="password"
                  type={inputType}
                  autoComplete="off"
                  handleChange={handleChange}
                  onBlur={handleValidatePassword}
                  value={data.password}
                  error={
                    passErr &&
                    `Minimum eight (8) characters in length. Contain at least one (1)
              number, one (1) uppercase letter and special character (~\`! ...)`
                  }
                  full
                />

                <FontAwesomeIcon
                  icon={inputType === 'text' ? faEye : faEyeSlash}
                  onClick={handleVisible}
                />
              </ContainerPassword>

              <ContainerPassword>
                <Field
                  label="Confirm password"
                  name="confirmPassword"
                  type={inputType}
                  autoComplete="off"
                  handleChange={handleChange}
                  value={data.confirmPassword}
                  error={confirmPassErr && `Passwords do not match!`}
                  full
                />

                <FontAwesomeIcon
                  icon={inputType === 'text' ? faEye : faEyeSlash}
                  onClick={handleVisible}
                />
              </ContainerPassword>

              <SubmitContainer>
                <Button type="submit">
                  {resetInfo.loading ? 'Loading...' : 'Reset Password'}
                </Button>
                {resetInfo.failure && <Error>{resetInfo.failure}</Error>}
              </SubmitContainer>
            </form>
          </FormContainer>
        </Container>
      )}
    </Main>
  );
}
