export default {
  parentFeedback: {
    title: 'Parent Feedback',
    subtitle: 'Help us understand the learner’s goals & challenges.',
    type: 'progress',
    completedName: 'parentScreenerStatus',
    url: '/parentScreener?learnerId=',
    buttonText: {
      pending: 'GET STARTED!',
      inProgress: 'CONTINUE',
      actionRequired: 'CONTINUE',
    },
  },
  programSelection: {
    title: 'Screener setup',
    subtitle:
      'Purchase a screener to help us recommend a personalized program for your learner',
    type: 'pay',
    completedName: 'initialPlanPayed',
    url: 'none',
    callback: 'setShowPayment',
    buttonText: {
      pending: 'VIEW',
      inProgress: 'VIEW',
      actionRequired: 'VIEW',
    },
  },
  screenerSession: {
    title: 'Sessions',
    subtitle:
      'Schedule your screener sessions to discover your learner’s profile.',
    type: 'schedule',
    completedName: 'learnerScreenerStatus',
    url: 'none',
    buttonText: {
      pending: 'SCHEDULE',
      inProgress: 'VIEW DETAILS',
      actionRequired: 'VIEW DETAILS',
    },
  },
  reviewProgram: {
    title: 'Review program',
    subtitle:
      'Meet a specialist to go over the learner’s profile and recommendations.',
    type: 'review',
    completedName: 'screenerReviewStatus',
    url: 'none',
    buttonText: {
      pending: 'SCHEDULE',
      inProgress: 'SCHEDULE',
      actionRequired: 'SCHEDULE',
    },
  },
};
