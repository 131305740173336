import styled from 'styled-components';
import BackgroundImage from '../../assets/images/background-sign.jpg';
import BackgroundImageMobile from '../../assets/images/background-sign-mobile.jpg';

const Main = styled.div<{ bottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    justify-content: ${({ bottom }) => (bottom ? 'flex-end' : 'center')};
  }

  h1 {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50vh;
  background: ${({ theme }) => theme.colors.quaternary.default};
  background: url(${BackgroundImage});
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    background: url(${BackgroundImageMobile});
    background-position-x: center;
    background-size: cover;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 100%;
  }

  @media (min-width: 426px) {
    transform: translateY(35%);
  }

  & > p {
    color: ${({ theme }) => theme.colors.white.default};
  }
`;

export const ContainerSuccess = styled(Container)`
  > div {
    padding: 100px 50px;
  }
`;

export const TextSuccess = styled(Container)`
  color: ${({ theme }) => theme.colors.main.dark};
  text-align: center;
  margin-bottom: 40px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  border-radius: 8px;
  padding: 20px;
  margin-top: 40px;
  width: 479px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white.default};

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    width: 100%;
  }
`;

const ContainerPassword = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 46px;
    right: 28px;
    z-index: 4;
    cursor: pointer;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 27px;
`;

export {
  Main,
  FormContainer,
  ContainerPassword,
  SubmitContainer,
  Background,
  Container,
};
