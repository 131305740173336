import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faExclamationCircle,
  faPlusCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';

import IState, { IBundleItem } from 'reducers';

import {
  BundleSection,
  BundleSectionTitles,
  BundleSectionContent,
} from 'components/Prices/styles';

import { getBundles } from './payment.action';

import {
  SummaryGetDiscount,
  QuantityContainer,
  QuantityIcon,
  QuantityAmmount,
} from './styles';

interface BundlePurchase {
  unitPrice: number;
  quantity: number;
  totalCost: number;
  discountAmount: number;
  productId: string;
  name: string;
}

interface Props {
  setBundlePurchase: (bundlePurchase: BundlePurchase) => void;
}

export default function BundleSelection({ setBundlePurchase }: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBundles());
  }, [dispatch]);

  const { bundles } = useSelector((state: IState) => state.payment);
  const [bundleQuantity, setBundleQuantity] = useState<number>(4);
  const [bundleDiscount, setBundleDiscount] = useState<number>(0);
  const [selectedBundle, selectBundle] = useState<IBundleItem>(
    {} as IBundleItem
  );

  useEffect(() => {
    selectBundle(bundles[0]);
  }, [bundles]);

  useEffect(() => {
    if (isEmpty(selectedBundle)) return;
    const purchaseBundle = {
      unitPrice: selectedBundle.price,
      quantity: bundleQuantity,
      totalCost: selectedBundle.price * bundleQuantity,
      discountAmount:
        selectedBundle.price * bundleQuantity * (bundleDiscount / 100),
      productId: selectedBundle.productId,
      name: selectedBundle.name,
    };
    setBundlePurchase(purchaseBundle);
  }, [selectedBundle, setBundlePurchase, bundleQuantity, bundleDiscount]);

  useEffect(() => {
    if (isEmpty(selectedBundle)) return;
    const { discounts } = selectedBundle;
    let bundleDiscount = 0;
    discounts.forEach((d) => {
      if (bundleQuantity >= d.min && bundleQuantity <= d.max)
        bundleDiscount = d.discount;
    });
    setBundleDiscount(bundleDiscount);
  }, [bundleQuantity, setBundleDiscount, selectedBundle]);

  const handleBundles = (add: boolean) => {
    add
      ? bundleQuantity < 30 && setBundleQuantity(bundleQuantity + 1)
      : bundleQuantity > 4 && setBundleQuantity(bundleQuantity - 1);
  };

  const isMin = bundleQuantity === 4;
  const isMax = bundleQuantity === 30;

  const quantityActions = () => (
    <QuantityContainer>
      <QuantityIcon
        onClick={() => handleBundles(false)}
        style={{ opacity: isMin ? 0.5 : 1 }}
      >
        <FontAwesomeIcon size="lg" icon={faMinusCircle} />
      </QuantityIcon>
      <QuantityAmmount>{bundleQuantity}</QuantityAmmount>
      <QuantityIcon
        onClick={() => handleBundles(true)}
        style={{ opacity: isMax ? 0.5 : 1 }}
      >
        <FontAwesomeIcon size="lg" icon={faPlusCircle} />
      </QuantityIcon>
    </QuantityContainer>
  );

  const labels = {
    'Learner Sessions': '1 hour',
  };
  const label = labels[selectedBundle.name];
  return (
    <>
      <SummaryGetDiscount>
        <FontAwesomeIcon icon={faExclamationCircle} />
        Get up to 20% off when you purchase more sessions!
      </SummaryGetDiscount>
      <BundleSection>
        <BundleSectionTitles first>{selectedBundle.name}</BundleSectionTitles>
        <BundleSectionTitles center>Quantity</BundleSectionTitles>
        <BundleSectionTitles last>Discount</BundleSectionTitles>
        <BundleSectionContent>
          <b>{label}</b>
        </BundleSectionContent>
        <BundleSectionContent>{quantityActions()}</BundleSectionContent>
        <BundleSectionContent center style={{ userSelect: 'none' }}>
          {bundleDiscount === 0 ? '-' : `${bundleDiscount}%`}
        </BundleSectionContent>
      </BundleSection>
    </>
  );
}
