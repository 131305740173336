import {
  GET_SCREENER_BUNDLE_SEND,
  GET_SCREENER_BUNDLE_SUCCESS,
  GET_SCREENER_BUNDLE_FAILURE,
  GET_BUNDLES_SEND,
  GET_BUNDLES_SUCCESS,
  GET_BUNDLES_FAILURE,
  BUNDLES_PURCHASED,
  GET_DISCOUNT_FAILURE,
  GET_DISCOUNT_SEND,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_RESET,
  GET_BT_TOKEN_FAILURE,
  GET_BT_TOKEN_SUCCESS,
  SEND_PURCHASE_FAILURE,
  RESET_PAYMENT_OPTIONS,
  RESET_PAYMENT_FAILURE_MESSAGE,
  RESET_BT_TOKEN,
} from '../pages/Payment/payment.action';

const initialState = {
  screener: [],
  bundles: [
    {
      productId: '',
      name: '',
      description: '',
      price: 0,
      sessions: 4,
      discounts: [
        {
          discount: 0,
          min: 0,
          max: 0,
          description: '',
          discountType: '',
        },
      ],
    },
  ],
  bundlesPurchased: false,
  discount: {
    discount: 0,
    code: '',
    type: '',
  },
  loading: false,
  loadingDiscount: false,
  screenerBundleFailure: '',
  btToken: '',
  purchaseFailure: { status: false },
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SCREENER_BUNDLE_SEND:
      return {
        ...state,
        loading: true,
      };
    case GET_SCREENER_BUNDLE_SUCCESS:
      return {
        ...state,
        screener: action.screener,
        loading: false,
      };
    case GET_SCREENER_BUNDLE_FAILURE:
      return {
        ...state,
        bundlesFailure: action.err,
        loading: false,
      };
    case GET_BUNDLES_SEND:
      return {
        ...state,
        loading: true,
      };
    case GET_BUNDLES_SUCCESS:
      return {
        ...state,
        bundles: action.bundles,
        loading: false,
      };
    case GET_BUNDLES_FAILURE:
      return {
        ...state,
        bundlesFailure: action.err,
        loading: false,
      };
    case BUNDLES_PURCHASED:
      return {
        ...state,
        bundlesPurchased: true,
      };
    case GET_DISCOUNT_SEND:
      return {
        ...state,
        loadingDiscount: true,
        discountFailure: false,
      };
    case GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: action.discount,
        loadingDiscount: false,
      };
    case GET_DISCOUNT_RESET:
      return {
        ...state,
        discount: initialState.discount,
        loadingDiscount: false,
      };

    case GET_DISCOUNT_FAILURE:
      return {
        ...state,
        loadingDiscount: false,
        discount: initialState.discount,
        discountFailure: true,
      };

    case GET_BT_TOKEN_SUCCESS:
      return {
        ...state,
        btToken: action.btToken,
        btTokenFailure: '',
      };
    case GET_BT_TOKEN_FAILURE:
      return {
        ...state,
        btTokenFailure: action.err,
      };
    case RESET_BT_TOKEN:
      return {
        ...state,
        btToken: null,
        btTokenFailure: '',
      };
    case SEND_PURCHASE_FAILURE:
      return {
        ...state,
        purchaseFailure: {
          status: true,
        },
      };
    case RESET_PAYMENT_FAILURE_MESSAGE:
      return {
        ...state,
        purchaseFailure: {
          status: false,
        },
        discount: initialState.discount,
      };
    case RESET_PAYMENT_OPTIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
