import React from 'react';
import { IPropsPills } from './Form.interfaces';
import { PillsBox, Pill } from './styles';
import { Error } from './common';

export default function Pills({
  options,
  selections,
  error,
  handleClick,
}: IPropsPills) {
  return (
    <PillsBox>
      <div>
        {options.length > 0 &&
          options.map((pill) => {
            return (
              <Pill
                key={pill.value}
                selected={selections && selections.includes(pill.value)}
                error={!!error}
                onClick={() => handleClick(pill.value)}
              >
                {pill.label}
              </Pill>
            );
          })}
      </div>
      {error && <Error>{error}</Error>}
    </PillsBox>
  );
}
