import React from 'react';
import styled, { DefaultTheme, keyframes } from 'styled-components';

export interface StyleProps {
  theme: DefaultTheme;
  color?: 'primary' | 'secondary' | 'quaternary';
}

const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 100%;
  transform: scale(0.5);
  display: flex;
  justify-content: center;
`;

const rotate = keyframes`
  0% { transform: rotate(0); }
  10% { transform: rotate(30deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerContainer = styled.div<StyleProps>`
  width: 5rem;
  height: 5rem;
  ${({ theme }) => `color: ${theme.colors.main.default}`};
  display: flex;
  justify-content: center;
  align-items: center;
  // ${({ theme }) => `border:  1px solid ${theme.colors.main.default}`};
  animation: ${rotate} 10s 2s infinite linear;

  &:after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    ${({ theme }) => `background-color:  ${theme.colors.main.default}`};
  }
`;

const shrink = keyframes`
  0% { transform: scale(1); }
  35% { transform: scale(.7); }
  75% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  // border: 2px solid hotpink;
  position: absolute;
  width: 1rem;
  height: 1rem;

  div {
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    bottom: 1.3rem;
    ${({ theme }) => `background: ${theme.colors.main.default}`};
    transform-origin: bottom;
    animation: ${shrink} 5s infinite;

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      transform: translateY(-11px);
      border-radius: 50%;
      ${({ theme }) => `background-color:  ${theme.colors.main.default}`};
    }
  }

  &:first-child {
    div {
      height: 3.7rem;
      width: 8px;
      animation-duration: 5s;
      animation-delay: 1.5s;
    }
  }

  &:nth-child(2) {
    transform: rotate(90deg);
    div {
      height: 2.6rem;
      width: 8px;
      animation-duration: 4s;
      animation-delay: 2.4s;
    }
  }

  &:nth-child(3) {
    transform: rotate(142deg);
    div {
      height: 1.7rem;
      width: 4px;
      animation-duration: 3.2s;
      animation-delay: 0.4s;
    }
  }

  &:nth-child(4) {
    transform: rotate(165deg);
    div {
      height: 2.4rem;
      width: 4px;
      animation-duration: 2.6s;
      animation-delay: 0.2s;
    }
  }

  &:nth-child(5) {
    transform: rotate(195deg);
    div {
      height: 3rem;
      width: 4px;
      animation-duration: 3.6s;
      animation-delay: 0.4s;
    }
  }

  &:nth-child(6) {
    transform: rotate(247deg);
    div {
      height: 2.8rem;
      width: 4px;
      animation-duration: 3.4s;
      animation-delay: 0.3s;
    }
  }

  &:nth-child(7) {
    transform: rotate(281deg);
    div {
      height: 2.3rem;
      width: 4px;
    }
  }
`;

export default function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerContainer>
        <Section>
          <div />
        </Section>
        <Section>
          <div />
        </Section>
        <Section>
          <div />
        </Section>
        <Section>
          <div />
        </Section>
        <Section>
          <div />
        </Section>
        <Section>
          <div />
        </Section>
        <Section>
          <div />
        </Section>
      </SpinnerContainer>
    </SpinnerWrapper>
  );
}
