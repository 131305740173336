import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDevices } from '../commonActions';

export default function useDevices() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const devices = {
        isMobile: false,
        isTablet: false,
        isDesktop: false,
      };

      // Set window width/height to state
      if (window?.matchMedia('(max-width:425px)')?.matches) {
        devices.isMobile = true;
      }
      if (
        window?.matchMedia('(min-width:426px) and (max-width: 992px)')?.matches
      ) {
        devices.isTablet = true;
      }
      if (window?.matchMedia('(min-width:993px)')?.matches) {
        devices.isDesktop = true;
      }

      dispatch(setDevices(devices));
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);
}
