import React from 'react';
import {
  ContentWrapper,
  MainContent,
  TopContent,
  BottomContent,
  InputWrapper,
  ImageElement,
} from './styles';
import { IFillBlankQuestionProps } from './FillBlankQuestion.interfaces';

export default function FillBlankQuestion(props: IFillBlankQuestionProps) {
  const { title, image, beginin, end } = props;
  return (
    <ContentWrapper>
      <h3>{title}</h3>
      <MainContent>
        <TopContent>
          <ImageElement image={image} />
        </TopContent>
        <BottomContent>
          <p>{beginin}</p>
          <InputWrapper>
            <input type="text" />
          </InputWrapper>
          <p>{end}</p>
        </BottomContent>
      </MainContent>
    </ContentWrapper>
  );
}
