import React from 'react';
import MyAccountWrapper from '../../components/account/MyAccountWrapper/MyAccountWrapper';
import { Background } from '../../components/Common';

export default function MyAccount() {
  return (
    <Background>
      <MyAccountWrapper title="My account" />
    </Background>
  );
}
