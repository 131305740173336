import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  h3 {
    text-align: center;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.main.default};
    font-weight: 500;
    margin: 30px 0;
  }
`;

export const StoryBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  font-style: italic;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
  margin: 0 60px;
  @media only screen and (max-width: 600px) {
    margin: 0;
  }
`;

export const ImageBox = styled.div`
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
  margin: 15px auto;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const ContentWrapperAnswer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 45px;
  h3 {
    text-align: center;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.main.default};
    font-weight: 500;
    margin: 30px 0;
  }
`;

export const StoryBoxAnswer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
  width: 100%;
  p {
    display: block;
    font-size: 16px;
    margin: 0 0 10px 0;
  }
  input {
    background-color: ${({ theme }) => theme.colors.tertiary.default};
    width: 100%;
    border: none;
    height: 54px;
    border-radius: 8px;
    padding: 0 15px;
    box-sizing: border-box;
  }
`;

export const ImageAnswer = styled.img`
  max-width: 100%;
`;

export const BottomContentAnswer = styled.div`
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  margin: 30px 0;
  img {
    margin: 0 5px;
  }
  p {
    margin: 0;
  }
`;
