import styled from 'styled-components';

export const Title = styled.h1`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding: 8px 0;
  margin: 0 0 32px;
`;

export const Subtitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 1em;
  font-size: 20px;
`;

export const LearnersSection = styled.section`
  margin-bottom: 20px;
  animation-name: show;
  animation-duration: 0.5s;
`;

export const LearnerBox = styled.section`
  border-radius: 8px;
  border: 1px solid;
  padding: 18px;
`;

export const LearnerHeader = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;

  svg {
    cursor: pointer;
  }
`;

export const Text = styled.p`
  margin-bottom: 0.75em;
`;

export const AdditionalLearner = styled.div`
  color: ${({ theme }) => theme.colors.primary.default};
  margin: 10px 0;
  margin-bottom: 30px;
  cursor: pointer;

  span {
    margin-left: 10px;
  }
`;

export const SubmitSection = styled.section`
  display: flex;
  margin: 40px 0;
  justify-content: center;
`;

export const SubmitButtons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  input[type='submit'] {
    margin-top: 40px;
    width: 100px;
    padding: 15px;
    border-radius: 5px;
  }
`;

export const ContainerCheckboxs = styled.div`
  font-size: 13px;
  line-height: 18.2px;

  div {
    margin-bottom: 36px;
  }
`;

export const BoxNumber = styled.div`
  position: relative;
  width: 100%;
  max-width: 49%;

  @media only screen and (max-width: 680px) {
    max-width: 100%;
  }

  &:before {
    content: '+1';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 26px;
    width: 45px;
    height: 54px;
    background-color: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #dbdcdd;
  }

  > div {
    max-width: 100%;
  }

  input {
    margin-left: 45px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
