import React from 'react';
import parse from 'html-react-parser';
import { FieldBox, Error } from './common';
import { LabelStyle } from './styles';
import { ICheckboxProps } from './Form.interfaces';

export default function Checkbox(props: ICheckboxProps) {
  const {
    label,
    type,
    name,
    placeholder,
    handleChange,
    value,
    disabled,
    checked,
    error,
    centerError,
  } = props;
  return (
    <FieldBox full>
      <LabelStyle error={!!error}>
        <input
          type={type}
          name={name}
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          checked={checked}
        />
        <i />
        <span>{parse(label)}</span>
      </LabelStyle>
      {error && <Error center={centerError}>{error}</Error>}
    </FieldBox>
  );
}
