import styled from 'styled-components';

export const ContentWrapper = styled.div`
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: center;
  h3 {
    text-align: center;
    font-size: 28px;
    color: #000;
    font-weight: 500;
    padding-top: 20px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin: 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 8px;
  padding: 25px 20px;
  input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.tertiary.default};
    border: none;
    border-radius: 8px;
    padding: 0 15px;
  }
`;

export const StoryBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
  margin: 15px 0;
  font-style: italic;
`;
