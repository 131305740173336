/* eslint-disable prettier/prettier */
import React from 'react';
import moment from 'moment';
import { ReactComponent as TimeStep } from '../../assets/icons/TimeStep.svg';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';
import { ReactComponent as Ellipsis } from '../../assets/icons/Ellipsis.svg';
import { ReactComponent as Lock } from '../../assets/icons/Lock.svg';
import { ReactComponent as Calendar } from '../../assets/icons/Calendar.svg';
import {
  IScreenerBoxProps,
  IStatus,
  IButtonStatus,
} from './ScreenerBox.interfaces';
import Button from '../Button';
import {
  Container,
  StatusLink,
  Step,
  Subtitle,
  CalendarsContainer,
  CalendarGroup,
  CalendarInput,
} from './styles';

import MessageStatus from '../MessageStatus/MessageStatus';

const IconStatus = ({ status, completed, actual }: IStatus) => {
  let Icon = Lock;

  if (completed) Icon = Check;
  if (actual) Icon = Ellipsis;
  if (status === 'IN_PROGRESS') Icon = TimeStep;

  return (
    <Step completed={completed}>
      <Icon />
    </Step>
  );
};

const ButtonStatus = ({
  status,
  completed,
  pending,
  actual,
  url,
  learnerId,
  buttonText,
  completedName,
  title,
  isMobile,
  sessions,
  disabled,
  showSchedule,
  showPayment,
  showReview,
}: IButtonStatus) => {
  let text = '';
  if (pending) text = buttonText.pending;
  if (status === 'IN_PROGRESS') text = buttonText.inProgress;
  if (status === 'ACTION_REQUIRED') text = buttonText.actionRequired;

  if (completed) return null;

  const handleClickOption = () => {
    switch (title) {
      case 'Sessions':
        return showSchedule;
      case 'Review program':
        return showReview;
      default:
        return showPayment;
    }
  };

  const textToShow = () => {
    if (title === 'Sessions' && (sessions.length as number) === 2)
      return 'VIEW SESSIONS';
    if (title === 'Review program' && sessions.length === 1)
      return 'VIEW SESSION';
    return text;
  };

  if (actual) {
    return url === 'none' ? (
      <Button
        kind="small"
        full={isMobile}
        color="primary"
        onClick={handleClickOption()}
        disabled={disabled}
      >
        {textToShow()}
      </Button>
    ) : (
      <StatusLink
        to={`${
          completedName === 'parentScreenerStatus' ? url + learnerId : url
        }`}
      >
        {text}
      </StatusLink>
    );
  }
  return null;
};

export default function ScreenerBox(props: IScreenerBoxProps) {
  const {
    step,
    indexActualStep,
    title,
    completedName,
    subtitle,
    status,
    type,
    learnerId,
    nextStep,
    url,
    buttonText,
    isMobile,
    showPayment,
    showSchedule,
    showReview,
    sessions,
    reviewSession,
    disabled,
  } = props;

  const completed = status === 'COMPLETED' || status === true;
  const pending = !status || status === 'PENDING';
  const inProgress = !status || status === 'IN_PROGRESS';
  const acutalStep = nextStep === completedName;
  const isDisabled = !(
    completed === true ||
    (pending === true && acutalStep) ||
    (inProgress === true && acutalStep)
  );

  const newStatus =
    (status !== 'COMPLETED' &&
      title === 'Sessions' &&
      (sessions.length as number) === 2) ||
    (status !== 'COMPLETED' &&
      title === 'Review program' &&
      (reviewSession.length as number) === 1)
      ? 'IN_PROGRESS'
      : status;

  const sessionToDisplay = type === 'review' ? reviewSession : sessions;
  const newSessionToDisplay = sessionToDisplay.length
    ? sessionToDisplay[0].sessions
    : [];

  const calendarGroup = (learnerSessions, isReviewSession?) => {
    if (!learnerSessions.length) {
      return (
        <CalendarsContainer>
          <CalendarGroup>
            <Calendar />
            <CalendarInput>Not scheduled</CalendarInput>
          </CalendarGroup>
          {!isReviewSession && (
            <CalendarGroup>
              <Calendar />
              <CalendarInput>Not scheduled</CalendarInput>
            </CalendarGroup>
          )}
        </CalendarsContainer>
      );
    }
    return (
      <CalendarsContainer>
        {learnerSessions.map((session) => {
          return (
            <CalendarGroup
              key={session.id}
              disabled={session.startDate === null}
            >
              <Calendar />
              <CalendarInput disabled={session.startDate === null}>
                {session.startDate === null
                  ? 'Confirmation Pending. No action needed'
                  : moment.utc(session.startDate).local().format('MMM DD')}
              </CalendarInput>
            </CalendarGroup>
          );
        })}
        {type === 'schedule' && learnerSessions.length < 2 && (
          <CalendarGroup>
            <Calendar />
            <CalendarInput>Not scheduled</CalendarInput>
          </CalendarGroup>
        )}
      </CalendarsContainer>
    );
  };

  return (
    <Container
      actual={acutalStep}
      completed={completed}
      isDisabled={isDisabled}
    >
      <IconStatus
        status={newStatus}
        actual={acutalStep}
        completed={completed}
      />
      {step <= indexActualStep && (
        <MessageStatus
          status={newStatus}
          completed={completed}
          pending={pending}
        />
      )}

      <div>
        <b>
          {!(acutalStep && step === 1) && `${step}.`} {title}
        </b>
      </div>

      <Subtitle>{subtitle}</Subtitle>
      {type === 'schedule' && calendarGroup(newSessionToDisplay)}
      {type === 'review' && calendarGroup(newSessionToDisplay, true)}

      <ButtonStatus
        status={status}
        actual={acutalStep}
        completed={completed}
        completedName={completedName}
        pending={pending}
        learnerId={learnerId}
        url={url}
        buttonText={buttonText}
        showPayment={showPayment}
        showSchedule={showSchedule}
        showReview={showReview}
        title={title}
        isMobile={isMobile}
        sessions={newSessionToDisplay}
        disabled={disabled}
      />
    </Container>
  );
}
