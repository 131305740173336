import React from 'react';

import RadioButton from '../../RadioButton/RadioButton';
import { ContentWrapper, MainContent } from './styles';
import { IUniqueChoiseProps } from './UniqueChoise.interfaces';

export default function UniqueChoise(props: IUniqueChoiseProps) {
  const { title } = props;
  return (
    <ContentWrapper>
      <h3>{title}</h3>
      <MainContent>
        <RadioButton value="colder" label="Colder" />
        <RadioButton value="hot" label="Hot" />
        <RadioButton value="ice" label="Ice" />
        <RadioButton value="cool" label="Cool" />
      </MainContent>
    </ContentWrapper>
  );
}
