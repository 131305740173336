import styled from 'styled-components';

export { Background } from '../Common';

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;

  p {
    max-width: 248px;
    text-align: center;
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const ModalActions = styled.div<{ aligment?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ aligment }) => aligment || 'space-between'};
`;

export const BackGroup = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary.default};
  svg {
    margin-right: 14px;
  }
`;
