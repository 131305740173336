import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInitialPlan, resetPaymentOptions } from './payment.action';
import PriceBox from '../../components/Prices';
import { packageIncluded } from './payment.data';
import {
  PaymentWrapper,
  BoxesWrapper,
  PaymentFooter,
  ContactUsFooter,
} from './styles';
import { IPaymentProps } from './Payment.interfaces';

export default function Payment({ screener, ...rest }: IPaymentProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitialPlan());
    dispatch(resetPaymentOptions());
  }, [dispatch]);

  if (screener.length === 0) return <div> Loading...</div>;

  return (
    <PaymentWrapper>
      <BoxesWrapper>
        <PriceBox
          key={screener[0].productId}
          package={screener[0]}
          packageIncluded={packageIncluded['Skill Screener']}
          initialPlan
          {...rest}
        />
      </BoxesWrapper>
      <PaymentFooter>
        Not sure about the purchase?
        <ContactUsFooter>Contact us</ContactUsFooter>
      </PaymentFooter>
    </PaymentWrapper>
  );
}
