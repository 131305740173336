import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../Modal/Modal';
import { Subtitle, Small } from '../Common';
import { updateActualLearner } from '../../commonActions';
import { textStepLearner, indexOfNextStep } from '../../utils';

import Istate from '../../reducers/reducer.interfaces';

import {
  ModalHeader,
  UserOption,
  KidSelectionTitle,
  UserIcon,
  Circle,
  // IconPlus,
  IconSignal,
  BlockFlex,
  ContainerUsers,
  ContainerUser,
  User,
  // ContainerAddMoreUsers,
  Strong,
  Title,
  ArrowDown,
} from './styles';
import { IKid } from './SwitchUser.interfaces';

const Learner = ({ learner, isSelected, handleClick, step, message }: IKid) => {
  const { alias } = learner;
  return (
    <UserOption isSelected={isSelected} onClick={handleClick}>
      <KidSelectionTitle>
        <UserIcon size={40} letter={alias.charAt(0)} />
        <div>
          <Strong>{alias}</Strong>
          <BlockFlex>
            {step > 0 && <IconSignal step={step} />}
            <Small>{message}</Small>
          </BlockFlex>
        </div>
      </KidSelectionTitle>
      <Circle isSelected={isSelected} />
    </UserOption>
  );
};

const SwitchUser = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    actualLearner: { alias },
  } = useSelector((state: Istate) => state.user);
  const learners = useSelector((state: Istate) =>
    state.user.learners
      ? [...state.user.learners].sort((a, b) => a.alias.localeCompare(b.alias))
      : []
  );

  const openSwitchUser = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <ContainerUser>
        <User onClick={openSwitchUser}>
          <div>
            <Title>
              <Subtitle>{alias}</Subtitle>
              <ArrowDown />
            </Title>
          </div>
          <UserIcon size={40} />
        </User>
      </ContainerUser>

      <Modal isOpen={showModal} closeModal={handleClose}>
        <ModalHeader>
          <Subtitle>My Kids</Subtitle>
        </ModalHeader>
        <ContainerUsers>
          {learners.map((learner) => {
            const selectLearner = () => {
              dispatch(updateActualLearner(learner));
              handleClose();
            };
            return (
              <Learner
                key={Math.random() * 100}
                learner={learner}
                isSelected={learner.alias === alias}
                handleClick={
                  !(learner.alias === alias) ? selectLearner : undefined
                }
                message={textStepLearner(learner.nextStep)}
                step={indexOfNextStep(learner.nextStep)}
              />
            );
          })}
        </ContainerUsers>
        {/* <ContainerAddMoreUsers>
          <IconPlus /> <span> Add additional learner</span>
        </ContainerAddMoreUsers> */}
      </Modal>
    </>
  );
};

export default SwitchUser;
