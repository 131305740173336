import axios from 'axios';
import { updateLearner } from '../../commonActions';

export const PARENT_SCREENER_IN_PROGRESS = 'PARENT_SCREENER_IN_PROGRESS';
export const PARENT_SCREENER_DONE = 'PARENT_SCREENER_DONE';
export const USER_OPTIONS_UPDATE_FAILED = 'USER_OPTIONS_UPDATE_FAILED';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;

const updateUserFailure = (err) => ({
  type: USER_OPTIONS_UPDATE_FAILED,
  err,
});

export const updateLearnerProfile = (learnerOptions, learnerId) => {
  return (dispatch) => {
    const userID = localStorage.getItem('userID');
    const token = localStorage.getItem('token');
    axios
      .patch(
        `${HOST_PROFILE}user/${userID}/learner/${learnerId}`,
        learnerOptions,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch(updateLearner(res.data));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('getUser Error', err);
        dispatch(updateUserFailure('Something went wrong'));
      });
  };
};
