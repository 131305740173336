import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropIn from 'braintree-web-drop-in-react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BundleSectionTitles,
  BundleDescription,
  PriceBoxTitle,
} from 'components/Prices/styles';
import Button from 'components/Button';
import LoadingComponent from 'components/Loading';
import { Spinner } from 'components';

import { getBrainTreeSettings } from 'utils';

import IState from 'reducers';

import familyValuesChild from 'assets/icons/FamilyValuesChild.svg';

import {
  getBraintreeToken,
  sendPurchase,
  resetBraintreeToken,
} from './payment.action';
import {
  PaymentMethodWrapper,
  PaymentMethodFooter,
  BundlePaymentFooter,
  EndPayment,
  EndPaymentTitle,
  EndPaymentSubtitle,
  EndPaymentSubtitleTitle,
  EndPaymentContact,
  CheckContainer,
  DisabledText,
  PaymentTitle,
  PaymentSubTitle,
  PaymentUser,
  ImageContent,
  OrderAmmount,
  OrderItems,
  TotalPrice,
  ButtonContainer,
  LoadingBlock,
} from './styles';

import { IPaymentMethodProps, IbuyObject } from './Payment.interfaces';

export default function PaymentMethod({
  initialPlan,
  learnerId,
  package: packagePlan,
  closeModal,
  paymentOptions,
}: IPaymentMethodProps) {
  const [instance, setInstance] = useState<{
    requestPaymentMethod: () => { nonce: string };
  } | null>(null);

  const dispatch = useDispatch();
  const { actualLearner } = useSelector((state: IState) => state.user);
  const { isMobile } = useSelector((state: IState) => state.devices);
  const [paymentButtonClicked, setPaymenntButtonClicked] = useState(false);
  const [noBraintree, setNoBrainTree] = useState(false);

  const { discount, btToken, purchaseFailure, bundlesPurchased } = useSelector(
    (state: IState) => state.payment
  );

  const { productId, price } = packagePlan;

  const getTotalPrice = () => {
    const discountAmmount =
      discount.type === 'Percentage'
        ? (price * discount.discount) / 100
        : discount.discount;
    return initialPlan
      ? price - discountAmmount
      : paymentOptions && paymentOptions.total - paymentOptions.totalDiscount;
  };

  const buy = async () => {
    if (instance) {
      const { nonce } = await instance.requestPaymentMethod();

      const buyObject = {
        nonce,
        productId,
        learnerId,
      } as IbuyObject;
      if (discount.discount > 0) {
        buyObject.discountCode = discount.code;
      }
      if (!initialPlan) {
        buyObject.quantity = paymentOptions?.quantity;
        buyObject.enrollmentStatus = paymentOptions?.enrollmentStatus;
      }
      setPaymenntButtonClicked(true);
      dispatch(sendPurchase(initialPlan, buyObject));
    }
  };

  useEffect(() => {
    const discountAmmount =
      discount.type === 'Percentage'
        ? (price * discount.discount) / 100
        : discount.discount;
    if (price === discountAmmount && discountAmmount > 0) {
      const buyObject = {
        nonce: '',
        productId,
        learnerId,
        discountCode: discount.code,
      } as IbuyObject;
      setNoBrainTree(true);
      dispatch(sendPurchase(initialPlan, buyObject));
    } else {
      dispatch(getBraintreeToken());
      setPaymenntButtonClicked(false);
      dispatch(resetBraintreeToken());
    }
  }, [dispatch, discount, price, productId, learnerId, initialPlan]);

  if (
    (!btToken && !noBraintree) ||
    (noBraintree && !actualLearner.initialPlanPayed)
  ) {
    return (
      <LoadingBlock>
        <Spinner />
      </LoadingBlock>
    );
  }
  if ((!initialPlan && bundlesPurchased) || (noBraintree && bundlesPurchased)) {
    return (
      <EndPayment>
        <CheckContainer>
          <FontAwesomeIcon size="4x" icon={faCheckCircle} />
        </CheckContainer>
        <EndPaymentTitle>Payment Successful!</EndPaymentTitle>
        <DisabledText mw={430} mb={0}>
          You have purchased
          <b>
            {` ${paymentOptions?.quantity && paymentOptions?.quantity} `}
            Sessions for {actualLearner.alias}
          </b>
          .
          <br />
          <br />
          For scheduling assistance, please contact us at
        </DisabledText>
        <EndPaymentContact mt={5}>
          <a href="mailto:hello@learnfully.com">hello@learnfully.com</a>
        </EndPaymentContact>
      </EndPayment>
    );
  }
  if (
    (initialPlan && actualLearner.initialPlanPayed) ||
    (noBraintree && actualLearner.initialPlanPayed)
  ) {
    return (
      <EndPayment>
        <CheckContainer>
          <FontAwesomeIcon size="4x" icon={faCheckCircle} />
        </CheckContainer>
        <EndPaymentTitle>Success!</EndPaymentTitle>
        <EndPaymentSubtitle>
          <EndPaymentSubtitleTitle>
            <b>Next steps: </b>
          </EndPaymentSubtitleTitle>
          {`Schedule ${actualLearner.alias}'s screener sessions.`}
        </EndPaymentSubtitle>
        <PaymentMethodFooter>
          <Button type="button" onClick={closeModal}>
            Back to Home
          </Button>
        </PaymentMethodFooter>
        <DisabledText mt={40} mb={25}>
          *This amount will be applied as credit towards future learning
          sessions.
        </DisabledText>
      </EndPayment>
    );
  }
  if (purchaseFailure.status) {
    return (
      <EndPayment>
        <EndPaymentTitle>There was an Error!</EndPaymentTitle>
        <EndPaymentSubtitle>
          Unfortunately we have an issue with your payment. Try again later.
        </EndPaymentSubtitle>
        <PaymentMethodFooter>
          <Button type="button" onClick={closeModal}>
            Try again
          </Button>
        </PaymentMethodFooter>
      </EndPayment>
    );
  }

  return (
    <PaymentMethodWrapper>
      {initialPlan ? (
        <>
          <PaymentTitle>{`Total to pay: $${getTotalPrice()}`} </PaymentTitle>
          <DropIn
            key={btToken}
            options={{
              authorization: btToken,
              ...getBrainTreeSettings(),
            }}
            onInstance={(inst) => setInstance(inst)}
          />
          {instance ? (
            <PaymentMethodFooter>
              <Button
                type="button"
                disabled={paymentButtonClicked}
                onClick={buy}
                width={139}
              >
                {paymentButtonClicked ? <LoadingComponent /> : 'Purchase'}
              </Button>
            </PaymentMethodFooter>
          ) : (
            <div>Loading...</div>
          )}
        </>
      ) : (
        <>
          <PaymentTitle>Checkout</PaymentTitle>
          <PaymentUser>
            <ImageContent>
              <img src={familyValuesChild} alt="" />
            </ImageContent>
            <div>
              For <b>{`${actualLearner.alias}`}</b>{' '}
            </div>
          </PaymentUser>
          <BundleSectionTitles first>
            {`${paymentOptions?.quantity} ${paymentOptions?.name}`}
          </BundleSectionTitles>
          {paymentOptions && (
            <BundleDescription>
              That includes{' '}
              <b>{`${paymentOptions?.quantity} x 1 hour sessions`}</b>
            </BundleDescription>
          )}
          <PaymentSubTitle>Payment method</PaymentSubTitle>
          <DropIn
            key={btToken}
            options={{
              authorization: btToken,
              ...getBrainTreeSettings(),
            }}
            onInstance={(inst) => setInstance(inst)}
          />
          {instance && paymentOptions ? (
            <>
              <BundlePaymentFooter>
                <OrderAmmount>
                  <OrderItems>Subtotal</OrderItems>
                  <PriceBoxTitle>{`$${paymentOptions?.total}`}</PriceBoxTitle>
                  <OrderItems dark>All discounts /credits</OrderItems>
                  <PriceBoxTitle dark>
                    {`-$${paymentOptions?.totalDiscount}`}
                  </PriceBoxTitle>
                </OrderAmmount>
                <TotalPrice>
                  <div>TOTAL</div>
                  <PriceBoxTitle size={28}>${getTotalPrice()}</PriceBoxTitle>
                </TotalPrice>
              </BundlePaymentFooter>
              <ButtonContainer>
                <Button full={isMobile} width={139} type="button" onClick={buy}>
                  {paymentButtonClicked ? <LoadingComponent /> : 'Submit'}
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <div>Loading...</div>
          )}
        </>
      )}
    </PaymentMethodWrapper>
  );
}
