import React from 'react';
import ThankYouContainer from './styles';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';

export default function ThankYou(): JSX.Element {
  return (
    <ThankYouContainer>
      <Check />
      <h2>Thank you!</h2>
      <p>
        We’re excited to help your child embrace learning! <br />
        Please <b>check your inbox</b> for a Learnfully email to get started.
        <br />
        You can also contact us at:
        <span>
          <b>
            <a href="mailto:contact@learnfully.com">contact@learnfully.com</a>
          </b>
        </span>
      </p>
    </ThankYouContainer>
  );
}
