import styled from 'styled-components';

export const ContentWrapper = styled.div`
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  h3 {
    text-align: center;
    font-size: 28px;
    color: #000;
    font-weight: 500;
    padding-top: 20px;
  }
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
`;

export const TopContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white.default};
  width: 210px;
  height: 168px;
  margin: 15px;
  background-color: ${({ theme }) => theme.colors.tertiary.default};
`;

export const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-size: 28px;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  border-radius: 8px;
  padding: 0 10px;
`;

export const InputWrapper = styled.div`
  width: 100px;
  height: 50px;
  margin: 10px;
  input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.tertiary.default};
    border: none;
    border-radius: 8px;
    padding: 0 15px;
  }
`;

export const ImageElement = styled.div<{ image?: string | null | undefined }>`
  ${({ image }) => (image ? `background-image: url(${image});` : null)}
  ${({ image, theme }) =>
    image ? `background-color: ${theme.colors.tertiary.default};` : null}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
