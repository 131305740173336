import styled from 'styled-components';

export { UserIcon } from '../Common';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const IconPlus = styled.i`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: none;
  background: ${({ theme }) => theme.colors.main.default};
  border-radius: 100%;
  margin-right: 5px;
  margin-top: -2px;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    margin-left: -1px;
    top: 4px;
    left: 8px;
    height: 8px;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.white.default};
  }

  &:after {
    transform: rotate(90deg);
  }
`;

interface IUserOption {
  isSelected: boolean;
}

const UserOption = styled.div<IUserOption>`
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected
        ? theme.colors.primary.default
        : theme.colors.quaternary.default};
  display: flex;
  margin-bottom: 12px;
  padding: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
`;

const KidSelectionTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Subtitle = styled.div`
  font-size: 13px;
`;

interface ICircle {
  isSelected: boolean;
}

const Circle = styled.div<ICircle>`
  border-radius: 100%;
  height: 26px;
  width: 26px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary.default : theme.colors.main.default};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, isSelected }) =>
    isSelected &&
    `
      &:after {
        content: '';
        border-radius: 100%;
        height: 16px;
        width: 16px;
        background-color: ${theme.colors.primary.default};
      }
  `}
`;

interface IIconSignal {
  step: number;
}

const IconSignal = styled.i<IIconSignal>`
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.main.default};
  height: 13px;
  width: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: -2px;

  &:after {
    content: '${({ step }) => step && step}';
    color: ${({ theme }) => theme.colors.white.default};
    font-size: 8px;
    font-style: initial;
  }
`;

const BlockFlex = styled.div`
  display: flex;
  align-items: center;
`;

const ContainerUsers = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const ContainerAddMoreUsers = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
`;

interface IContainer {
  isMobile: boolean;
}

const Container = styled.div<IContainer>`
  padding: 16px 0;
  display: flex;
  justify-content: ${({ isMobile }) =>
    isMobile ? 'space-between' : 'flex-end'};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
`;

const ContainerUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding-bottom: 20px;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.default};
  padding-right: 3px;

  h3 {
    margin: 0 10px 0 0;
  }
`;

const ArrowDown = styled.i`
  border: solid ${({ theme }) => theme.colors.primary.default};
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

const Strong = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primary.default};
`;

export {
  ModalHeader,
  UserOption,
  KidSelectionTitle,
  Subtitle,
  Circle,
  IconPlus,
  IconSignal,
  BlockFlex,
  ContainerUsers,
  ContainerAddMoreUsers,
  Container,
  ContainerUser,
  User,
  Title,
  ArrowDown,
  Strong,
};
