import styled from 'styled-components';

export const Title = styled.h1`
  border-bottom: 1px solid ${({ theme }) => theme.colors.quaternary.default};
  padding: 8px 0;

  @media (max-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    margin-top: 50px;
  }
`;

export const TopContent = styled.div`
  p {
    color: ${({ theme }) => theme.colors.main.default};
    font-weight: bold;
    margin: 0;
  }
  span {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.main.medium};
  }
`;

export const BottomContent = styled.div`
  p {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.main.medium};
    margin: 0;
    padding-bottom: 4px;
  }
  span {
    color: ${({ theme }) => theme.colors.primary.default};
    font-size: 13px;
    font-weight: 700;
  }
`;
