import React from 'react';
import {
  ContainerSchedule,
  ContainerUser,
  UserImg,
  UserInfo,
  Subject,
} from './styles';
import Button from '../Button';
import { SpecialistProps } from './Dashboard.interfaces';
import specialistLogo from '../../assets/images/LF-Avatar-Blue.png';

const SpecialistCard = ({ specialist, scheduleSession }: SpecialistProps) => {
  const { lastName, firstName, titleDegree } = specialist;
  return (
    <ContainerSchedule>
      <ContainerUser>
        <UserInfo>
          <UserImg>
            <img src={specialistLogo} alt="specialist" />
          </UserImg>
          <UserInfo>
            <b>{`${firstName} ${lastName}`}</b>
            <Subject>{titleDegree}</Subject>
          </UserInfo>
        </UserInfo>
        <Button
          type="button"
          kind="quaternary"
          color="quaternary"
          width={142}
          onClick={scheduleSession}
        >
          SCHEDULE
        </Button>
      </ContainerUser>
    </ContainerSchedule>
  );
};

export default SpecialistCard;
