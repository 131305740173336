import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import {
  PriceBoxComponent,
  PriceBoxTitle,
  Price,
  PriceSubtitle,
  PriceDescription,
  PackageList,
  PackageIncludeWrapper,
  IncludeItem,
  PackageFooter,
} from './styles';
import Button from '../Button';
import { IPriceBox } from './Prices.interfaces';

export default function PriceBox(props: IPriceBox) {
  const { name, price, description } = props.package;
  const { isMobile } = useSelector(
    (state: { devices: { isMobile: boolean } }) => state.devices
  );
  return (
    <PriceBoxComponent premium={name === 'Gold Package'}>
      {name === 'Gold Package' && (
        <div style={{ marginBottom: 8 }}>Recomended</div>
      )}
      <PriceBoxTitle>
        {props.initialPlan ? 'Skill Screener' : name}
      </PriceBoxTitle>
      <Price>${price}</Price>
      {!props.initialPlan && (
        <PriceSubtitle>
          Skill Screener
          {props.sessionsAmount && props.sessionsAmount > 0
            ? ` + ${props.sessionsAmount} sessions`
            : '.'}
        </PriceSubtitle>
      )}
      {!props.initialPlan && (
        <PriceDescription align="center">{description}</PriceDescription>
      )}
      <PackageList spaceBetween>Purchase Includes:</PackageList>
      <PackageIncludeWrapper>
        {props.packageIncluded.map((item) => (
          <IncludeItem key={item}>
            <FontAwesomeIcon icon={faCheckCircle} />
            {item}
          </IncludeItem>
        ))}
        <PackageFooter>
          Payment for the screener will be applied as a credit towards future
          learning sessions.
        </PackageFooter>
      </PackageIncludeWrapper>
      <Button
        full={isMobile}
        bg="primary"
        onClick={() => props.next(props.package)}
        width={156}
      >
        Get Started
      </Button>
    </PriceBoxComponent>
  );
}
