import React, { useState } from 'react';

import noImage from '../../assets/images/noImage.png';
import QuestionWrapper from '../../components/QuestionWrapper/QuestionWrapper';
import DragableImagesQuestion from '../../components/questions/DragableImagesQuestion/DragableImagesQuestion';
import SortableQuestion from '../../components/questions/SortableQuestion/SortableQuestion';
import UniqueChoise from '../../components/questions/UniqueChoise/UniqueChoise';
import FillBlankQuestion from '../../components/questions/FillBlankQuestion/FillBlankQuestion';
import SimpleQuestion from '../../components/questions/SimpleQuestion/SimpleQuestion';
import StoryComponent from '../../components/questions/StoryComponent/StoryComponent';
import StoryComponentAnswer from '../../components/questions/StoryComponent/StoryComponentAnswer';

const imageList = [
  {
    name: '1',
    image: noImage,
  },
  {
    name: '2',
    image: noImage,
  },
  {
    name: '3',
    image: noImage,
  },
  {
    name: '4',
    image: noImage,
  },
  {
    name: '5',
    image: noImage,
  },
  {
    name: '6',
    image: noImage,
  },
  {
    name: '7',
    image: noImage,
  },
  {
    name: '8',
    image: noImage,
  },
];

const steps = [
  <UniqueChoise title="The opposite of cold:" />,
  <DragableImagesQuestion
    title="Place images in the right sequence"
    imageList={[...imageList]}
  />,
  <DragableImagesQuestion
    title="Place images in the right sequence"
    imageList={[...imageList]}
    reorder
  />,
  <SortableQuestion title="Place the sentences in the right sequence" />,
  <FillBlankQuestion
    title="Fill in the missing letters."
    image={noImage}
    beginin="Ho"
    end="se"
  />,
  <SimpleQuestion
    title="Re-write the sentence in the Past Tense"
    question="Chad will tell his dad"
  />,
  <StoryComponent
    title="Read the Story and Answer the questions."
    questionType="image"
    image={noImage}
  />,
  <StoryComponentAnswer
    image={noImage}
    title="What does Anu play?"
    label="Noun:"
  />,
  <StoryComponent
    title="Read the Story and Answer the questions."
    questionType="text"
    story="Anu loves playing tennis and is the best player in her school. She wants to play tennis and will ask her friend to play with her."
  />,
  <StoryComponentAnswer
    story="Anu loves playing tennis and is the best player in her school. She wants to play tennis and will ask her friend to play with her."
    title="What does Anu play?"
    label="Noun:"
  />,
];

export default function KidScreener() {
  const [stepIndex, setStepIndex] = useState<number>(0);

  return (
    <QuestionWrapper
      currentIndex={stepIndex}
      totalItems={steps.length}
      title="Kid 1 Literacy Screener"
      onClickNext={() => {
        setStepIndex((current) => current + 1);
      }}
      onClickBack={() => {
        setStepIndex((current) => current - 1);
      }}
    >
      {steps[stepIndex]}
    </QuestionWrapper>
  );
}
