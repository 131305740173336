import React from 'react';
import { Wrapper, Title } from './styles';
import { ILearnersProps } from './Learners.interfaces';
import Learner from './Learner';

export default function Learners({ title, image, learners }: ILearnersProps) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {learners.map((learner) => (
        <Learner learner={learner} image={image} key={learner.learnerId} />
      ))}
    </Wrapper>
  );
}
