import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 28px;
  font-weight: 500;
`;

export const FormContainer = styled.div``;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;

  svg {
    width: 100px;
    height: 100px;
  }

  div {
    margin-bottom: 25px;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  button {
    width: 202px;
  }
`;

export const ContainerPassword = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 46px;
    right: 28px;
    z-index: 4;
    cursor: pointer;
  }
`;

export const Error = styled.div<{ center?: boolean }>`
  padding: 10px 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.alert.default};
  ${({ center }) => center && `text-align: center;`}
`;
