import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TransitionStyle = styled.div<{
  init: boolean;
  timeout: number;
}>`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: all ${({ timeout }) => timeout}ms;
  z-index: 5;

  ${({ init }) => init && ` opacity: 1; `}
`;

interface ITransition {
  trigger: boolean;
  children: React.ReactNode;
  timeout?: number;
}

const Transition = (props: ITransition) => {
  const { trigger, timeout = 300, children } = props;
  const [active, setActive] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    let timerIn = 0;
    let timerOut = 0;
    if (trigger) {
      clearTimeout(timerOut);
      timerOut = 0;
      setActive(true);
      timerIn = setTimeout(() => {
        setInit(true);
      }, 1);
    } else {
      clearTimeout(timerIn);
      timerIn = 0;
      setInit(false);
      timerOut = setTimeout(() => {
        setActive(false);
      }, timeout);
    }
    return () => {
      clearTimeout(timerIn);
      clearTimeout(timerOut);
      timerIn = 0;
      timerOut = 0;
    };
  }, [trigger, timeout]);

  return active ? (
    <TransitionStyle init={init} timeout={timeout}>
      {children}
    </TransitionStyle>
  ) : null;
};

export default Transition;
