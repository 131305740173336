import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import { Modal, Slider, ScreenerBox } from '..';
import {
  updateUser,
  getUser,
  getLearnerSessions,
  cleanSessions,
} from '../../commonActions';
import { Payment, Summary, PaymentMethod } from '../../pages/Payment';
import { indexOfNextStep } from '../../utils';
import { Background, SubHeader, BackGroup } from './styles';
import {
  KidScreenerSchedule,
  CalendlyPage,
  ViewScheduleDetails,
} from '../../pages/KidScreenerSchedule';

import steps from './stepsOnboarding';
import stepBoxes from './stepBoxes';
import IState from '../../reducers';

import { resetPaymentFailure } from '../../pages/Payment/payment.action';

export default function Steps() {
  const dispatch = useDispatch();
  const { info, actualLearner, homeSteps } = useSelector(
    (state: IState) => state.user
  );
  const { retryLearnerSessions } = useSelector(
    (state: IState) => state.sessions
  );

  const { isMobile } = useSelector((state: IState) => state.devices);
  const { purchaseFailure, screener } = useSelector(
    (state: IState) => state.payment
  );
  const { sessionDetails } = useSelector((state: IState) => state.sessions);

  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [showReview, setShowReview] = useState<boolean>(false);
  const [paymentStep, setPaymentStep] = useState<number>(0);
  const [scheduleStep, setScheduleStep] = useState<number>(0);
  const [maxWidth, setMaxWidth] = useState<number>(674);
  const [sessionNumber, setSessionNumber] = useState<number>(1);
  const [lastStep, setLastStep] = useState(false);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const getSessions = useCallback(() => {
    if (
      actualLearner.nextStep === 'learnerScreenerStatus' ||
      actualLearner.nextStep === 'screenerReviewStatus'
    ) {
      dispatch(getLearnerSessions());
    }
  }, [actualLearner.nextStep, dispatch]);

  useEffect(() => {
    getSessions();
  }, [getSessions, actualLearner.learnerId]);

  useEffect(() => {
    const stepClean =
      actualLearner.nextStep === 'parentScreenerStatus' ||
      actualLearner.nextStep === 'initialPlanPayed';
    if (sessionDetails.length > 0 && stepClean) dispatch(cleanSessions());
  }, [actualLearner.nextStep, dispatch, sessionDetails]);

  useEffect(() => {
    let timer;
    if (retryLearnerSessions) {
      timer = setInterval(() => {
        getSessions();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [getSessions, retryLearnerSessions]);

  useEffect(() => {
    let timer;
    if (sessionDetails.length > 1) {
      sessionDetails.forEach((s) => {
        if (s.sessions.length && !s.sessions[0].sessionName) {
          timer = setTimeout(() => {
            getSessions();
          }, 10000);
        }
      });
    }
    return clearTimeout(timer);
  }, [getSessions, sessionDetails]);

  useEffect(() => {
    if (paymentStep === 0) setMaxWidth(640);
    if (paymentStep === 1) setMaxWidth(640);
    if (paymentStep === 1 && actualLearner.initialPlanPayed) setMaxWidth(446);
    if (scheduleStep === 1) setMaxWidth(1000);
    if (scheduleStep === 1 && showReview) setMaxWidth(1000);
  }, [actualLearner.initialPlanPayed, paymentStep, scheduleStep, showReview]);

  if (!actualLearner?.learnerId) return <div> Loading...</div>;

  const screenerSessions = sessionDetails.filter((session) => {
    return session.minutesPerSession === 60;
  });

  const reviewSession = sessionDetails.filter(
    (session) => session.minutesPerSession === 45
  );

  const handleNext = () => {
    setPaymentStep(paymentStep + 1);
  };

  const handleCloseModal = () => {
    setShowPayment(false);
    setShowSchedule(false);
    setShowReview(false);
    if (paymentStep === 2) {
      if (purchaseFailure.status) {
        dispatch(resetPaymentFailure());
      }
      setTimeout(() => {
        setPaymentStep(0);
      }, 1000);
    }
    setPaymentStep(0);
    setTimeout(() => {
      setScheduleStep(0);
    }, 1000);
  };

  const paymentStepsToShow = () => {
    switch (paymentStep) {
      case 0:
        return (
          <Payment
            next={handleNext}
            screener={screener}
            closeModal={handleCloseModal}
          />
        );
      case 1:
        return (
          <Summary
            next={handleNext}
            actualLearner={actualLearner}
            initialPlan
          />
        );
      case 2:
        return (
          <PaymentMethod
            closeModal={handleCloseModal}
            learnerId={actualLearner.learnerId}
            package={screener[0]}
            initialPlan
          />
        );
      default:
        return null;
    }
  };

  const handleNextSchedule = (session, details) => {
    details ? setScheduleStep(3) : setScheduleStep(scheduleStep + 1);
    setSessionNumber(session);
  };

  const scheduleStepsToShow = (sessionsGroup, isReviewSession?) => {
    switch (scheduleStep) {
      case 0:
        return (
          <KidScreenerSchedule
            learner={actualLearner}
            next={handleNextSchedule}
            sessions={sessionsGroup}
          />
        );
      case 1:
        return (
          <CalendlyPage
            alias={actualLearner.alias}
            sessionNumber={sessionNumber}
            sessionId={
              isReviewSession
                ? reviewSession[0]?.packageId
                : screenerSessions[0]?.packageId
            }
            sessionDetailId={
              isReviewSession
                ? reviewSession[0]?.packageDetailId
                : screenerSessions[0]?.packageDetailId
            }
            isReviewSession={isReviewSession}
            isLastStep={setLastStep}
          />
        );
      case 3:
        return (
          <ViewScheduleDetails
            alias={actualLearner.alias}
            session={sessionsGroup[0]}
            sessionNumber={sessionNumber}
            isReviewSession={isReviewSession}
          />
        );
      default:
        return null;
    }
  };

  const BackButton = () => (
    <BackGroup
      onClick={() => setPaymentStep(paymentStep - 1)}
      className="cursor-pointer"
    >
      <FontAwesomeIcon icon={faAngleLeft} /> Back
    </BackGroup>
  );

  const ScheduleBackButton = (isReviewSession) => {
    const scheduleHandle = () =>
      setScheduleStep(scheduleStep === 3 ? 0 : scheduleStep - 1);
    const reviewHandle = () => handleCloseModal();
    return (
      <BackGroup
        onClick={!isReviewSession ? scheduleHandle : reviewHandle}
        className="cursor-pointer"
      >
        <FontAwesomeIcon icon={faAngleLeft} />
        {!isReviewSession ? 'Screener sessions' : 'Home'}
      </BackGroup>
    );
  };

  const closeOnboarding = () => {
    dispatch(updateUser({ isOnboardingCompleted: true }));
  };

  const showReviewHandle = () => {
    setShowReview(true);
    setScheduleStep(reviewSession[0].sessions.length ? 3 : 1);
  };

  return (
    <Background>
      <SubHeader />
      {homeSteps.map((step, idx) => {
        const boxProps = {
          ...stepBoxes[step],
          step: idx + 1,
          indexActualStep: indexOfNextStep(actualLearner.nextStep),
          status: actualLearner[stepBoxes[step].completedName],
          nextStep: actualLearner.nextStep,
          learnerId: actualLearner.learnerId,
          disabled: retryLearnerSessions,
          showPayment: () => setShowPayment(true),
          showSchedule: () => setShowSchedule(true),
          showReview: showReviewHandle,
          sessions: sessionDetails.filter(
            (session) => session.minutesPerSession === 60
          ),
          reviewSession: sessionDetails.filter(
            (session) => session.minutesPerSession === 45
          ),
          isMobile,
        };
        return <ScreenerBox key={step} {...boxProps} />;
      })}

      <Modal
        isOpen={!info.onboardingCompleted}
        closeModal={() => closeOnboarding()}
        full={isMobile}
        mw={572}
      >
        <Slider slides={steps} callback={() => closeOnboarding()} />
      </Modal>

      <Modal
        isOpen={showPayment}
        closeModal={handleCloseModal}
        leftAction={
          !actualLearner.initialPlanPayed && paymentStep > 0 && BackButton()
        }
        full={isMobile}
        mw={maxWidth}
      >
        {paymentStepsToShow()}
      </Modal>

      <Modal
        isOpen={showSchedule}
        closeModal={!lastStep ? handleCloseModal : undefined}
        leftAction={scheduleStep > 0 && !lastStep && ScheduleBackButton(false)}
        full={isMobile}
        mw={maxWidth}
      >
        {scheduleStepsToShow(screenerSessions)}
      </Modal>

      <Modal
        isOpen={showReview}
        closeModal={!lastStep ? handleCloseModal : undefined}
        leftAction={scheduleStep > 0 && !lastStep && ScheduleBackButton(true)}
        full={isMobile}
        mw={maxWidth}
      >
        {scheduleStepsToShow(reviewSession, true)}
      </Modal>
    </Background>
  );
}
