import {
  INTERESTS_SERVICES,
  GRADES,
  LOCATIONS,
  FORM_DATA_SUCCESS,
  FORM_DATA_SEND,
  FORM_DATA_FAILURE,
  CLOSE_THANK_YOU_MODAL,
} from '../pages/ContactForm/contactForm.action';

const initialState = {
  interests: [],
  grades: [],
  locations: [],
  formResponse: {},
  showThankYouModal: false,
  loading: false,
};

export default function contactUsData(state = initialState, action) {
  switch (action.type) {
    case INTERESTS_SERVICES:
      return {
        ...state,
        interests: action.payload.interests.interests,
      };
    case GRADES:
      return {
        ...state,
        grades: action.payload.grades.grades,
      };
    case LOCATIONS:
      return {
        ...state,
        locations: action.payload.locations.states,
      };
    case FORM_DATA_SEND:
      return {
        ...state,
        loading: true,
      };
    case FORM_DATA_SUCCESS:
      return {
        ...state,
        showThankYouModal: true,
        loading: false,
      };
    case FORM_DATA_FAILURE:
      return {
        ...state,
        formResponse: action.payload,
        loading: false,
      };
    case CLOSE_THANK_YOU_MODAL:
      return {
        ...state,
        showThankYouModal: false,
      };
    default:
      return state;
  }
}
