import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../commonActions';

import { SignOutButton } from './styles';

export default function SignOut() {
  const history = useHistory();
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(logout);
    history.push('/signIn');
  };

  return <SignOutButton onClick={signOut}>Sign Out</SignOutButton>;
}
