import { CognitoUserPool } from 'amazon-cognito-identity-js';

export const SEND_RESET = 'SEND_RESET';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILURE = 'RESET_FAILURE';
export const CLEAR_RESET_DATA = 'CLEAR_RESET_DATA';

const sendReset = () => ({
  type: SEND_RESET,
});

const resetSuccess = (result) => ({
  type: RESET_SUCCESS,
  result,
});

const resetFailure = (err) => ({
  type: RESET_FAILURE,
  err,
});

export const clearData = () => ({
  type: CLEAR_RESET_DATA,
});

export const sendPasswordData = ({ password, newPassword }) => {
  return (dispatch) => {
    dispatch(sendReset());

    const resetData = {
      password,
      newPassword,
    };
    const poolData = {
      UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
      ClientId: process.env.REACT_APP_AWS_CLIENT_ID || '',
    };
    const userPool = new CognitoUserPool(poolData);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err) => {
        if (err) {
          dispatch(resetFailure(err));
        }
      });
      cognitoUser.changePassword(
        resetData.password,
        resetData.newPassword,
        (err, result) => {
          if (err) {
            dispatch(resetFailure(err.message));
            return;
          }
          dispatch(resetSuccess(result));
        }
      );
    }
  };
};
