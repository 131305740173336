import styled from 'styled-components';

export const ContentWrapper = styled.div<{ reorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: ${({ reorder }) => (reorder ? '90%' : '80%')};
  h3 {
    text-align: center;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.main.default};
    font-weight: 500;
    margin: 30px 0;
  }
  @media only screen and (max-width: 600px) {
    h3 {
      padding-top: 20px;
    }
  }
`;

export const TopContent = styled.div<{ reorder: boolean }>`
  ${({ reorder }) => (!reorder ? 'display: grid;' : null)}
  ${({ reorder }) => (reorder ? 'display: flex;' : null)}
  width: 100%;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${({ reorder }) => (reorder ? '5px' : '10px')};
  align-items: center;
  justify-content: center;
  padding: ${({ reorder }) => (reorder ? '5px' : '10px')};
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const BottomContent = styled(TopContent)`
  ${({ reorder }) => (!reorder ? 'display: grid;' : null)}
  ${({ reorder }) => (reorder ? 'display: flex;' : null)}
  border: 1px solid ${({ theme }) => theme.colors.secondary.default};
  margin-bottom: 30px;
`;

export const StoryBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary.default};
`;

export const Box = styled.div<{
  image?: string | null;
  reorder?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white.default};
  border: 1px solid ${({ theme }) => theme.colors.secondary.default};
  overflow: hidden;
  width: 100%;
  height: ${({ reorder }) => (reorder ? '60px;' : '120px;')};
  margin: 0;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const ImageBox = styled(Box)`
  border: 1px solid transparent;
`;

export const BottomBox = styled(Box)`
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary.default};
    color: ${({ theme }) => theme.colors.primary.default};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageElement = styled.div<{ image?: string | null | undefined }>`
  ${({ image }) => (image ? `background-image: url(${image});` : null)}
  ${({ image, theme }) =>
    image ? `background-color: ${theme.colors.tertiary.default};` : null}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  cursor: inherit;
`;

export const DragableFigureBox = styled.div`
  width: 100%;
  height: 100%;
  cursor: grabbing;
`;

export const DropFigureBox = styled.div`
  width: 100%;
  height: 100%;
`;
