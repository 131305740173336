import {
  RESET_SUCCESS,
  RESET_FAILURE,
  SEND_RESET,
  CLEAR_RESET_DATA,
} from '../components/ResetPassword/resetPassword.actions';

const initialState = {
  success: false,
  loading: false,
  resetFailure: '',
};

export default function resetPassword(state = initialState, action) {
  switch (action.type) {
    case SEND_RESET:
      return {
        ...state,
        loading: true,
        resetFailure: '',
      };
    case RESET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case RESET_FAILURE:
      return {
        ...state,
        resetFailure: action.err,
        loading: false,
      };
    case CLEAR_RESET_DATA:
      return {
        ...state,
        success: false,
        loading: false,
        resetFailure: '',
      };
    default:
      return state;
  }
}
