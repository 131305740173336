interface IBrainTreeSettings {
  card?: {
    overrides: {
      fields: {
        cvv: {
          placeholder?: string;
        };
        postalCode: {
          minlength?: number;
        };
      };
    };
    cardholderName: {
      required?: boolean;
    };
    postalCode: {
      required?: boolean;
    };
    cvv: {
      required?: boolean;
    };
  };
  paypal?: {
    flow?: string;
    commit?: boolean;
  };
  venmo?: boolean;
  vaultManager?: boolean;
}

export const getBrainTreeSettings = () => {
  let brainTreeSettings: IBrainTreeSettings = {
    vaultManager: true,
    card: {
      overrides: {
        fields: {
          cvv: {
            placeholder: 'CVV',
          },
          postalCode: {
            minlength: 5,
          },
        },
      },
      cardholderName: {
        required: true,
      },
      postalCode: {
        required: true,
      },
      cvv: {
        required: true,
      },
    },
  };

  if (process.env.REACT_APP_ENABLE_PAYPAL) {
    brainTreeSettings = {
      ...brainTreeSettings,
      paypal: {
        flow: 'vault',
        commit: false,
      },
    };
  }

  if (process.env.REACT_APP_ENABLE_VENMO) {
    brainTreeSettings = {
      ...brainTreeSettings,
      venmo: true,
    };
  }

  return brainTreeSettings;
};

export function textStepLearner(step) {
  switch (step) {
    case 'parentScreenerStatus':
      return 'Parent feedback';
    case 'initialPlanPayed':
      return 'Program Selection';
    case 'learnerScreenerStatus':
      return 'Screener Session';
    case 'screenerReviewStatus':
      return 'Review Program';
    default:
      return '';
  }
}

export function indexOfNextStep(step) {
  const steps = [
    'parentScreenerStatus',
    'initialPlanPayed',
    'learnerScreenerStatus',
    'screenerReviewStatus',
  ];
  return steps.indexOf(step) + 1;
}

export function checkNextStep(learner) {
  /* 
     0 parentFeedback ==> parentScreenerStatus 
     1 programSelection ==> initialPlanPayed 
     2 screenerSession ==> learnerScreenerStatus 
     3 reviewProgram ==> screenerReviewStatus 
  */
  const steps = [
    'parentScreenerStatus',
    'initialPlanPayed',
    'learnerScreenerStatus',
    'screenerReviewStatus',
  ];

  let nextStep = '';
  steps.every((step) => {
    if (learner[step] === 'COMPLETED' || learner[step] === true) {
      return true;
    }
    nextStep = step;
    return false;
  });
  return nextStep;
}

export function updateLearner(learner) {
  return {
    ...learner,
    nextStep: checkNextStep(learner),
  };
}

export function updateLearners(learners) {
  return learners.map(updateLearner);
}

export function getDefaultIndexLearner(data) {
  let defaultLearner = 0;
  data.learnerProfile.forEach((learner, index) => {
    if (learner.defaultUser === true) defaultLearner = index;
  });
  return defaultLearner;
}

export function makeObjectUser(data) {
  const learners = updateLearners(data.learnerProfile);
  const newData = {
    info: data,
    learners,
    homeSteps: data.homePageOrder,
    actualLearner: learners[getDefaultIndexLearner(data)],
  };
  delete newData.info.learnerProfile;
  delete newData.info.homePageOrder;
  return newData;
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isStaginghost = Boolean(
  window.location.hostname.match(/stagin/)?.length
);

export const checkBool = (data) => {
  if (typeof data === 'string') return `${data}` === 'true';
  if (typeof data === 'boolean') return data;
  if (typeof data === 'number') return Boolean(data);
  return false;
};
